/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";

export const GlowChile = ({ className }) => {
  return (
    <svg
      className={`${className}`}
      viewBox="0 0 153 926"
      width="153"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path pulse-color"
        d="M108.898 7.77342C110.886 7.77342 112.498 6.03328 112.498 3.88671C112.498 1.74014 110.886 0 108.898 0C106.91 0 105.298 1.74014 105.298 3.88671C105.298 6.03328 106.91 7.77342 108.898 7.77342Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 16.5162C94.6903 16.5162 96.3021 14.776 96.3021 12.6295C96.3021 10.4829 94.6903 8.74275 92.7021 8.74275C90.7138 8.74275 89.102 10.4829 89.102 12.6295C89.102 14.776 90.7138 16.5162 92.7021 16.5162Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 16.5162C102.788 16.5162 104.4 14.776 104.4 12.6295C104.4 10.4829 102.788 8.74275 100.8 8.74275C98.8117 8.74275 97.1999 10.4829 97.1999 12.6295C97.1999 14.776 98.8117 16.5162 100.8 16.5162Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 16.5162C110.886 16.5162 112.498 14.776 112.498 12.6295C112.498 10.4829 110.886 8.74275 108.898 8.74275C106.91 8.74275 105.298 10.4829 105.298 12.6295C105.298 14.776 106.91 16.5162 108.898 16.5162Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 25.2589C102.788 25.2589 104.4 23.5188 104.4 21.3722C104.4 19.2256 102.788 17.4855 100.8 17.4855C98.8117 17.4855 97.1999 19.2256 97.1999 21.3722C97.1999 23.5188 98.8117 25.2589 100.8 25.2589Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 25.2589C110.886 25.2589 112.498 23.5188 112.498 21.3722C112.498 19.2256 110.886 17.4855 108.898 17.4855C106.91 17.4855 105.298 19.2256 105.298 21.3722C105.298 23.5188 106.91 25.2589 108.898 25.2589Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M116.996 25.2589C118.984 25.2589 120.596 23.5188 120.596 21.3722C120.596 19.2256 118.984 17.4855 116.996 17.4855C115.008 17.4855 113.396 19.2256 113.396 21.3722C113.396 23.5188 115.008 25.2589 116.996 25.2589Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 34.0017C102.788 34.0017 104.4 32.2615 104.4 30.1149C104.4 27.9684 102.788 26.2282 100.8 26.2282C98.8117 26.2282 97.1999 27.9684 97.1999 30.1149C97.1999 32.2615 98.8117 34.0017 100.8 34.0017Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 34.0017C110.886 34.0017 112.498 32.2615 112.498 30.1149C112.498 27.9684 110.886 26.2282 108.898 26.2282C106.91 26.2282 105.298 27.9684 105.298 30.1149C105.298 32.2615 106.91 34.0017 108.898 34.0017Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M116.996 34.0017C118.984 34.0017 120.596 32.2615 120.596 30.1149C120.596 27.9684 118.984 26.2282 116.996 26.2282C115.008 26.2282 113.396 27.9684 113.396 30.1149C113.396 32.2615 115.008 34.0017 116.996 34.0017Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 42.7444C102.788 42.7444 104.4 41.0043 104.4 38.8577C104.4 36.7111 102.788 34.971 100.8 34.971C98.8117 34.971 97.1999 36.7111 97.1999 38.8577C97.1999 41.0043 98.8117 42.7444 100.8 42.7444Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 42.7444C110.886 42.7444 112.498 41.0043 112.498 38.8577C112.498 36.7111 110.886 34.971 108.898 34.971C106.91 34.971 105.298 36.7111 105.298 38.8577C105.298 41.0043 106.91 42.7444 108.898 42.7444Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M116.996 42.7444C118.984 42.7444 120.596 41.0043 120.596 38.8577C120.596 36.7111 118.984 34.971 116.996 34.971C115.008 34.971 113.396 36.7111 113.396 38.8577C113.396 41.0043 115.008 42.7444 116.996 42.7444Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 51.5004C102.788 51.5004 104.4 49.7602 104.4 47.6137C104.4 45.4671 102.788 43.727 100.8 43.727C98.8117 43.727 97.1999 45.4671 97.1999 47.6137C97.1999 49.7602 98.8117 51.5004 100.8 51.5004Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 51.5004C110.886 51.5004 112.498 49.7602 112.498 47.6137C112.498 45.4671 110.886 43.727 108.898 43.727C106.91 43.727 105.298 45.4671 105.298 47.6137C105.298 49.7602 106.91 51.5004 108.898 51.5004Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M116.996 51.5004C118.984 51.5004 120.596 49.7602 120.596 47.6137C120.596 45.4671 118.984 43.727 116.996 43.727C115.008 43.727 113.396 45.4671 113.396 47.6137C113.396 49.7602 115.008 51.5004 116.996 51.5004Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 60.2431C102.788 60.2431 104.4 58.503 104.4 56.3564C104.4 54.2098 102.788 52.4697 100.8 52.4697C98.8117 52.4697 97.1999 54.2098 97.1999 56.3564C97.1999 58.503 98.8117 60.2431 100.8 60.2431Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 60.2431C110.886 60.2431 112.498 58.503 112.498 56.3564C112.498 54.2098 110.886 52.4697 108.898 52.4697C106.91 52.4697 105.298 54.2098 105.298 56.3564C105.298 58.503 106.91 60.2431 108.898 60.2431Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M116.996 60.2431C118.984 60.2431 120.596 58.503 120.596 56.3564C120.596 54.2098 118.984 52.4697 116.996 52.4697C115.008 52.4697 113.396 54.2098 113.396 56.3564C113.396 58.503 115.008 60.2431 116.996 60.2431Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M125.106 60.2431C127.094 60.2431 128.706 58.503 128.706 56.3564C128.706 54.2098 127.094 52.4697 125.106 52.4697C123.118 52.4697 121.506 54.2098 121.506 56.3564C121.506 58.503 123.118 60.2431 125.106 60.2431Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 68.9859C102.788 68.9859 104.4 67.2457 104.4 65.0992C104.4 62.9526 102.788 61.2125 100.8 61.2125C98.8117 61.2125 97.1999 62.9526 97.1999 65.0992C97.1999 67.2457 98.8117 68.9859 100.8 68.9859Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 68.9859C110.886 68.9859 112.498 67.2457 112.498 65.0992C112.498 62.9526 110.886 61.2125 108.898 61.2125C106.91 61.2125 105.298 62.9526 105.298 65.0992C105.298 67.2457 106.91 68.9859 108.898 68.9859Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M116.996 68.9859C118.984 68.9859 120.596 67.2457 120.596 65.0992C120.596 62.9526 118.984 61.2125 116.996 61.2125C115.008 61.2125 113.396 62.9526 113.396 65.0992C113.396 67.2457 115.008 68.9859 116.996 68.9859Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M125.106 68.9859C127.094 68.9859 128.706 67.2457 128.706 65.0992C128.706 62.9526 127.094 61.2125 125.106 61.2125C123.118 61.2125 121.506 62.9526 121.506 65.0992C121.506 67.2457 123.118 68.9859 125.106 68.9859Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 77.7286C102.788 77.7286 104.4 75.9885 104.4 73.8419C104.4 71.6954 102.788 69.9552 100.8 69.9552C98.8117 69.9552 97.1999 71.6954 97.1999 73.8419C97.1999 75.9885 98.8117 77.7286 100.8 77.7286Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 77.7286C110.886 77.7286 112.498 75.9885 112.498 73.8419C112.498 71.6954 110.886 69.9552 108.898 69.9552C106.91 69.9552 105.298 71.6954 105.298 73.8419C105.298 75.9885 106.91 77.7286 108.898 77.7286Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M116.996 77.7286C118.984 77.7286 120.596 75.9885 120.596 73.8419C120.596 71.6954 118.984 69.9552 116.996 69.9552C115.008 69.9552 113.396 71.6954 113.396 73.8419C113.396 75.9885 115.008 77.7286 116.996 77.7286Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M125.106 77.7286C127.094 77.7286 128.706 75.9885 128.706 73.8419C128.706 71.6954 127.094 69.9552 125.106 69.9552C123.118 69.9552 121.506 71.6954 121.506 73.8419C121.506 75.9885 123.118 77.7286 125.106 77.7286Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 86.4714C102.788 86.4714 104.4 84.7312 104.4 82.5847C104.4 80.4381 102.788 78.6979 100.8 78.6979C98.8117 78.6979 97.1999 80.4381 97.1999 82.5847C97.1999 84.7312 98.8117 86.4714 100.8 86.4714Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 86.4714C110.886 86.4714 112.498 84.7312 112.498 82.5847C112.498 80.4381 110.886 78.6979 108.898 78.6979C106.91 78.6979 105.298 80.4381 105.298 82.5847C105.298 84.7312 106.91 86.4714 108.898 86.4714Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M116.996 86.4714C118.984 86.4714 120.596 84.7312 120.596 82.5847C120.596 80.4381 118.984 78.6979 116.996 78.6979C115.008 78.6979 113.396 80.4381 113.396 82.5847C113.396 84.7312 115.008 86.4714 116.996 86.4714Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M125.106 86.4714C127.094 86.4714 128.706 84.7312 128.706 82.5847C128.706 80.4381 127.094 78.6979 125.106 78.6979C123.118 78.6979 121.506 80.4381 121.506 82.5847C121.506 84.7312 123.118 86.4714 125.106 86.4714Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 95.2273C102.788 95.2273 104.4 93.4872 104.4 91.3406C104.4 89.1941 102.788 87.4539 100.8 87.4539C98.8117 87.4539 97.1999 89.1941 97.1999 91.3406C97.1999 93.4872 98.8117 95.2273 100.8 95.2273Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 95.2273C110.886 95.2273 112.498 93.4872 112.498 91.3406C112.498 89.1941 110.886 87.4539 108.898 87.4539C106.91 87.4539 105.298 89.1941 105.298 91.3406C105.298 93.4872 106.91 95.2273 108.898 95.2273Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M116.996 95.2273C118.984 95.2273 120.596 93.4872 120.596 91.3406C120.596 89.1941 118.984 87.4539 116.996 87.4539C115.008 87.4539 113.396 89.1941 113.396 91.3406C113.396 93.4872 115.008 95.2273 116.996 95.2273Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M125.106 95.2273C127.094 95.2273 128.706 93.4872 128.706 91.3406C128.706 89.1941 127.094 87.4539 125.106 87.4539C123.118 87.4539 121.506 89.1941 121.506 91.3406C121.506 93.4872 123.118 95.2273 125.106 95.2273Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M133.204 95.2273C135.192 95.2273 136.804 93.4872 136.804 91.3406C136.804 89.1941 135.192 87.4539 133.204 87.4539C131.216 87.4539 129.604 89.1941 129.604 91.3406C129.604 93.4872 131.216 95.2273 133.204 95.2273Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 103.97C102.788 103.97 104.4 102.23 104.4 100.083C104.4 97.9368 102.788 96.1967 100.8 96.1967C98.8117 96.1967 97.1999 97.9368 97.1999 100.083C97.1999 102.23 98.8117 103.97 100.8 103.97Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 103.97C110.886 103.97 112.498 102.23 112.498 100.083C112.498 97.9368 110.886 96.1967 108.898 96.1967C106.91 96.1967 105.298 97.9368 105.298 100.083C105.298 102.23 106.91 103.97 108.898 103.97Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M116.996 103.97C118.984 103.97 120.596 102.23 120.596 100.083C120.596 97.9368 118.984 96.1967 116.996 96.1967C115.008 96.1967 113.396 97.9368 113.396 100.083C113.396 102.23 115.008 103.97 116.996 103.97Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M125.106 103.97C127.094 103.97 128.706 102.23 128.706 100.083C128.706 97.9368 127.094 96.1967 125.106 96.1967C123.118 96.1967 121.506 97.9368 121.506 100.083C121.506 102.23 123.118 103.97 125.106 103.97Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M133.204 103.97C135.192 103.97 136.804 102.23 136.804 100.083C136.804 97.9368 135.192 96.1967 133.204 96.1967C131.216 96.1967 129.604 97.9368 129.604 100.083C129.604 102.23 131.216 103.97 133.204 103.97Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 112.713C94.6903 112.713 96.3021 110.973 96.3021 108.826C96.3021 106.68 94.6903 104.939 92.7021 104.939C90.7138 104.939 89.102 106.68 89.102 108.826C89.102 110.973 90.7138 112.713 92.7021 112.713Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 112.713C102.788 112.713 104.4 110.973 104.4 108.826C104.4 106.68 102.788 104.939 100.8 104.939C98.8117 104.939 97.1999 106.68 97.1999 108.826C97.1999 110.973 98.8117 112.713 100.8 112.713Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 112.713C110.886 112.713 112.498 110.973 112.498 108.826C112.498 106.68 110.886 104.939 108.898 104.939C106.91 104.939 105.298 106.68 105.298 108.826C105.298 110.973 106.91 112.713 108.898 112.713Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M116.996 112.713C118.984 112.713 120.596 110.973 120.596 108.826C120.596 106.68 118.984 104.939 116.996 104.939C115.008 104.939 113.396 106.68 113.396 108.826C113.396 110.973 115.008 112.713 116.996 112.713Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M125.106 112.713C127.094 112.713 128.706 110.973 128.706 108.826C128.706 106.68 127.094 104.939 125.106 104.939C123.118 104.939 121.506 106.68 121.506 108.826C121.506 110.973 123.118 112.713 125.106 112.713Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M133.204 112.713C135.192 112.713 136.804 110.973 136.804 108.826C136.804 106.68 135.192 104.939 133.204 104.939C131.216 104.939 129.604 106.68 129.604 108.826C129.604 110.973 131.216 112.713 133.204 112.713Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M141.302 112.713C143.29 112.713 144.902 110.973 144.902 108.826C144.902 106.68 143.29 104.939 141.302 104.939C139.314 104.939 137.702 106.68 137.702 108.826C137.702 110.973 139.314 112.713 141.302 112.713Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M149.4 112.713C151.388 112.713 153 110.973 153 108.826C153 106.68 151.388 104.939 149.4 104.939C147.412 104.939 145.8 106.68 145.8 108.826C145.8 110.973 147.412 112.713 149.4 112.713Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 121.456C94.6903 121.456 96.3021 119.715 96.3021 117.569C96.3021 115.422 94.6903 113.682 92.7021 113.682C90.7138 113.682 89.102 115.422 89.102 117.569C89.102 119.715 90.7138 121.456 92.7021 121.456Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 121.456C102.788 121.456 104.4 119.715 104.4 117.569C104.4 115.422 102.788 113.682 100.8 113.682C98.8117 113.682 97.1999 115.422 97.1999 117.569C97.1999 119.715 98.8117 121.456 100.8 121.456Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 121.456C110.886 121.456 112.498 119.715 112.498 117.569C112.498 115.422 110.886 113.682 108.898 113.682C106.91 113.682 105.298 115.422 105.298 117.569C105.298 119.715 106.91 121.456 108.898 121.456Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M116.996 121.456C118.984 121.456 120.596 119.715 120.596 117.569C120.596 115.422 118.984 113.682 116.996 113.682C115.008 113.682 113.396 115.422 113.396 117.569C113.396 119.715 115.008 121.456 116.996 121.456Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M125.106 121.456C127.094 121.456 128.706 119.715 128.706 117.569C128.706 115.422 127.094 113.682 125.106 113.682C123.118 113.682 121.506 115.422 121.506 117.569C121.506 119.715 123.118 121.456 125.106 121.456Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M133.204 121.456C135.192 121.456 136.804 119.715 136.804 117.569C136.804 115.422 135.192 113.682 133.204 113.682C131.216 113.682 129.604 115.422 129.604 117.569C129.604 119.715 131.216 121.456 133.204 121.456Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M141.302 121.456C143.29 121.456 144.902 119.715 144.902 117.569C144.902 115.422 143.29 113.682 141.302 113.682C139.314 113.682 137.702 115.422 137.702 117.569C137.702 119.715 139.314 121.456 141.302 121.456Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 130.198C94.6903 130.198 96.3021 128.458 96.3021 126.312C96.3021 124.165 94.6903 122.425 92.7021 122.425C90.7138 122.425 89.102 124.165 89.102 126.312C89.102 128.458 90.7138 130.198 92.7021 130.198Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 130.198C102.788 130.198 104.4 128.458 104.4 126.312C104.4 124.165 102.788 122.425 100.8 122.425C98.8117 122.425 97.1999 124.165 97.1999 126.312C97.1999 128.458 98.8117 130.198 100.8 130.198Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 130.198C110.886 130.198 112.498 128.458 112.498 126.312C112.498 124.165 110.886 122.425 108.898 122.425C106.91 122.425 105.298 124.165 105.298 126.312C105.298 128.458 106.91 130.198 108.898 130.198Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M116.996 130.198C118.984 130.198 120.596 128.458 120.596 126.312C120.596 124.165 118.984 122.425 116.996 122.425C115.008 122.425 113.396 124.165 113.396 126.312C113.396 128.458 115.008 130.198 116.996 130.198Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M125.106 130.198C127.094 130.198 128.706 128.458 128.706 126.312C128.706 124.165 127.094 122.425 125.106 122.425C123.118 122.425 121.506 124.165 121.506 126.312C121.506 128.458 123.118 130.198 125.106 130.198Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M133.204 130.198C135.192 130.198 136.804 128.458 136.804 126.312C136.804 124.165 135.192 122.425 133.204 122.425C131.216 122.425 129.604 124.165 129.604 126.312C129.604 128.458 131.216 130.198 133.204 130.198Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M141.302 130.198C143.29 130.198 144.902 128.458 144.902 126.312C144.902 124.165 143.29 122.425 141.302 122.425C139.314 122.425 137.702 124.165 137.702 126.312C137.702 128.458 139.314 130.198 141.302 130.198Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 138.941C94.6903 138.941 96.3021 137.201 96.3021 135.054C96.3021 132.908 94.6903 131.168 92.7021 131.168C90.7138 131.168 89.102 132.908 89.102 135.054C89.102 137.201 90.7138 138.941 92.7021 138.941Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 138.941C102.788 138.941 104.4 137.201 104.4 135.054C104.4 132.908 102.788 131.168 100.8 131.168C98.8117 131.168 97.1999 132.908 97.1999 135.054C97.1999 137.201 98.8117 138.941 100.8 138.941Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 138.941C110.886 138.941 112.498 137.201 112.498 135.054C112.498 132.908 110.886 131.168 108.898 131.168C106.91 131.168 105.298 132.908 105.298 135.054C105.298 137.201 106.91 138.941 108.898 138.941Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M116.996 138.941C118.984 138.941 120.596 137.201 120.596 135.054C120.596 132.908 118.984 131.168 116.996 131.168C115.008 131.168 113.396 132.908 113.396 135.054C113.396 137.201 115.008 138.941 116.996 138.941Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M125.106 138.941C127.094 138.941 128.706 137.201 128.706 135.054C128.706 132.908 127.094 131.168 125.106 131.168C123.118 131.168 121.506 132.908 121.506 135.054C121.506 137.201 123.118 138.941 125.106 138.941Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 147.697C94.6903 147.697 96.3021 145.957 96.3021 143.81C96.3021 141.664 94.6903 139.924 92.7021 139.924C90.7138 139.924 89.102 141.664 89.102 143.81C89.102 145.957 90.7138 147.697 92.7021 147.697Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 147.697C102.788 147.697 104.4 145.957 104.4 143.81C104.4 141.664 102.788 139.924 100.8 139.924C98.8117 139.924 97.1999 141.664 97.1999 143.81C97.1999 145.957 98.8117 147.697 100.8 147.697Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 147.697C110.886 147.697 112.498 145.957 112.498 143.81C112.498 141.664 110.886 139.924 108.898 139.924C106.91 139.924 105.298 141.664 105.298 143.81C105.298 145.957 106.91 147.697 108.898 147.697Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M116.996 147.697C118.984 147.697 120.596 145.957 120.596 143.81C120.596 141.664 118.984 139.924 116.996 139.924C115.008 139.924 113.396 141.664 113.396 143.81C113.396 145.957 115.008 147.697 116.996 147.697Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M125.106 147.697C127.094 147.697 128.706 145.957 128.706 143.81C128.706 141.664 127.094 139.924 125.106 139.924C123.118 139.924 121.506 141.664 121.506 143.81C121.506 145.957 123.118 147.697 125.106 147.697Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 156.44C94.6903 156.44 96.3021 154.7 96.3021 152.553C96.3021 150.407 94.6903 148.666 92.7021 148.666C90.7138 148.666 89.102 150.407 89.102 152.553C89.102 154.7 90.7138 156.44 92.7021 156.44Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 156.44C102.788 156.44 104.4 154.7 104.4 152.553C104.4 150.407 102.788 148.666 100.8 148.666C98.8117 148.666 97.1999 150.407 97.1999 152.553C97.1999 154.7 98.8117 156.44 100.8 156.44Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 156.44C110.886 156.44 112.498 154.7 112.498 152.553C112.498 150.407 110.886 148.666 108.898 148.666C106.91 148.666 105.298 150.407 105.298 152.553C105.298 154.7 106.91 156.44 108.898 156.44Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M116.996 156.44C118.984 156.44 120.596 154.7 120.596 152.553C120.596 150.407 118.984 148.666 116.996 148.666C115.008 148.666 113.396 150.407 113.396 152.553C113.396 154.7 115.008 156.44 116.996 156.44Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 165.183C94.6903 165.183 96.3021 163.442 96.3021 161.296C96.3021 159.149 94.6903 157.409 92.7021 157.409C90.7138 157.409 89.102 159.149 89.102 161.296C89.102 163.442 90.7138 165.183 92.7021 165.183Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 165.183C102.788 165.183 104.4 163.442 104.4 161.296C104.4 159.149 102.788 157.409 100.8 157.409C98.8117 157.409 97.1999 159.149 97.1999 161.296C97.1999 163.442 98.8117 165.183 100.8 165.183Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 165.183C110.886 165.183 112.498 163.442 112.498 161.296C112.498 159.149 110.886 157.409 108.898 157.409C106.91 157.409 105.298 159.149 105.298 161.296C105.298 163.442 106.91 165.183 108.898 165.183Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M116.996 165.183C118.984 165.183 120.596 163.442 120.596 161.296C120.596 159.149 118.984 157.409 116.996 157.409C115.008 157.409 113.396 159.149 113.396 161.296C113.396 163.442 115.008 165.183 116.996 165.183Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M125.106 165.183C127.094 165.183 128.706 163.442 128.706 161.296C128.706 159.149 127.094 157.409 125.106 157.409C123.118 157.409 121.506 159.149 121.506 161.296C121.506 163.442 123.118 165.183 125.106 165.183Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 173.925C94.6903 173.925 96.3021 172.185 96.3021 170.039C96.3021 167.892 94.6903 166.152 92.7021 166.152C90.7138 166.152 89.102 167.892 89.102 170.039C89.102 172.185 90.7138 173.925 92.7021 173.925Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 173.925C102.788 173.925 104.4 172.185 104.4 170.039C104.4 167.892 102.788 166.152 100.8 166.152C98.8117 166.152 97.1999 167.892 97.1999 170.039C97.1999 172.185 98.8117 173.925 100.8 173.925Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 173.925C110.886 173.925 112.498 172.185 112.498 170.039C112.498 167.892 110.886 166.152 108.898 166.152C106.91 166.152 105.298 167.892 105.298 170.039C105.298 172.185 106.91 173.925 108.898 173.925Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M116.996 173.925C118.984 173.925 120.596 172.185 120.596 170.039C120.596 167.892 118.984 166.152 116.996 166.152C115.008 166.152 113.396 167.892 113.396 170.039C113.396 172.185 115.008 173.925 116.996 173.925Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M125.106 173.925C127.094 173.925 128.706 172.185 128.706 170.039C128.706 167.892 127.094 166.152 125.106 166.152C123.118 166.152 121.506 167.892 121.506 170.039C121.506 172.185 123.118 173.925 125.106 173.925Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 182.668C94.6903 182.668 96.3021 180.928 96.3021 178.781C96.3021 176.635 94.6903 174.895 92.7021 174.895C90.7138 174.895 89.102 176.635 89.102 178.781C89.102 180.928 90.7138 182.668 92.7021 182.668Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 182.668C102.788 182.668 104.4 180.928 104.4 178.781C104.4 176.635 102.788 174.895 100.8 174.895C98.8117 174.895 97.1999 176.635 97.1999 178.781C97.1999 180.928 98.8117 182.668 100.8 182.668Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 182.668C110.886 182.668 112.498 180.928 112.498 178.781C112.498 176.635 110.886 174.895 108.898 174.895C106.91 174.895 105.298 176.635 105.298 178.781C105.298 180.928 106.91 182.668 108.898 182.668Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M116.996 182.668C118.984 182.668 120.596 180.928 120.596 178.781C120.596 176.635 118.984 174.895 116.996 174.895C115.008 174.895 113.396 176.635 113.396 178.781C113.396 180.928 115.008 182.668 116.996 182.668Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M125.106 182.668C127.094 182.668 128.706 180.928 128.706 178.781C128.706 176.635 127.094 174.895 125.106 174.895C123.118 174.895 121.506 176.635 121.506 178.781C121.506 180.928 123.118 182.668 125.106 182.668Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 191.411C94.6903 191.411 96.3021 189.671 96.3021 187.524C96.3021 185.378 94.6903 183.637 92.7021 183.637C90.7138 183.637 89.102 185.378 89.102 187.524C89.102 189.671 90.7138 191.411 92.7021 191.411Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 191.411C102.788 191.411 104.4 189.671 104.4 187.524C104.4 185.378 102.788 183.637 100.8 183.637C98.8117 183.637 97.1999 185.378 97.1999 187.524C97.1999 189.671 98.8117 191.411 100.8 191.411Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 191.411C110.886 191.411 112.498 189.671 112.498 187.524C112.498 185.378 110.886 183.637 108.898 183.637C106.91 183.637 105.298 185.378 105.298 187.524C105.298 189.671 106.91 191.411 108.898 191.411Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M116.996 191.411C118.984 191.411 120.596 189.671 120.596 187.524C120.596 185.378 118.984 183.637 116.996 183.637C115.008 183.637 113.396 185.378 113.396 187.524C113.396 189.671 115.008 191.411 116.996 191.411Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M125.106 191.411C127.094 191.411 128.706 189.671 128.706 187.524C128.706 185.378 127.094 183.637 125.106 183.637C123.118 183.637 121.506 185.378 121.506 187.524C121.506 189.671 123.118 191.411 125.106 191.411Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M84.6041 200.167C86.5924 200.167 88.2041 198.427 88.2041 196.28C88.2041 194.133 86.5924 192.393 84.6041 192.393C82.6158 192.393 81.004 194.133 81.004 196.28C81.004 198.427 82.6158 200.167 84.6041 200.167Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 200.167C94.6903 200.167 96.3021 198.427 96.3021 196.28C96.3021 194.133 94.6903 192.393 92.7021 192.393C90.7138 192.393 89.102 194.133 89.102 196.28C89.102 198.427 90.7138 200.167 92.7021 200.167Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 200.167C102.788 200.167 104.4 198.427 104.4 196.28C104.4 194.133 102.788 192.393 100.8 192.393C98.8117 192.393 97.1999 194.133 97.1999 196.28C97.1999 198.427 98.8117 200.167 100.8 200.167Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 200.167C110.886 200.167 112.498 198.427 112.498 196.28C112.498 194.133 110.886 192.393 108.898 192.393C106.91 192.393 105.298 194.133 105.298 196.28C105.298 198.427 106.91 200.167 108.898 200.167Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M116.996 200.167C118.984 200.167 120.596 198.427 120.596 196.28C120.596 194.133 118.984 192.393 116.996 192.393C115.008 192.393 113.396 194.133 113.396 196.28C113.396 198.427 115.008 200.167 116.996 200.167Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M84.6041 208.91C86.5924 208.91 88.2041 207.169 88.2041 205.023C88.2041 202.876 86.5924 201.136 84.6041 201.136C82.6158 201.136 81.004 202.876 81.004 205.023C81.004 207.169 82.6158 208.91 84.6041 208.91Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 208.91C94.6903 208.91 96.3021 207.169 96.3021 205.023C96.3021 202.876 94.6903 201.136 92.7021 201.136C90.7138 201.136 89.102 202.876 89.102 205.023C89.102 207.169 90.7138 208.91 92.7021 208.91Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 208.91C102.788 208.91 104.4 207.169 104.4 205.023C104.4 202.876 102.788 201.136 100.8 201.136C98.8117 201.136 97.1999 202.876 97.1999 205.023C97.1999 207.169 98.8117 208.91 100.8 208.91Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 208.91C110.886 208.91 112.498 207.169 112.498 205.023C112.498 202.876 110.886 201.136 108.898 201.136C106.91 201.136 105.298 202.876 105.298 205.023C105.298 207.169 106.91 208.91 108.898 208.91Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M84.6041 217.652C86.5924 217.652 88.2041 215.912 88.2041 213.766C88.2041 211.619 86.5924 209.879 84.6041 209.879C82.6158 209.879 81.004 211.619 81.004 213.766C81.004 215.912 82.6158 217.652 84.6041 217.652Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 217.652C94.6903 217.652 96.3021 215.912 96.3021 213.766C96.3021 211.619 94.6903 209.879 92.7021 209.879C90.7138 209.879 89.102 211.619 89.102 213.766C89.102 215.912 90.7138 217.652 92.7021 217.652Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 217.652C102.788 217.652 104.4 215.912 104.4 213.766C104.4 211.619 102.788 209.879 100.8 209.879C98.8117 209.879 97.1999 211.619 97.1999 213.766C97.1999 215.912 98.8117 217.652 100.8 217.652Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 217.652C110.886 217.652 112.498 215.912 112.498 213.766C112.498 211.619 110.886 209.879 108.898 209.879C106.91 209.879 105.298 211.619 105.298 213.766C105.298 215.912 106.91 217.652 108.898 217.652Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 226.395C78.4821 226.395 80.0939 224.655 80.0939 222.508C80.0939 220.362 78.4821 218.622 76.4939 218.622C74.5056 218.622 72.8938 220.362 72.8938 222.508C72.8938 224.655 74.5056 226.395 76.4939 226.395Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M84.6041 226.395C86.5924 226.395 88.2041 224.655 88.2041 222.508C88.2041 220.362 86.5924 218.622 84.6041 218.622C82.6158 218.622 81.004 220.362 81.004 222.508C81.004 224.655 82.6158 226.395 84.6041 226.395Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 226.395C94.6903 226.395 96.3021 224.655 96.3021 222.508C96.3021 220.362 94.6903 218.622 92.7021 218.622C90.7138 218.622 89.102 220.362 89.102 222.508C89.102 224.655 90.7138 226.395 92.7021 226.395Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 226.395C102.788 226.395 104.4 224.655 104.4 222.508C104.4 220.362 102.788 218.622 100.8 218.622C98.8117 218.622 97.1999 220.362 97.1999 222.508C97.1999 224.655 98.8117 226.395 100.8 226.395Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 235.138C78.4821 235.138 80.0939 233.398 80.0939 231.251C80.0939 229.104 78.4821 227.364 76.4939 227.364C74.5056 227.364 72.8938 229.104 72.8938 231.251C72.8938 233.398 74.5056 235.138 76.4939 235.138Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M84.6041 235.138C86.5924 235.138 88.2041 233.398 88.2041 231.251C88.2041 229.104 86.5924 227.364 84.6041 227.364C82.6158 227.364 81.004 229.104 81.004 231.251C81.004 233.398 82.6158 235.138 84.6041 235.138Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 235.138C94.6903 235.138 96.3021 233.398 96.3021 231.251C96.3021 229.104 94.6903 227.364 92.7021 227.364C90.7138 227.364 89.102 229.104 89.102 231.251C89.102 233.398 90.7138 235.138 92.7021 235.138Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 235.138C102.788 235.138 104.4 233.398 104.4 231.251C104.4 229.104 102.788 227.364 100.8 227.364C98.8117 227.364 97.1999 229.104 97.1999 231.251C97.1999 233.398 98.8117 235.138 100.8 235.138Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 243.894C78.4821 243.894 80.0939 242.154 80.0939 240.007C80.0939 237.86 78.4821 236.12 76.4939 236.12C74.5056 236.12 72.8938 237.86 72.8938 240.007C72.8938 242.154 74.5056 243.894 76.4939 243.894Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M84.6041 243.894C86.5924 243.894 88.2041 242.154 88.2041 240.007C88.2041 237.86 86.5924 236.12 84.6041 236.12C82.6158 236.12 81.004 237.86 81.004 240.007C81.004 242.154 82.6158 243.894 84.6041 243.894Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 243.894C94.6903 243.894 96.3021 242.154 96.3021 240.007C96.3021 237.86 94.6903 236.12 92.7021 236.12C90.7138 236.12 89.102 237.86 89.102 240.007C89.102 242.154 90.7138 243.894 92.7021 243.894Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 252.636C78.4821 252.636 80.0939 250.896 80.0939 248.75C80.0939 246.603 78.4821 244.863 76.4939 244.863C74.5056 244.863 72.8938 246.603 72.8938 248.75C72.8938 250.896 74.5056 252.636 76.4939 252.636Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M84.6041 252.636C86.5924 252.636 88.2041 250.896 88.2041 248.75C88.2041 246.603 86.5924 244.863 84.6041 244.863C82.6158 244.863 81.004 246.603 81.004 248.75C81.004 250.896 82.6158 252.636 84.6041 252.636Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 252.636C94.6903 252.636 96.3021 250.896 96.3021 248.75C96.3021 246.603 94.6903 244.863 92.7021 244.863C90.7138 244.863 89.102 246.603 89.102 248.75C89.102 250.896 90.7138 252.636 92.7021 252.636Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 252.636C102.788 252.636 104.4 250.896 104.4 248.75C104.4 246.603 102.788 244.863 100.8 244.863C98.8117 244.863 97.1999 246.603 97.1999 248.75C97.1999 250.896 98.8117 252.636 100.8 252.636Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 261.379C78.4821 261.379 80.0939 259.639 80.0939 257.492C80.0939 255.346 78.4821 253.606 76.4939 253.606C74.5056 253.606 72.8938 255.346 72.8938 257.492C72.8938 259.639 74.5056 261.379 76.4939 261.379Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M84.6041 261.379C86.5924 261.379 88.2041 259.639 88.2041 257.492C88.2041 255.346 86.5924 253.606 84.6041 253.606C82.6158 253.606 81.004 255.346 81.004 257.492C81.004 259.639 82.6158 261.379 84.6041 261.379Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 261.379C94.6903 261.379 96.3021 259.639 96.3021 257.492C96.3021 255.346 94.6903 253.606 92.7021 253.606C90.7138 253.606 89.102 255.346 89.102 257.492C89.102 259.639 90.7138 261.379 92.7021 261.379Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 261.379C102.788 261.379 104.4 259.639 104.4 257.492C104.4 255.346 102.788 253.606 100.8 253.606C98.8117 253.606 97.1999 255.346 97.1999 257.492C97.1999 259.639 98.8117 261.379 100.8 261.379Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 270.122C78.4821 270.122 80.0939 268.382 80.0939 266.235C80.0939 264.089 78.4821 262.349 76.4939 262.349C74.5056 262.349 72.8938 264.089 72.8938 266.235C72.8938 268.382 74.5056 270.122 76.4939 270.122Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M84.6041 270.122C86.5924 270.122 88.2041 268.382 88.2041 266.235C88.2041 264.089 86.5924 262.349 84.6041 262.349C82.6158 262.349 81.004 264.089 81.004 266.235C81.004 268.382 82.6158 270.122 84.6041 270.122Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 270.122C94.6903 270.122 96.3021 268.382 96.3021 266.235C96.3021 264.089 94.6903 262.349 92.7021 262.349C90.7138 262.349 89.102 264.089 89.102 266.235C89.102 268.382 90.7138 270.122 92.7021 270.122Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 278.865C78.4821 278.865 80.0939 277.125 80.0939 274.978C80.0939 272.831 78.4821 271.091 76.4939 271.091C74.5056 271.091 72.8938 272.831 72.8938 274.978C72.8938 277.125 74.5056 278.865 76.4939 278.865Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M84.6041 278.865C86.5924 278.865 88.2041 277.125 88.2041 274.978C88.2041 272.831 86.5924 271.091 84.6041 271.091C82.6158 271.091 81.004 272.831 81.004 274.978C81.004 277.125 82.6158 278.865 84.6041 278.865Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 278.865C94.6903 278.865 96.3021 277.125 96.3021 274.978C96.3021 272.831 94.6903 271.091 92.7021 271.091C90.7138 271.091 89.102 272.831 89.102 274.978C89.102 277.125 90.7138 278.865 92.7021 278.865Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 287.607C78.4821 287.607 80.0939 285.867 80.0939 283.721C80.0939 281.574 78.4821 279.834 76.4939 279.834C74.5056 279.834 72.8938 281.574 72.8938 283.721C72.8938 285.867 74.5056 287.607 76.4939 287.607Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M84.6041 287.607C86.5924 287.607 88.2041 285.867 88.2041 283.721C88.2041 281.574 86.5924 279.834 84.6041 279.834C82.6158 279.834 81.004 281.574 81.004 283.721C81.004 285.867 82.6158 287.607 84.6041 287.607Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 296.363C78.4821 296.363 80.0939 294.623 80.0939 292.477C80.0939 290.33 78.4821 288.59 76.4939 288.59C74.5056 288.59 72.8938 290.33 72.8938 292.477C72.8938 294.623 74.5056 296.363 76.4939 296.363Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M84.6041 296.363C86.5924 296.363 88.2041 294.623 88.2041 292.477C88.2041 290.33 86.5924 288.59 84.6041 288.59C82.6158 288.59 81.004 290.33 81.004 292.477C81.004 294.623 82.6158 296.363 84.6041 296.363Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 296.363C94.6903 296.363 96.3021 294.623 96.3021 292.477C96.3021 290.33 94.6903 288.59 92.7021 288.59C90.7138 288.59 89.102 290.33 89.102 292.477C89.102 294.623 90.7138 296.363 92.7021 296.363Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 305.106C78.4821 305.106 80.0939 303.366 80.0939 301.219C80.0939 299.073 78.4821 297.333 76.4939 297.333C74.5056 297.333 72.8938 299.073 72.8938 301.219C72.8938 303.366 74.5056 305.106 76.4939 305.106Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M84.6041 305.106C86.5924 305.106 88.2041 303.366 88.2041 301.219C88.2041 299.073 86.5924 297.333 84.6041 297.333C82.6158 297.333 81.004 299.073 81.004 301.219C81.004 303.366 82.6158 305.106 84.6041 305.106Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 305.106C94.6903 305.106 96.3021 303.366 96.3021 301.219C96.3021 299.073 94.6903 297.333 92.7021 297.333C90.7138 297.333 89.102 299.073 89.102 301.219C89.102 303.366 90.7138 305.106 92.7021 305.106Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 313.849C78.4821 313.849 80.0939 312.109 80.0939 309.962C80.0939 307.816 78.4821 306.075 76.4939 306.075C74.5056 306.075 72.8938 307.816 72.8938 309.962C72.8938 312.109 74.5056 313.849 76.4939 313.849Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M84.6041 313.849C86.5924 313.849 88.2041 312.109 88.2041 309.962C88.2041 307.816 86.5924 306.075 84.6041 306.075C82.6158 306.075 81.004 307.816 81.004 309.962C81.004 312.109 82.6158 313.849 84.6041 313.849Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 313.849C94.6903 313.849 96.3021 312.109 96.3021 309.962C96.3021 307.816 94.6903 306.075 92.7021 306.075C90.7138 306.075 89.102 307.816 89.102 309.962C89.102 312.109 90.7138 313.849 92.7021 313.849Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 322.592C78.4821 322.592 80.0939 320.852 80.0939 318.705C80.0939 316.558 78.4821 314.818 76.4939 314.818C74.5056 314.818 72.8938 316.558 72.8938 318.705C72.8938 320.852 74.5056 322.592 76.4939 322.592Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M84.6041 322.592C86.5924 322.592 88.2041 320.852 88.2041 318.705C88.2041 316.558 86.5924 314.818 84.6041 314.818C82.6158 314.818 81.004 316.558 81.004 318.705C81.004 320.852 82.6158 322.592 84.6041 322.592Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 322.592C94.6903 322.592 96.3021 320.852 96.3021 318.705C96.3021 316.558 94.6903 314.818 92.7021 314.818C90.7138 314.818 89.102 316.558 89.102 318.705C89.102 320.852 90.7138 322.592 92.7021 322.592Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 331.334C78.4821 331.334 80.0939 329.594 80.0939 327.448C80.0939 325.301 78.4821 323.561 76.4939 323.561C74.5056 323.561 72.8938 325.301 72.8938 327.448C72.8938 329.594 74.5056 331.334 76.4939 331.334Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M84.6041 331.334C86.5924 331.334 88.2041 329.594 88.2041 327.448C88.2041 325.301 86.5924 323.561 84.6041 323.561C82.6158 323.561 81.004 325.301 81.004 327.448C81.004 329.594 82.6158 331.334 84.6041 331.334Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 331.334C94.6903 331.334 96.3021 329.594 96.3021 327.448C96.3021 325.301 94.6903 323.561 92.7021 323.561C90.7138 323.561 89.102 325.301 89.102 327.448C89.102 329.594 90.7138 331.334 92.7021 331.334Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 331.334C102.788 331.334 104.4 329.594 104.4 327.448C104.4 325.301 102.788 323.561 100.8 323.561C98.8117 323.561 97.1999 325.301 97.1999 327.448C97.1999 329.594 98.8117 331.334 100.8 331.334Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 340.077C70.3842 340.077 71.996 338.337 71.996 336.19C71.996 334.044 70.3842 332.304 68.3959 332.304C66.4076 332.304 64.7958 334.044 64.7958 336.19C64.7958 338.337 66.4076 340.077 68.3959 340.077Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 340.077C78.4821 340.077 80.0939 338.337 80.0939 336.19C80.0939 334.044 78.4821 332.304 76.4939 332.304C74.5056 332.304 72.8938 334.044 72.8938 336.19C72.8938 338.337 74.5056 340.077 76.4939 340.077Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M84.6041 340.077C86.5924 340.077 88.2041 338.337 88.2041 336.19C88.2041 334.044 86.5924 332.304 84.6041 332.304C82.6158 332.304 81.004 334.044 81.004 336.19C81.004 338.337 82.6158 340.077 84.6041 340.077Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 340.077C94.6903 340.077 96.3021 338.337 96.3021 336.19C96.3021 334.044 94.6903 332.304 92.7021 332.304C90.7138 332.304 89.102 334.044 89.102 336.19C89.102 338.337 90.7138 340.077 92.7021 340.077Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 340.077C102.788 340.077 104.4 338.337 104.4 336.19C104.4 334.044 102.788 332.304 100.8 332.304C98.8117 332.304 97.1999 334.044 97.1999 336.19C97.1999 338.337 98.8117 340.077 100.8 340.077Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 348.833C70.3842 348.833 71.996 347.093 71.996 344.946C71.996 342.8 70.3842 341.06 68.3959 341.06C66.4076 341.06 64.7958 342.8 64.7958 344.946C64.7958 347.093 66.4076 348.833 68.3959 348.833Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 348.833C78.4821 348.833 80.0939 347.093 80.0939 344.946C80.0939 342.8 78.4821 341.06 76.4939 341.06C74.5056 341.06 72.8938 342.8 72.8938 344.946C72.8938 347.093 74.5056 348.833 76.4939 348.833Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M84.6041 348.833C86.5924 348.833 88.2041 347.093 88.2041 344.946C88.2041 342.8 86.5924 341.06 84.6041 341.06C82.6158 341.06 81.004 342.8 81.004 344.946C81.004 347.093 82.6158 348.833 84.6041 348.833Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 348.833C94.6903 348.833 96.3021 347.093 96.3021 344.946C96.3021 342.8 94.6903 341.06 92.7021 341.06C90.7138 341.06 89.102 342.8 89.102 344.946C89.102 347.093 90.7138 348.833 92.7021 348.833Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 348.833C102.788 348.833 104.4 347.093 104.4 344.946C104.4 342.8 102.788 341.06 100.8 341.06C98.8117 341.06 97.1999 342.8 97.1999 344.946C97.1999 347.093 98.8117 348.833 100.8 348.833Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 357.576C70.3842 357.576 71.996 355.836 71.996 353.689C71.996 351.543 70.3842 349.802 68.3959 349.802C66.4076 349.802 64.7958 351.543 64.7958 353.689C64.7958 355.836 66.4076 357.576 68.3959 357.576Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 357.576C78.4821 357.576 80.0939 355.836 80.0939 353.689C80.0939 351.543 78.4821 349.802 76.4939 349.802C74.5056 349.802 72.8938 351.543 72.8938 353.689C72.8938 355.836 74.5056 357.576 76.4939 357.576Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M84.6041 357.576C86.5924 357.576 88.2041 355.836 88.2041 353.689C88.2041 351.543 86.5924 349.802 84.6041 349.802C82.6158 349.802 81.004 351.543 81.004 353.689C81.004 355.836 82.6158 357.576 84.6041 357.576Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 357.576C94.6903 357.576 96.3021 355.836 96.3021 353.689C96.3021 351.543 94.6903 349.802 92.7021 349.802C90.7138 349.802 89.102 351.543 89.102 353.689C89.102 355.836 90.7138 357.576 92.7021 357.576Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 366.319C70.3842 366.319 71.996 364.578 71.996 362.432C71.996 360.285 70.3842 358.545 68.3959 358.545C66.4076 358.545 64.7958 360.285 64.7958 362.432C64.7958 364.578 66.4076 366.319 68.3959 366.319Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 366.319C78.4821 366.319 80.0939 364.578 80.0939 362.432C80.0939 360.285 78.4821 358.545 76.4939 358.545C74.5056 358.545 72.8938 360.285 72.8938 362.432C72.8938 364.578 74.5056 366.319 76.4939 366.319Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M84.6041 366.319C86.5924 366.319 88.2041 364.578 88.2041 362.432C88.2041 360.285 86.5924 358.545 84.6041 358.545C82.6158 358.545 81.004 360.285 81.004 362.432C81.004 364.578 82.6158 366.319 84.6041 366.319Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 366.319C94.6903 366.319 96.3021 364.578 96.3021 362.432C96.3021 360.285 94.6903 358.545 92.7021 358.545C90.7138 358.545 89.102 360.285 89.102 362.432C89.102 364.578 90.7138 366.319 92.7021 366.319Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 375.061C62.2862 375.061 63.898 373.321 63.898 371.175C63.898 369.028 62.2862 367.288 60.2979 367.288C58.3097 367.288 56.6979 369.028 56.6979 371.175C56.6979 373.321 58.3097 375.061 60.2979 375.061Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 375.061C70.3842 375.061 71.996 373.321 71.996 371.175C71.996 369.028 70.3842 367.288 68.3959 367.288C66.4076 367.288 64.7958 369.028 64.7958 371.175C64.7958 373.321 66.4076 375.061 68.3959 375.061Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 375.061C78.4821 375.061 80.0939 373.321 80.0939 371.175C80.0939 369.028 78.4821 367.288 76.4939 367.288C74.5056 367.288 72.8938 369.028 72.8938 371.175C72.8938 373.321 74.5056 375.061 76.4939 375.061Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M84.6041 375.061C86.5924 375.061 88.2041 373.321 88.2041 371.175C88.2041 369.028 86.5924 367.288 84.6041 367.288C82.6158 367.288 81.004 369.028 81.004 371.175C81.004 373.321 82.6158 375.061 84.6041 375.061Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 383.804C62.2862 383.804 63.898 382.064 63.898 379.917C63.898 377.771 62.2862 376.031 60.2979 376.031C58.3097 376.031 56.6979 377.771 56.6979 379.917C56.6979 382.064 58.3097 383.804 60.2979 383.804Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 383.804C70.3842 383.804 71.996 382.064 71.996 379.917C71.996 377.771 70.3842 376.031 68.3959 376.031C66.4076 376.031 64.7958 377.771 64.7958 379.917C64.7958 382.064 66.4076 383.804 68.3959 383.804Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 383.804C78.4821 383.804 80.0939 382.064 80.0939 379.917C80.0939 377.771 78.4821 376.031 76.4939 376.031C74.5056 376.031 72.8938 377.771 72.8938 379.917C72.8938 382.064 74.5056 383.804 76.4939 383.804Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M84.6041 383.804C86.5924 383.804 88.2041 382.064 88.2041 379.917C88.2041 377.771 86.5924 376.031 84.6041 376.031C82.6158 376.031 81.004 377.771 81.004 379.917C81.004 382.064 82.6158 383.804 84.6041 383.804Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 383.804C94.6903 383.804 96.3021 382.064 96.3021 379.917C96.3021 377.771 94.6903 376.031 92.7021 376.031C90.7138 376.031 89.102 377.771 89.102 379.917C89.102 382.064 90.7138 383.804 92.7021 383.804Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 392.547C54.1883 392.547 55.8001 390.807 55.8001 388.66C55.8001 386.514 54.1883 384.773 52.2 384.773C50.2118 384.773 48.6 386.514 48.6 388.66C48.6 390.807 50.2118 392.547 52.2 392.547Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 392.547C62.2862 392.547 63.898 390.807 63.898 388.66C63.898 386.514 62.2862 384.773 60.2979 384.773C58.3097 384.773 56.6979 386.514 56.6979 388.66C56.6979 390.807 58.3097 392.547 60.2979 392.547Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 392.547C70.3842 392.547 71.996 390.807 71.996 388.66C71.996 386.514 70.3842 384.773 68.3959 384.773C66.4076 384.773 64.7958 386.514 64.7958 388.66C64.7958 390.807 66.4076 392.547 68.3959 392.547Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 392.547C78.4821 392.547 80.0939 390.807 80.0939 388.66C80.0939 386.514 78.4821 384.773 76.4939 384.773C74.5056 384.773 72.8938 386.514 72.8938 388.66C72.8938 390.807 74.5056 392.547 76.4939 392.547Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M84.6041 392.547C86.5924 392.547 88.2041 390.807 88.2041 388.66C88.2041 386.514 86.5924 384.773 84.6041 384.773C82.6158 384.773 81.004 386.514 81.004 388.66C81.004 390.807 82.6158 392.547 84.6041 392.547Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 401.303C54.1883 401.303 55.8001 399.563 55.8001 397.416C55.8001 395.27 54.1883 393.529 52.2 393.529C50.2118 393.529 48.6 395.27 48.6 397.416C48.6 399.563 50.2118 401.303 52.2 401.303Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 401.303C62.2862 401.303 63.898 399.563 63.898 397.416C63.898 395.27 62.2862 393.529 60.2979 393.529C58.3097 393.529 56.6979 395.27 56.6979 397.416C56.6979 399.563 58.3097 401.303 60.2979 401.303Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 401.303C70.3842 401.303 71.996 399.563 71.996 397.416C71.996 395.27 70.3842 393.529 68.3959 393.529C66.4076 393.529 64.7958 395.27 64.7958 397.416C64.7958 399.563 66.4076 401.303 68.3959 401.303Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 401.303C78.4821 401.303 80.0939 399.563 80.0939 397.416C80.0939 395.27 78.4821 393.529 76.4939 393.529C74.5056 393.529 72.8938 395.27 72.8938 397.416C72.8938 399.563 74.5056 401.303 76.4939 401.303Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 410.046C46.0903 410.046 47.7021 408.305 47.7021 406.159C47.7021 404.012 46.0903 402.272 44.102 402.272C42.1138 402.272 40.502 404.012 40.502 406.159C40.502 408.305 42.1138 410.046 44.102 410.046Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 410.046C54.1883 410.046 55.8001 408.305 55.8001 406.159C55.8001 404.012 54.1883 402.272 52.2 402.272C50.2118 402.272 48.6 404.012 48.6 406.159C48.6 408.305 50.2118 410.046 52.2 410.046Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 410.046C62.2862 410.046 63.898 408.305 63.898 406.159C63.898 404.012 62.2862 402.272 60.2979 402.272C58.3097 402.272 56.6979 404.012 56.6979 406.159C56.6979 408.305 58.3097 410.046 60.2979 410.046Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 410.046C70.3842 410.046 71.996 408.305 71.996 406.159C71.996 404.012 70.3842 402.272 68.3959 402.272C66.4076 402.272 64.7958 404.012 64.7958 406.159C64.7958 408.305 66.4076 410.046 68.3959 410.046Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 410.046C78.4821 410.046 80.0939 408.305 80.0939 406.159C80.0939 404.012 78.4821 402.272 76.4939 402.272C74.5056 402.272 72.8938 404.012 72.8938 406.159C72.8938 408.305 74.5056 410.046 76.4939 410.046Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 418.788C37.9923 418.788 39.6041 417.048 39.6041 414.902C39.6041 412.755 37.9923 411.015 36.0041 411.015C34.0158 411.015 32.404 412.755 32.404 414.902C32.404 417.048 34.0158 418.788 36.0041 418.788Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 418.788C46.0903 418.788 47.7021 417.048 47.7021 414.902C47.7021 412.755 46.0903 411.015 44.102 411.015C42.1138 411.015 40.502 412.755 40.502 414.902C40.502 417.048 42.1138 418.788 44.102 418.788Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 418.788C54.1883 418.788 55.8001 417.048 55.8001 414.902C55.8001 412.755 54.1883 411.015 52.2 411.015C50.2118 411.015 48.6 412.755 48.6 414.902C48.6 417.048 50.2118 418.788 52.2 418.788Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 418.788C62.2862 418.788 63.898 417.048 63.898 414.902C63.898 412.755 62.2862 411.015 60.2979 411.015C58.3097 411.015 56.6979 412.755 56.6979 414.902C56.6979 417.048 58.3097 418.788 60.2979 418.788Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 418.788C70.3842 418.788 71.996 417.048 71.996 414.902C71.996 412.755 70.3842 411.015 68.3959 411.015C66.4076 411.015 64.7958 412.755 64.7958 414.902C64.7958 417.048 66.4076 418.788 68.3959 418.788Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 418.788C78.4821 418.788 80.0939 417.048 80.0939 414.902C80.0939 412.755 78.4821 411.015 76.4939 411.015C74.5056 411.015 72.8938 412.755 72.8938 414.902C72.8938 417.048 74.5056 418.788 76.4939 418.788Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 427.531C37.9923 427.531 39.6041 425.791 39.6041 423.644C39.6041 421.498 37.9923 419.758 36.0041 419.758C34.0158 419.758 32.404 421.498 32.404 423.644C32.404 425.791 34.0158 427.531 36.0041 427.531Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 427.531C46.0903 427.531 47.7021 425.791 47.7021 423.644C47.7021 421.498 46.0903 419.758 44.102 419.758C42.1138 419.758 40.502 421.498 40.502 423.644C40.502 425.791 42.1138 427.531 44.102 427.531Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 427.531C54.1883 427.531 55.8001 425.791 55.8001 423.644C55.8001 421.498 54.1883 419.758 52.2 419.758C50.2118 419.758 48.6 421.498 48.6 423.644C48.6 425.791 50.2118 427.531 52.2 427.531Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 427.531C62.2862 427.531 63.898 425.791 63.898 423.644C63.898 421.498 62.2862 419.758 60.2979 419.758C58.3097 419.758 56.6979 421.498 56.6979 423.644C56.6979 425.791 58.3097 427.531 60.2979 427.531Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 427.531C70.3842 427.531 71.996 425.791 71.996 423.644C71.996 421.498 70.3842 419.758 68.3959 419.758C66.4076 419.758 64.7958 421.498 64.7958 423.644C64.7958 425.791 66.4076 427.531 68.3959 427.531Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 427.531C78.4821 427.531 80.0939 425.791 80.0939 423.644C80.0939 421.498 78.4821 419.758 76.4939 419.758C74.5056 419.758 72.8938 421.498 72.8938 423.644C72.8938 425.791 74.5056 427.531 76.4939 427.531Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 436.274C46.0903 436.274 47.7021 434.534 47.7021 432.387C47.7021 430.241 46.0903 428.5 44.102 428.5C42.1138 428.5 40.502 430.241 40.502 432.387C40.502 434.534 42.1138 436.274 44.102 436.274Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 436.274C54.1883 436.274 55.8001 434.534 55.8001 432.387C55.8001 430.241 54.1883 428.5 52.2 428.5C50.2118 428.5 48.6 430.241 48.6 432.387C48.6 434.534 50.2118 436.274 52.2 436.274Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 436.274C62.2862 436.274 63.898 434.534 63.898 432.387C63.898 430.241 62.2862 428.5 60.2979 428.5C58.3097 428.5 56.6979 430.241 56.6979 432.387C56.6979 434.534 58.3097 436.274 60.2979 436.274Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 436.274C70.3842 436.274 71.996 434.534 71.996 432.387C71.996 430.241 70.3842 428.5 68.3959 428.5C66.4076 428.5 64.7958 430.241 64.7958 432.387C64.7958 434.534 66.4076 436.274 68.3959 436.274Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 436.274C78.4821 436.274 80.0939 434.534 80.0939 432.387C80.0939 430.241 78.4821 428.5 76.4939 428.5C74.5056 428.5 72.8938 430.241 72.8938 432.387C72.8938 434.534 74.5056 436.274 76.4939 436.274Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 445.017C46.0903 445.017 47.7021 443.276 47.7021 441.13C47.7021 438.983 46.0903 437.243 44.102 437.243C42.1138 437.243 40.502 438.983 40.502 441.13C40.502 443.276 42.1138 445.017 44.102 445.017Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 445.017C54.1883 445.017 55.8001 443.276 55.8001 441.13C55.8001 438.983 54.1883 437.243 52.2 437.243C50.2118 437.243 48.6 438.983 48.6 441.13C48.6 443.276 50.2118 445.017 52.2 445.017Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 445.017C62.2862 445.017 63.898 443.276 63.898 441.13C63.898 438.983 62.2862 437.243 60.2979 437.243C58.3097 437.243 56.6979 438.983 56.6979 441.13C56.6979 443.276 58.3097 445.017 60.2979 445.017Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 445.017C70.3842 445.017 71.996 443.276 71.996 441.13C71.996 438.983 70.3842 437.243 68.3959 437.243C66.4076 437.243 64.7958 438.983 64.7958 441.13C64.7958 443.276 66.4076 445.017 68.3959 445.017Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 445.017C78.4821 445.017 80.0939 443.276 80.0939 441.13C80.0939 438.983 78.4821 437.243 76.4939 437.243C74.5056 437.243 72.8938 438.983 72.8938 441.13C72.8938 443.276 74.5056 445.017 76.4939 445.017Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 453.773C46.0903 453.773 47.7021 452.032 47.7021 449.886C47.7021 447.739 46.0903 445.999 44.102 445.999C42.1138 445.999 40.502 447.739 40.502 449.886C40.502 452.032 42.1138 453.773 44.102 453.773Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 453.773C54.1883 453.773 55.8001 452.032 55.8001 449.886C55.8001 447.739 54.1883 445.999 52.2 445.999C50.2118 445.999 48.6 447.739 48.6 449.886C48.6 452.032 50.2118 453.773 52.2 453.773Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 453.773C62.2862 453.773 63.898 452.032 63.898 449.886C63.898 447.739 62.2862 445.999 60.2979 445.999C58.3097 445.999 56.6979 447.739 56.6979 449.886C56.6979 452.032 58.3097 453.773 60.2979 453.773Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 453.773C70.3842 453.773 71.996 452.032 71.996 449.886C71.996 447.739 70.3842 445.999 68.3959 445.999C66.4076 445.999 64.7958 447.739 64.7958 449.886C64.7958 452.032 66.4076 453.773 68.3959 453.773Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 453.773C78.4821 453.773 80.0939 452.032 80.0939 449.886C80.0939 447.739 78.4821 445.999 76.4939 445.999C74.5056 445.999 72.8938 447.739 72.8938 449.886C72.8938 452.032 74.5056 453.773 76.4939 453.773Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 462.515C46.0903 462.515 47.7021 460.775 47.7021 458.629C47.7021 456.482 46.0903 454.742 44.102 454.742C42.1138 454.742 40.502 456.482 40.502 458.629C40.502 460.775 42.1138 462.515 44.102 462.515Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 462.515C54.1883 462.515 55.8001 460.775 55.8001 458.629C55.8001 456.482 54.1883 454.742 52.2 454.742C50.2118 454.742 48.6 456.482 48.6 458.629C48.6 460.775 50.2118 462.515 52.2 462.515Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 462.515C62.2862 462.515 63.898 460.775 63.898 458.629C63.898 456.482 62.2862 454.742 60.2979 454.742C58.3097 454.742 56.6979 456.482 56.6979 458.629C56.6979 460.775 58.3097 462.515 60.2979 462.515Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 462.515C70.3842 462.515 71.996 460.775 71.996 458.629C71.996 456.482 70.3842 454.742 68.3959 454.742C66.4076 454.742 64.7958 456.482 64.7958 458.629C64.7958 460.775 66.4076 462.515 68.3959 462.515Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 471.258C46.0903 471.258 47.7021 469.518 47.7021 467.371C47.7021 465.225 46.0903 463.485 44.102 463.485C42.1138 463.485 40.502 465.225 40.502 467.371C40.502 469.518 42.1138 471.258 44.102 471.258Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 471.258C54.1883 471.258 55.8001 469.518 55.8001 467.371C55.8001 465.225 54.1883 463.485 52.2 463.485C50.2118 463.485 48.6 465.225 48.6 467.371C48.6 469.518 50.2118 471.258 52.2 471.258Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 471.258C62.2862 471.258 63.898 469.518 63.898 467.371C63.898 465.225 62.2862 463.485 60.2979 463.485C58.3097 463.485 56.6979 465.225 56.6979 467.371C56.6979 469.518 58.3097 471.258 60.2979 471.258Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 471.258C70.3842 471.258 71.996 469.518 71.996 467.371C71.996 465.225 70.3842 463.485 68.3959 463.485C66.4076 463.485 64.7958 465.225 64.7958 467.371C64.7958 469.518 66.4076 471.258 68.3959 471.258Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 480.001C46.0903 480.001 47.7021 478.261 47.7021 476.114C47.7021 473.967 46.0903 472.227 44.102 472.227C42.1138 472.227 40.502 473.967 40.502 476.114C40.502 478.261 42.1138 480.001 44.102 480.001Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 480.001C54.1883 480.001 55.8001 478.261 55.8001 476.114C55.8001 473.967 54.1883 472.227 52.2 472.227C50.2118 472.227 48.6 473.967 48.6 476.114C48.6 478.261 50.2118 480.001 52.2 480.001Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 480.001C62.2862 480.001 63.898 478.261 63.898 476.114C63.898 473.967 62.2862 472.227 60.2979 472.227C58.3097 472.227 56.6979 473.967 56.6979 476.114C56.6979 478.261 58.3097 480.001 60.2979 480.001Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 480.001C70.3842 480.001 71.996 478.261 71.996 476.114C71.996 473.967 70.3842 472.227 68.3959 472.227C66.4076 472.227 64.7958 473.967 64.7958 476.114C64.7958 478.261 66.4076 480.001 68.3959 480.001Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 488.743C37.9923 488.743 39.6041 487.003 39.6041 484.857C39.6041 482.71 37.9923 480.97 36.0041 480.97C34.0158 480.97 32.404 482.71 32.404 484.857C32.404 487.003 34.0158 488.743 36.0041 488.743Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 488.743C46.0903 488.743 47.7021 487.003 47.7021 484.857C47.7021 482.71 46.0903 480.97 44.102 480.97C42.1138 480.97 40.502 482.71 40.502 484.857C40.502 487.003 42.1138 488.743 44.102 488.743Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 488.743C54.1883 488.743 55.8001 487.003 55.8001 484.857C55.8001 482.71 54.1883 480.97 52.2 480.97C50.2118 480.97 48.6 482.71 48.6 484.857C48.6 487.003 50.2118 488.743 52.2 488.743Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 488.743C62.2862 488.743 63.898 487.003 63.898 484.857C63.898 482.71 62.2862 480.97 60.2979 480.97C58.3097 480.97 56.6979 482.71 56.6979 484.857C56.6979 487.003 58.3097 488.743 60.2979 488.743Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 497.5C37.9923 497.5 39.6041 495.759 39.6041 493.613C39.6041 491.466 37.9923 489.726 36.0041 489.726C34.0158 489.726 32.404 491.466 32.404 493.613C32.404 495.759 34.0158 497.5 36.0041 497.5Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 497.5C46.0903 497.5 47.7021 495.759 47.7021 493.613C47.7021 491.466 46.0903 489.726 44.102 489.726C42.1138 489.726 40.502 491.466 40.502 493.613C40.502 495.759 42.1138 497.5 44.102 497.5Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 497.5C54.1883 497.5 55.8001 495.759 55.8001 493.613C55.8001 491.466 54.1883 489.726 52.2 489.726C50.2118 489.726 48.6 491.466 48.6 493.613C48.6 495.759 50.2118 497.5 52.2 497.5Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 497.5C62.2862 497.5 63.898 495.759 63.898 493.613C63.898 491.466 62.2862 489.726 60.2979 489.726C58.3097 489.726 56.6979 491.466 56.6979 493.613C56.6979 495.759 58.3097 497.5 60.2979 497.5Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 506.242C37.9923 506.242 39.6041 504.502 39.6041 502.356C39.6041 500.209 37.9923 498.469 36.0041 498.469C34.0158 498.469 32.404 500.209 32.404 502.356C32.404 504.502 34.0158 506.242 36.0041 506.242Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 506.242C46.0903 506.242 47.7021 504.502 47.7021 502.356C47.7021 500.209 46.0903 498.469 44.102 498.469C42.1138 498.469 40.502 500.209 40.502 502.356C40.502 504.502 42.1138 506.242 44.102 506.242Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 506.242C54.1883 506.242 55.8001 504.502 55.8001 502.356C55.8001 500.209 54.1883 498.469 52.2 498.469C50.2118 498.469 48.6 500.209 48.6 502.356C48.6 504.502 50.2118 506.242 52.2 506.242Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 506.242C62.2862 506.242 63.898 504.502 63.898 502.356C63.898 500.209 62.2862 498.469 60.2979 498.469C58.3097 498.469 56.6979 500.209 56.6979 502.356C56.6979 504.502 58.3097 506.242 60.2979 506.242Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 514.985C37.9923 514.985 39.6041 513.245 39.6041 511.098C39.6041 508.952 37.9923 507.212 36.0041 507.212C34.0158 507.212 32.404 508.952 32.404 511.098C32.404 513.245 34.0158 514.985 36.0041 514.985Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 514.985C46.0903 514.985 47.7021 513.245 47.7021 511.098C47.7021 508.952 46.0903 507.212 44.102 507.212C42.1138 507.212 40.502 508.952 40.502 511.098C40.502 513.245 42.1138 514.985 44.102 514.985Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 514.985C54.1883 514.985 55.8001 513.245 55.8001 511.098C55.8001 508.952 54.1883 507.212 52.2 507.212C50.2118 507.212 48.6 508.952 48.6 511.098C48.6 513.245 50.2118 514.985 52.2 514.985Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 514.985C62.2862 514.985 63.898 513.245 63.898 511.098C63.898 508.952 62.2862 507.212 60.2979 507.212C58.3097 507.212 56.6979 508.952 56.6979 511.098C56.6979 513.245 58.3097 514.985 60.2979 514.985Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 523.728C37.9923 523.728 39.6041 521.988 39.6041 519.841C39.6041 517.695 37.9923 515.954 36.0041 515.954C34.0158 515.954 32.404 517.695 32.404 519.841C32.404 521.988 34.0158 523.728 36.0041 523.728Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 523.728C46.0903 523.728 47.7021 521.988 47.7021 519.841C47.7021 517.695 46.0903 515.954 44.102 515.954C42.1138 515.954 40.502 517.695 40.502 519.841C40.502 521.988 42.1138 523.728 44.102 523.728Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 523.728C54.1883 523.728 55.8001 521.988 55.8001 519.841C55.8001 517.695 54.1883 515.954 52.2 515.954C50.2118 515.954 48.6 517.695 48.6 519.841C48.6 521.988 50.2118 523.728 52.2 523.728Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 523.728C62.2862 523.728 63.898 521.988 63.898 519.841C63.898 517.695 62.2862 515.954 60.2979 515.954C58.3097 515.954 56.6979 517.695 56.6979 519.841C56.6979 521.988 58.3097 523.728 60.2979 523.728Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 532.471C37.9923 532.471 39.6041 530.73 39.6041 528.584C39.6041 526.437 37.9923 524.697 36.0041 524.697C34.0158 524.697 32.404 526.437 32.404 528.584C32.404 530.73 34.0158 532.471 36.0041 532.471Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 532.471C54.1883 532.471 55.8001 530.73 55.8001 528.584C55.8001 526.437 54.1883 524.697 52.2 524.697C50.2118 524.697 48.6 526.437 48.6 528.584C48.6 530.73 50.2118 532.471 52.2 532.471Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 532.471C62.2862 532.471 63.898 530.73 63.898 528.584C63.898 526.437 62.2862 524.697 60.2979 524.697C58.3097 524.697 56.6979 526.437 56.6979 528.584C56.6979 530.73 58.3097 532.471 60.2979 532.471Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 532.471C70.3842 532.471 71.996 530.73 71.996 528.584C71.996 526.437 70.3842 524.697 68.3959 524.697C66.4076 524.697 64.7958 526.437 64.7958 528.584C64.7958 530.73 66.4076 532.471 68.3959 532.471Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M27.8939 541.213C29.8822 541.213 31.494 539.473 31.494 537.327C31.494 535.18 29.8822 533.44 27.8939 533.44C25.9057 533.44 24.2939 535.18 24.2939 537.327C24.2939 539.473 25.9057 541.213 27.8939 541.213Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 541.213C37.9923 541.213 39.6041 539.473 39.6041 537.327C39.6041 535.18 37.9923 533.44 36.0041 533.44C34.0158 533.44 32.404 535.18 32.404 537.327C32.404 539.473 34.0158 541.213 36.0041 541.213Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 541.213C54.1883 541.213 55.8001 539.473 55.8001 537.327C55.8001 535.18 54.1883 533.44 52.2 533.44C50.2118 533.44 48.6 535.18 48.6 537.327C48.6 539.473 50.2118 541.213 52.2 541.213Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 541.213C62.2862 541.213 63.898 539.473 63.898 537.327C63.898 535.18 62.2862 533.44 60.2979 533.44C58.3097 533.44 56.6979 535.18 56.6979 537.327C56.6979 539.473 58.3097 541.213 60.2979 541.213Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M27.8939 549.969C29.8822 549.969 31.494 548.229 31.494 546.083C31.494 543.936 29.8822 542.196 27.8939 542.196C25.9057 542.196 24.2939 543.936 24.2939 546.083C24.2939 548.229 25.9057 549.969 27.8939 549.969Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 549.969C54.1883 549.969 55.8001 548.229 55.8001 546.083C55.8001 543.936 54.1883 542.196 52.2 542.196C50.2118 542.196 48.6 543.936 48.6 546.083C48.6 548.229 50.2118 549.969 52.2 549.969Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 549.969C62.2862 549.969 63.898 548.229 63.898 546.083C63.898 543.936 62.2862 542.196 60.2979 542.196C58.3097 542.196 56.6979 543.936 56.6979 546.083C56.6979 548.229 58.3097 549.969 60.2979 549.969Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M27.8939 558.712C29.8822 558.712 31.494 556.972 31.494 554.825C31.494 552.679 29.8822 550.939 27.8939 550.939C25.9057 550.939 24.2939 552.679 24.2939 554.825C24.2939 556.972 25.9057 558.712 27.8939 558.712Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 558.712C37.9923 558.712 39.6041 556.972 39.6041 554.825C39.6041 552.679 37.9923 550.939 36.0041 550.939C34.0158 550.939 32.404 552.679 32.404 554.825C32.404 556.972 34.0158 558.712 36.0041 558.712Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 558.712C54.1883 558.712 55.8001 556.972 55.8001 554.825C55.8001 552.679 54.1883 550.939 52.2 550.939C50.2118 550.939 48.6 552.679 48.6 554.825C48.6 556.972 50.2118 558.712 52.2 558.712Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 558.712C62.2862 558.712 63.898 556.972 63.898 554.825C63.898 552.679 62.2862 550.939 60.2979 550.939C58.3097 550.939 56.6979 552.679 56.6979 554.825C56.6979 556.972 58.3097 558.712 60.2979 558.712Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M27.8939 567.455C29.8822 567.455 31.494 565.715 31.494 563.568C31.494 561.422 29.8822 559.681 27.8939 559.681C25.9057 559.681 24.2939 561.422 24.2939 563.568C24.2939 565.715 25.9057 567.455 27.8939 567.455Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 567.455C54.1883 567.455 55.8001 565.715 55.8001 563.568C55.8001 561.422 54.1883 559.681 52.2 559.681C50.2118 559.681 48.6 561.422 48.6 563.568C48.6 565.715 50.2118 567.455 52.2 567.455Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 567.455C62.2862 567.455 63.898 565.715 63.898 563.568C63.898 561.422 62.2862 559.681 60.2979 559.681C58.3097 559.681 56.6979 561.422 56.6979 563.568C56.6979 565.715 58.3097 567.455 60.2979 567.455Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 567.455C70.3842 567.455 71.996 565.715 71.996 563.568C71.996 561.422 70.3842 559.681 68.3959 559.681C66.4076 559.681 64.7958 561.422 64.7958 563.568C64.7958 565.715 66.4076 567.455 68.3959 567.455Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 576.198C54.1883 576.198 55.8001 574.457 55.8001 572.311C55.8001 570.164 54.1883 568.424 52.2 568.424C50.2118 568.424 48.6 570.164 48.6 572.311C48.6 574.457 50.2118 576.198 52.2 576.198Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 576.198C62.2862 576.198 63.898 574.457 63.898 572.311C63.898 570.164 62.2862 568.424 60.2979 568.424C58.3097 568.424 56.6979 570.164 56.6979 572.311C56.6979 574.457 58.3097 576.198 60.2979 576.198Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 576.198C70.3842 576.198 71.996 574.457 71.996 572.311C71.996 570.164 70.3842 568.424 68.3959 568.424C66.4076 568.424 64.7958 570.164 64.7958 572.311C64.7958 574.457 66.4076 576.198 68.3959 576.198Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 584.94C37.9923 584.94 39.6041 583.2 39.6041 581.054C39.6041 578.907 37.9923 577.167 36.0041 577.167C34.0158 577.167 32.404 578.907 32.404 581.054C32.404 583.2 34.0158 584.94 36.0041 584.94Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 584.94C46.0903 584.94 47.7021 583.2 47.7021 581.054C47.7021 578.907 46.0903 577.167 44.102 577.167C42.1138 577.167 40.502 578.907 40.502 581.054C40.502 583.2 42.1138 584.94 44.102 584.94Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 584.94C54.1883 584.94 55.8001 583.2 55.8001 581.054C55.8001 578.907 54.1883 577.167 52.2 577.167C50.2118 577.167 48.6 578.907 48.6 581.054C48.6 583.2 50.2118 584.94 52.2 584.94Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 584.94C62.2862 584.94 63.898 583.2 63.898 581.054C63.898 578.907 62.2862 577.167 60.2979 577.167C58.3097 577.167 56.6979 578.907 56.6979 581.054C56.6979 583.2 58.3097 584.94 60.2979 584.94Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 584.94C70.3842 584.94 71.996 583.2 71.996 581.054C71.996 578.907 70.3842 577.167 68.3959 577.167C66.4076 577.167 64.7958 578.907 64.7958 581.054C64.7958 583.2 66.4076 584.94 68.3959 584.94Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M27.8939 593.696C29.8822 593.696 31.494 591.956 31.494 589.81C31.494 587.663 29.8822 585.923 27.8939 585.923C25.9057 585.923 24.2939 587.663 24.2939 589.81C24.2939 591.956 25.9057 593.696 27.8939 593.696Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 593.696C54.1883 593.696 55.8001 591.956 55.8001 589.81C55.8001 587.663 54.1883 585.923 52.2 585.923C50.2118 585.923 48.6 587.663 48.6 589.81C48.6 591.956 50.2118 593.696 52.2 593.696Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 593.696C62.2862 593.696 63.898 591.956 63.898 589.81C63.898 587.663 62.2862 585.923 60.2979 585.923C58.3097 585.923 56.6979 587.663 56.6979 589.81C56.6979 591.956 58.3097 593.696 60.2979 593.696Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 593.696C70.3842 593.696 71.996 591.956 71.996 589.81C71.996 587.663 70.3842 585.923 68.3959 585.923C66.4076 585.923 64.7958 587.663 64.7958 589.81C64.7958 591.956 66.4076 593.696 68.3959 593.696Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M19.7959 602.439C21.7842 602.439 23.396 600.699 23.396 598.552C23.396 596.406 21.7842 594.666 19.7959 594.666C17.8077 594.666 16.1959 596.406 16.1959 598.552C16.1959 600.699 17.8077 602.439 19.7959 602.439Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M27.8939 602.439C29.8822 602.439 31.494 600.699 31.494 598.552C31.494 596.406 29.8822 594.666 27.8939 594.666C25.9057 594.666 24.2939 596.406 24.2939 598.552C24.2939 600.699 25.9057 602.439 27.8939 602.439Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 602.439C37.9923 602.439 39.6041 600.699 39.6041 598.552C39.6041 596.406 37.9923 594.666 36.0041 594.666C34.0158 594.666 32.404 596.406 32.404 598.552C32.404 600.699 34.0158 602.439 36.0041 602.439Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 602.439C46.0903 602.439 47.7021 600.699 47.7021 598.552C47.7021 596.406 46.0903 594.666 44.102 594.666C42.1138 594.666 40.502 596.406 40.502 598.552C40.502 600.699 42.1138 602.439 44.102 602.439Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 602.439C54.1883 602.439 55.8001 600.699 55.8001 598.552C55.8001 596.406 54.1883 594.666 52.2 594.666C50.2118 594.666 48.6 596.406 48.6 598.552C48.6 600.699 50.2118 602.439 52.2 602.439Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 602.439C62.2862 602.439 63.898 600.699 63.898 598.552C63.898 596.406 62.2862 594.666 60.2979 594.666C58.3097 594.666 56.6979 596.406 56.6979 598.552C56.6979 600.699 58.3097 602.439 60.2979 602.439Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 602.439C70.3842 602.439 71.996 600.699 71.996 598.552C71.996 596.406 70.3842 594.666 68.3959 594.666C66.4076 594.666 64.7958 596.406 64.7958 598.552C64.7958 600.699 66.4076 602.439 68.3959 602.439Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 602.439C78.4821 602.439 80.0939 600.699 80.0939 598.552C80.0939 596.406 78.4821 594.666 76.4939 594.666C74.5056 594.666 72.8938 596.406 72.8938 598.552C72.8938 600.699 74.5056 602.439 76.4939 602.439Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M27.8939 611.182C29.8822 611.182 31.494 609.442 31.494 607.295C31.494 605.148 29.8822 603.408 27.8939 603.408C25.9057 603.408 24.2939 605.148 24.2939 607.295C24.2939 609.442 25.9057 611.182 27.8939 611.182Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 611.182C46.0903 611.182 47.7021 609.442 47.7021 607.295C47.7021 605.148 46.0903 603.408 44.102 603.408C42.1138 603.408 40.502 605.148 40.502 607.295C40.502 609.442 42.1138 611.182 44.102 611.182Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 611.182C54.1883 611.182 55.8001 609.442 55.8001 607.295C55.8001 605.148 54.1883 603.408 52.2 603.408C50.2118 603.408 48.6 605.148 48.6 607.295C48.6 609.442 50.2118 611.182 52.2 611.182Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 611.182C62.2862 611.182 63.898 609.442 63.898 607.295C63.898 605.148 62.2862 603.408 60.2979 603.408C58.3097 603.408 56.6979 605.148 56.6979 607.295C56.6979 609.442 58.3097 611.182 60.2979 611.182Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 611.182C70.3842 611.182 71.996 609.442 71.996 607.295C71.996 605.148 70.3842 603.408 68.3959 603.408C66.4076 603.408 64.7958 605.148 64.7958 607.295C64.7958 609.442 66.4076 611.182 68.3959 611.182Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M27.8939 619.924C29.8822 619.924 31.494 618.184 31.494 616.038C31.494 613.891 29.8822 612.151 27.8939 612.151C25.9057 612.151 24.2939 613.891 24.2939 616.038C24.2939 618.184 25.9057 619.924 27.8939 619.924Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 619.924C46.0903 619.924 47.7021 618.184 47.7021 616.038C47.7021 613.891 46.0903 612.151 44.102 612.151C42.1138 612.151 40.502 613.891 40.502 616.038C40.502 618.184 42.1138 619.924 44.102 619.924Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 619.924C54.1883 619.924 55.8001 618.184 55.8001 616.038C55.8001 613.891 54.1883 612.151 52.2 612.151C50.2118 612.151 48.6 613.891 48.6 616.038C48.6 618.184 50.2118 619.924 52.2 619.924Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 619.924C62.2862 619.924 63.898 618.184 63.898 616.038C63.898 613.891 62.2862 612.151 60.2979 612.151C58.3097 612.151 56.6979 613.891 56.6979 616.038C56.6979 618.184 58.3097 619.924 60.2979 619.924Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 619.924C70.3842 619.924 71.996 618.184 71.996 616.038C71.996 613.891 70.3842 612.151 68.3959 612.151C66.4076 612.151 64.7958 613.891 64.7958 616.038C64.7958 618.184 66.4076 619.924 68.3959 619.924Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 619.924C78.4821 619.924 80.0939 618.184 80.0939 616.038C80.0939 613.891 78.4821 612.151 76.4939 612.151C74.5056 612.151 72.8938 613.891 72.8938 616.038C72.8938 618.184 74.5056 619.924 76.4939 619.924Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M19.7959 628.667C21.7842 628.667 23.396 626.927 23.396 624.78C23.396 622.634 21.7842 620.894 19.7959 620.894C17.8077 620.894 16.1959 622.634 16.1959 624.78C16.1959 626.927 17.8077 628.667 19.7959 628.667Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M27.8939 628.667C29.8822 628.667 31.494 626.927 31.494 624.78C31.494 622.634 29.8822 620.894 27.8939 620.894C25.9057 620.894 24.2939 622.634 24.2939 624.78C24.2939 626.927 25.9057 628.667 27.8939 628.667Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 628.667C37.9923 628.667 39.6041 626.927 39.6041 624.78C39.6041 622.634 37.9923 620.894 36.0041 620.894C34.0158 620.894 32.404 622.634 32.404 624.78C32.404 626.927 34.0158 628.667 36.0041 628.667Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 628.667C46.0903 628.667 47.7021 626.927 47.7021 624.78C47.7021 622.634 46.0903 620.894 44.102 620.894C42.1138 620.894 40.502 622.634 40.502 624.78C40.502 626.927 42.1138 628.667 44.102 628.667Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 628.667C54.1883 628.667 55.8001 626.927 55.8001 624.78C55.8001 622.634 54.1883 620.894 52.2 620.894C50.2118 620.894 48.6 622.634 48.6 624.78C48.6 626.927 50.2118 628.667 52.2 628.667Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 628.667C62.2862 628.667 63.898 626.927 63.898 624.78C63.898 622.634 62.2862 620.894 60.2979 620.894C58.3097 620.894 56.6979 622.634 56.6979 624.78C56.6979 626.927 58.3097 628.667 60.2979 628.667Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 628.667C70.3842 628.667 71.996 626.927 71.996 624.78C71.996 622.634 70.3842 620.894 68.3959 620.894C66.4076 620.894 64.7958 622.634 64.7958 624.78C64.7958 626.927 66.4076 628.667 68.3959 628.667Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M11.698 637.423C13.6862 637.423 15.298 635.683 15.298 633.536C15.298 631.39 13.6862 629.65 11.698 629.65C9.70971 629.65 8.09791 631.39 8.09791 633.536C8.09791 635.683 9.70971 637.423 11.698 637.423Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M19.7959 637.423C21.7842 637.423 23.396 635.683 23.396 633.536C23.396 631.39 21.7842 629.65 19.7959 629.65C17.8077 629.65 16.1959 631.39 16.1959 633.536C16.1959 635.683 17.8077 637.423 19.7959 637.423Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M27.8939 637.423C29.8822 637.423 31.494 635.683 31.494 633.536C31.494 631.39 29.8822 629.65 27.8939 629.65C25.9057 629.65 24.2939 631.39 24.2939 633.536C24.2939 635.683 25.9057 637.423 27.8939 637.423Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 637.423C37.9923 637.423 39.6041 635.683 39.6041 633.536C39.6041 631.39 37.9923 629.65 36.0041 629.65C34.0158 629.65 32.404 631.39 32.404 633.536C32.404 635.683 34.0158 637.423 36.0041 637.423Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 637.423C46.0903 637.423 47.7021 635.683 47.7021 633.536C47.7021 631.39 46.0903 629.65 44.102 629.65C42.1138 629.65 40.502 631.39 40.502 633.536C40.502 635.683 42.1138 637.423 44.102 637.423Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 637.423C54.1883 637.423 55.8001 635.683 55.8001 633.536C55.8001 631.39 54.1883 629.65 52.2 629.65C50.2118 629.65 48.6 631.39 48.6 633.536C48.6 635.683 50.2118 637.423 52.2 637.423Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 637.423C62.2862 637.423 63.898 635.683 63.898 633.536C63.898 631.39 62.2862 629.65 60.2979 629.65C58.3097 629.65 56.6979 631.39 56.6979 633.536C56.6979 635.683 58.3097 637.423 60.2979 637.423Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 637.423C70.3842 637.423 71.996 635.683 71.996 633.536C71.996 631.39 70.3842 629.65 68.3959 629.65C66.4076 629.65 64.7958 631.39 64.7958 633.536C64.7958 635.683 66.4076 637.423 68.3959 637.423Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M11.698 646.166C13.6862 646.166 15.298 644.426 15.298 642.279C15.298 640.133 13.6862 638.392 11.698 638.392C9.70971 638.392 8.09791 640.133 8.09791 642.279C8.09791 644.426 9.70971 646.166 11.698 646.166Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M19.7959 646.166C21.7842 646.166 23.396 644.426 23.396 642.279C23.396 640.133 21.7842 638.392 19.7959 638.392C17.8077 638.392 16.1959 640.133 16.1959 642.279C16.1959 644.426 17.8077 646.166 19.7959 646.166Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M27.8939 646.166C29.8822 646.166 31.494 644.426 31.494 642.279C31.494 640.133 29.8822 638.392 27.8939 638.392C25.9057 638.392 24.2939 640.133 24.2939 642.279C24.2939 644.426 25.9057 646.166 27.8939 646.166Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 646.166C37.9923 646.166 39.6041 644.426 39.6041 642.279C39.6041 640.133 37.9923 638.392 36.0041 638.392C34.0158 638.392 32.404 640.133 32.404 642.279C32.404 644.426 34.0158 646.166 36.0041 646.166Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 646.166C46.0903 646.166 47.7021 644.426 47.7021 642.279C47.7021 640.133 46.0903 638.392 44.102 638.392C42.1138 638.392 40.502 640.133 40.502 642.279C40.502 644.426 42.1138 646.166 44.102 646.166Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 646.166C54.1883 646.166 55.8001 644.426 55.8001 642.279C55.8001 640.133 54.1883 638.392 52.2 638.392C50.2118 638.392 48.6 640.133 48.6 642.279C48.6 644.426 50.2118 646.166 52.2 646.166Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 646.166C62.2862 646.166 63.898 644.426 63.898 642.279C63.898 640.133 62.2862 638.392 60.2979 638.392C58.3097 638.392 56.6979 640.133 56.6979 642.279C56.6979 644.426 58.3097 646.166 60.2979 646.166Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 646.166C70.3842 646.166 71.996 644.426 71.996 642.279C71.996 640.133 70.3842 638.392 68.3959 638.392C66.4076 638.392 64.7958 640.133 64.7958 642.279C64.7958 644.426 66.4076 646.166 68.3959 646.166Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M3.60006 654.909C5.58831 654.909 7.20012 653.169 7.20012 651.022C7.20012 648.875 5.58831 647.135 3.60006 647.135C1.6118 647.135 0 648.875 0 651.022C0 653.169 1.6118 654.909 3.60006 654.909Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M11.698 654.909C13.6862 654.909 15.298 653.169 15.298 651.022C15.298 648.875 13.6862 647.135 11.698 647.135C9.70971 647.135 8.09791 648.875 8.09791 651.022C8.09791 653.169 9.70971 654.909 11.698 654.909Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M19.7959 654.909C21.7842 654.909 23.396 653.169 23.396 651.022C23.396 648.875 21.7842 647.135 19.7959 647.135C17.8077 647.135 16.1959 648.875 16.1959 651.022C16.1959 653.169 17.8077 654.909 19.7959 654.909Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M27.8939 654.909C29.8822 654.909 31.494 653.169 31.494 651.022C31.494 648.875 29.8822 647.135 27.8939 647.135C25.9057 647.135 24.2939 648.875 24.2939 651.022C24.2939 653.169 25.9057 654.909 27.8939 654.909Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 654.909C37.9923 654.909 39.6041 653.169 39.6041 651.022C39.6041 648.875 37.9923 647.135 36.0041 647.135C34.0158 647.135 32.404 648.875 32.404 651.022C32.404 653.169 34.0158 654.909 36.0041 654.909Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 654.909C46.0903 654.909 47.7021 653.169 47.7021 651.022C47.7021 648.875 46.0903 647.135 44.102 647.135C42.1138 647.135 40.502 648.875 40.502 651.022C40.502 653.169 42.1138 654.909 44.102 654.909Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 654.909C54.1883 654.909 55.8001 653.169 55.8001 651.022C55.8001 648.875 54.1883 647.135 52.2 647.135C50.2118 647.135 48.6 648.875 48.6 651.022C48.6 653.169 50.2118 654.909 52.2 654.909Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 654.909C62.2862 654.909 63.898 653.169 63.898 651.022C63.898 648.875 62.2862 647.135 60.2979 647.135C58.3097 647.135 56.6979 648.875 56.6979 651.022C56.6979 653.169 58.3097 654.909 60.2979 654.909Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 654.909C70.3842 654.909 71.996 653.169 71.996 651.022C71.996 648.875 70.3842 647.135 68.3959 647.135C66.4076 647.135 64.7958 648.875 64.7958 651.022C64.7958 653.169 66.4076 654.909 68.3959 654.909Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M3.60006 663.651C5.58831 663.651 7.20012 661.911 7.20012 659.765C7.20012 657.618 5.58831 655.878 3.60006 655.878C1.6118 655.878 0 657.618 0 659.765C0 661.911 1.6118 663.651 3.60006 663.651Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M27.8939 663.651C29.8822 663.651 31.494 661.911 31.494 659.765C31.494 657.618 29.8822 655.878 27.8939 655.878C25.9057 655.878 24.2939 657.618 24.2939 659.765C24.2939 661.911 25.9057 663.651 27.8939 663.651Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 663.651C37.9923 663.651 39.6041 661.911 39.6041 659.765C39.6041 657.618 37.9923 655.878 36.0041 655.878C34.0158 655.878 32.404 657.618 32.404 659.765C32.404 661.911 34.0158 663.651 36.0041 663.651Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 663.651C46.0903 663.651 47.7021 661.911 47.7021 659.765C47.7021 657.618 46.0903 655.878 44.102 655.878C42.1138 655.878 40.502 657.618 40.502 659.765C40.502 661.911 42.1138 663.651 44.102 663.651Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 663.651C54.1883 663.651 55.8001 661.911 55.8001 659.765C55.8001 657.618 54.1883 655.878 52.2 655.878C50.2118 655.878 48.6 657.618 48.6 659.765C48.6 661.911 50.2118 663.651 52.2 663.651Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 663.651C62.2862 663.651 63.898 661.911 63.898 659.765C63.898 657.618 62.2862 655.878 60.2979 655.878C58.3097 655.878 56.6979 657.618 56.6979 659.765C56.6979 661.911 58.3097 663.651 60.2979 663.651Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M27.8939 672.394C29.8822 672.394 31.494 670.654 31.494 668.507C31.494 666.361 29.8822 664.621 27.8939 664.621C25.9057 664.621 24.2939 666.361 24.2939 668.507C24.2939 670.654 25.9057 672.394 27.8939 672.394Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 672.394C37.9923 672.394 39.6041 670.654 39.6041 668.507C39.6041 666.361 37.9923 664.621 36.0041 664.621C34.0158 664.621 32.404 666.361 32.404 668.507C32.404 670.654 34.0158 672.394 36.0041 672.394Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 672.394C46.0903 672.394 47.7021 670.654 47.7021 668.507C47.7021 666.361 46.0903 664.621 44.102 664.621C42.1138 664.621 40.502 666.361 40.502 668.507C40.502 670.654 42.1138 672.394 44.102 672.394Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 672.394C54.1883 672.394 55.8001 670.654 55.8001 668.507C55.8001 666.361 54.1883 664.621 52.2 664.621C50.2118 664.621 48.6 666.361 48.6 668.507C48.6 670.654 50.2118 672.394 52.2 672.394Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 672.394C62.2862 672.394 63.898 670.654 63.898 668.507C63.898 666.361 62.2862 664.621 60.2979 664.621C58.3097 664.621 56.6979 666.361 56.6979 668.507C56.6979 670.654 58.3097 672.394 60.2979 672.394Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M19.7959 681.137C21.7842 681.137 23.396 679.397 23.396 677.25C23.396 675.104 21.7842 673.363 19.7959 673.363C17.8077 673.363 16.1959 675.104 16.1959 677.25C16.1959 679.397 17.8077 681.137 19.7959 681.137Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M27.8939 681.137C29.8822 681.137 31.494 679.397 31.494 677.25C31.494 675.104 29.8822 673.363 27.8939 673.363C25.9057 673.363 24.2939 675.104 24.2939 677.25C24.2939 679.397 25.9057 681.137 27.8939 681.137Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 681.137C37.9923 681.137 39.6041 679.397 39.6041 677.25C39.6041 675.104 37.9923 673.363 36.0041 673.363C34.0158 673.363 32.404 675.104 32.404 677.25C32.404 679.397 34.0158 681.137 36.0041 681.137Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 681.137C46.0903 681.137 47.7021 679.397 47.7021 677.25C47.7021 675.104 46.0903 673.363 44.102 673.363C42.1138 673.363 40.502 675.104 40.502 677.25C40.502 679.397 42.1138 681.137 44.102 681.137Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 681.137C54.1883 681.137 55.8001 679.397 55.8001 677.25C55.8001 675.104 54.1883 673.363 52.2 673.363C50.2118 673.363 48.6 675.104 48.6 677.25C48.6 679.397 50.2118 681.137 52.2 681.137Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M27.8939 689.893C29.8822 689.893 31.494 688.153 31.494 686.006C31.494 683.86 29.8822 682.119 27.8939 682.119C25.9057 682.119 24.2939 683.86 24.2939 686.006C24.2939 688.153 25.9057 689.893 27.8939 689.893Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 689.893C46.0903 689.893 47.7021 688.153 47.7021 686.006C47.7021 683.86 46.0903 682.119 44.102 682.119C42.1138 682.119 40.502 683.86 40.502 686.006C40.502 688.153 42.1138 689.893 44.102 689.893Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 689.893C54.1883 689.893 55.8001 688.153 55.8001 686.006C55.8001 683.86 54.1883 682.119 52.2 682.119C50.2118 682.119 48.6 683.86 48.6 686.006C48.6 688.153 50.2118 689.893 52.2 689.893Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M3.60006 698.636C5.58831 698.636 7.20012 696.896 7.20012 694.749C7.20012 692.602 5.58831 690.862 3.60006 690.862C1.6118 690.862 0 692.602 0 694.749C0 696.896 1.6118 698.636 3.60006 698.636Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M11.698 698.636C13.6862 698.636 15.298 696.896 15.298 694.749C15.298 692.602 13.6862 690.862 11.698 690.862C9.70971 690.862 8.09791 692.602 8.09791 694.749C8.09791 696.896 9.70971 698.636 11.698 698.636Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M19.7959 698.636C21.7842 698.636 23.396 696.896 23.396 694.749C23.396 692.602 21.7842 690.862 19.7959 690.862C17.8077 690.862 16.1959 692.602 16.1959 694.749C16.1959 696.896 17.8077 698.636 19.7959 698.636Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M27.8939 698.636C29.8822 698.636 31.494 696.896 31.494 694.749C31.494 692.602 29.8822 690.862 27.8939 690.862C25.9057 690.862 24.2939 692.602 24.2939 694.749C24.2939 696.896 25.9057 698.636 27.8939 698.636Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 698.636C37.9923 698.636 39.6041 696.896 39.6041 694.749C39.6041 692.602 37.9923 690.862 36.0041 690.862C34.0158 690.862 32.404 692.602 32.404 694.749C32.404 696.896 34.0158 698.636 36.0041 698.636Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 698.636C46.0903 698.636 47.7021 696.896 47.7021 694.749C47.7021 692.602 46.0903 690.862 44.102 690.862C42.1138 690.862 40.502 692.602 40.502 694.749C40.502 696.896 42.1138 698.636 44.102 698.636Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 698.636C54.1883 698.636 55.8001 696.896 55.8001 694.749C55.8001 692.602 54.1883 690.862 52.2 690.862C50.2118 690.862 48.6 692.602 48.6 694.749C48.6 696.896 50.2118 698.636 52.2 698.636Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M3.60006 707.378C5.58831 707.378 7.20012 705.638 7.20012 703.492C7.20012 701.345 5.58831 699.605 3.60006 699.605C1.6118 699.605 0 701.345 0 703.492C0 705.638 1.6118 707.378 3.60006 707.378Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M11.698 707.378C13.6862 707.378 15.298 705.638 15.298 703.492C15.298 701.345 13.6862 699.605 11.698 699.605C9.70971 699.605 8.09791 701.345 8.09791 703.492C8.09791 705.638 9.70971 707.378 11.698 707.378Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M27.8939 707.378C29.8822 707.378 31.494 705.638 31.494 703.492C31.494 701.345 29.8822 699.605 27.8939 699.605C25.9057 699.605 24.2939 701.345 24.2939 703.492C24.2939 705.638 25.9057 707.378 27.8939 707.378Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 707.378C37.9923 707.378 39.6041 705.638 39.6041 703.492C39.6041 701.345 37.9923 699.605 36.0041 699.605C34.0158 699.605 32.404 701.345 32.404 703.492C32.404 705.638 34.0158 707.378 36.0041 707.378Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 707.378C46.0903 707.378 47.7021 705.638 47.7021 703.492C47.7021 701.345 46.0903 699.605 44.102 699.605C42.1138 699.605 40.502 701.345 40.502 703.492C40.502 705.638 42.1138 707.378 44.102 707.378Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 707.378C54.1883 707.378 55.8001 705.638 55.8001 703.492C55.8001 701.345 54.1883 699.605 52.2 699.605C50.2118 699.605 48.6 701.345 48.6 703.492C48.6 705.638 50.2118 707.378 52.2 707.378Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M11.698 716.121C13.6862 716.121 15.298 714.381 15.298 712.234C15.298 710.088 13.6862 708.348 11.698 708.348C9.70971 708.348 8.09791 710.088 8.09791 712.234C8.09791 714.381 9.70971 716.121 11.698 716.121Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M19.7959 716.121C21.7842 716.121 23.396 714.381 23.396 712.234C23.396 710.088 21.7842 708.348 19.7959 708.348C17.8077 708.348 16.1959 710.088 16.1959 712.234C16.1959 714.381 17.8077 716.121 19.7959 716.121Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M27.8939 716.121C29.8822 716.121 31.494 714.381 31.494 712.234C31.494 710.088 29.8822 708.348 27.8939 708.348C25.9057 708.348 24.2939 710.088 24.2939 712.234C24.2939 714.381 25.9057 716.121 27.8939 716.121Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 716.121C37.9923 716.121 39.6041 714.381 39.6041 712.234C39.6041 710.088 37.9923 708.348 36.0041 708.348C34.0158 708.348 32.404 710.088 32.404 712.234C32.404 714.381 34.0158 716.121 36.0041 716.121Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 716.121C46.0903 716.121 47.7021 714.381 47.7021 712.234C47.7021 710.088 46.0903 708.348 44.102 708.348C42.1138 708.348 40.502 710.088 40.502 712.234C40.502 714.381 42.1138 716.121 44.102 716.121Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 716.121C54.1883 716.121 55.8001 714.381 55.8001 712.234C55.8001 710.088 54.1883 708.348 52.2 708.348C50.2118 708.348 48.6 710.088 48.6 712.234C48.6 714.381 50.2118 716.121 52.2 716.121Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M3.60006 724.864C5.58831 724.864 7.20012 723.124 7.20012 720.977C7.20012 718.831 5.58831 717.091 3.60006 717.091C1.6118 717.091 0 718.831 0 720.977C0 723.124 1.6118 724.864 3.60006 724.864Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M11.698 724.864C13.6862 724.864 15.298 723.124 15.298 720.977C15.298 718.831 13.6862 717.091 11.698 717.091C9.70971 717.091 8.09791 718.831 8.09791 720.977C8.09791 723.124 9.70971 724.864 11.698 724.864Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M19.7959 724.864C21.7842 724.864 23.396 723.124 23.396 720.977C23.396 718.831 21.7842 717.091 19.7959 717.091C17.8077 717.091 16.1959 718.831 16.1959 720.977C16.1959 723.124 17.8077 724.864 19.7959 724.864Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M27.8939 724.864C29.8822 724.864 31.494 723.124 31.494 720.977C31.494 718.831 29.8822 717.091 27.8939 717.091C25.9057 717.091 24.2939 718.831 24.2939 720.977C24.2939 723.124 25.9057 724.864 27.8939 724.864Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 724.864C37.9923 724.864 39.6041 723.124 39.6041 720.977C39.6041 718.831 37.9923 717.091 36.0041 717.091C34.0158 717.091 32.404 718.831 32.404 720.977C32.404 723.124 34.0158 724.864 36.0041 724.864Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 724.864C46.0903 724.864 47.7021 723.124 47.7021 720.977C47.7021 718.831 46.0903 717.091 44.102 717.091C42.1138 717.091 40.502 718.831 40.502 720.977C40.502 723.124 42.1138 724.864 44.102 724.864Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M11.698 733.607C13.6862 733.607 15.298 731.866 15.298 729.72C15.298 727.573 13.6862 725.833 11.698 725.833C9.70971 725.833 8.09791 727.573 8.09791 729.72C8.09791 731.866 9.70971 733.607 11.698 733.607Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M19.7959 733.607C21.7842 733.607 23.396 731.866 23.396 729.72C23.396 727.573 21.7842 725.833 19.7959 725.833C17.8077 725.833 16.1959 727.573 16.1959 729.72C16.1959 731.866 17.8077 733.607 19.7959 733.607Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M27.8939 733.607C29.8822 733.607 31.494 731.866 31.494 729.72C31.494 727.573 29.8822 725.833 27.8939 725.833C25.9057 725.833 24.2939 727.573 24.2939 729.72C24.2939 731.866 25.9057 733.607 27.8939 733.607Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 733.607C37.9923 733.607 39.6041 731.866 39.6041 729.72C39.6041 727.573 37.9923 725.833 36.0041 725.833C34.0158 725.833 32.404 727.573 32.404 729.72C32.404 731.866 34.0158 733.607 36.0041 733.607Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M3.60006 742.363C5.58831 742.363 7.20012 740.622 7.20012 738.476C7.20012 736.329 5.58831 734.589 3.60006 734.589C1.6118 734.589 0 736.329 0 738.476C0 740.622 1.6118 742.363 3.60006 742.363Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M11.698 742.363C13.6862 742.363 15.298 740.622 15.298 738.476C15.298 736.329 13.6862 734.589 11.698 734.589C9.70971 734.589 8.09791 736.329 8.09791 738.476C8.09791 740.622 9.70971 742.363 11.698 742.363Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M19.7959 742.363C21.7842 742.363 23.396 740.622 23.396 738.476C23.396 736.329 21.7842 734.589 19.7959 734.589C17.8077 734.589 16.1959 736.329 16.1959 738.476C16.1959 740.622 17.8077 742.363 19.7959 742.363Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M27.8939 742.363C29.8822 742.363 31.494 740.622 31.494 738.476C31.494 736.329 29.8822 734.589 27.8939 734.589C25.9057 734.589 24.2939 736.329 24.2939 738.476C24.2939 740.622 25.9057 742.363 27.8939 742.363Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 742.363C37.9923 742.363 39.6041 740.622 39.6041 738.476C39.6041 736.329 37.9923 734.589 36.0041 734.589C34.0158 734.589 32.404 736.329 32.404 738.476C32.404 740.622 34.0158 742.363 36.0041 742.363Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M19.7959 751.105C21.7842 751.105 23.396 749.365 23.396 747.219C23.396 745.072 21.7842 743.332 19.7959 743.332C17.8077 743.332 16.1959 745.072 16.1959 747.219C16.1959 749.365 17.8077 751.105 19.7959 751.105Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M27.8939 751.105C29.8822 751.105 31.494 749.365 31.494 747.219C31.494 745.072 29.8822 743.332 27.8939 743.332C25.9057 743.332 24.2939 745.072 24.2939 747.219C24.2939 749.365 25.9057 751.105 27.8939 751.105Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 751.105C37.9923 751.105 39.6041 749.365 39.6041 747.219C39.6041 745.072 37.9923 743.332 36.0041 743.332C34.0158 743.332 32.404 745.072 32.404 747.219C32.404 749.365 34.0158 751.105 36.0041 751.105Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M27.8939 759.848C29.8822 759.848 31.494 758.108 31.494 755.961C31.494 753.815 29.8822 752.075 27.8939 752.075C25.9057 752.075 24.2939 753.815 24.2939 755.961C24.2939 758.108 25.9057 759.848 27.8939 759.848Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 759.848C37.9923 759.848 39.6041 758.108 39.6041 755.961C39.6041 753.815 37.9923 752.075 36.0041 752.075C34.0158 752.075 32.404 753.815 32.404 755.961C32.404 758.108 34.0158 759.848 36.0041 759.848Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M19.7959 768.591C21.7842 768.591 23.396 766.851 23.396 764.704C23.396 762.557 21.7842 760.817 19.7959 760.817C17.8077 760.817 16.1959 762.557 16.1959 764.704C16.1959 766.851 17.8077 768.591 19.7959 768.591Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M27.8939 768.591C29.8822 768.591 31.494 766.851 31.494 764.704C31.494 762.557 29.8822 760.817 27.8939 760.817C25.9057 760.817 24.2939 762.557 24.2939 764.704C24.2939 766.851 25.9057 768.591 27.8939 768.591Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 768.591C37.9923 768.591 39.6041 766.851 39.6041 764.704C39.6041 762.557 37.9923 760.817 36.0041 760.817C34.0158 760.817 32.404 762.557 32.404 764.704C32.404 766.851 34.0158 768.591 36.0041 768.591Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M19.7959 777.334C21.7842 777.334 23.396 775.593 23.396 773.447C23.396 771.3 21.7842 769.56 19.7959 769.56C17.8077 769.56 16.1959 771.3 16.1959 773.447C16.1959 775.593 17.8077 777.334 19.7959 777.334Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M27.8939 777.334C29.8822 777.334 31.494 775.593 31.494 773.447C31.494 771.3 29.8822 769.56 27.8939 769.56C25.9057 769.56 24.2939 771.3 24.2939 773.447C24.2939 775.593 25.9057 777.334 27.8939 777.334Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 777.334C37.9923 777.334 39.6041 775.593 39.6041 773.447C39.6041 771.3 37.9923 769.56 36.0041 769.56C34.0158 769.56 32.404 771.3 32.404 773.447C32.404 775.593 34.0158 777.334 36.0041 777.334Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 777.334C46.0903 777.334 47.7021 775.593 47.7021 773.447C47.7021 771.3 46.0903 769.56 44.102 769.56C42.1138 769.56 40.502 771.3 40.502 773.447C40.502 775.593 42.1138 777.334 44.102 777.334Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 777.334C54.1883 777.334 55.8001 775.593 55.8001 773.447C55.8001 771.3 54.1883 769.56 52.2 769.56C50.2118 769.56 48.6 771.3 48.6 773.447C48.6 775.593 50.2118 777.334 52.2 777.334Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 777.334C62.2862 777.334 63.898 775.593 63.898 773.447C63.898 771.3 62.2862 769.56 60.2979 769.56C58.3097 769.56 56.6979 771.3 56.6979 773.447C56.6979 775.593 58.3097 777.334 60.2979 777.334Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M27.8939 786.09C29.8822 786.09 31.494 784.349 31.494 782.203C31.494 780.056 29.8822 778.316 27.8939 778.316C25.9057 778.316 24.2939 780.056 24.2939 782.203C24.2939 784.349 25.9057 786.09 27.8939 786.09Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 786.09C37.9923 786.09 39.6041 784.349 39.6041 782.203C39.6041 780.056 37.9923 778.316 36.0041 778.316C34.0158 778.316 32.404 780.056 32.404 782.203C32.404 784.349 34.0158 786.09 36.0041 786.09Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 786.09C46.0903 786.09 47.7021 784.349 47.7021 782.203C47.7021 780.056 46.0903 778.316 44.102 778.316C42.1138 778.316 40.502 780.056 40.502 782.203C40.502 784.349 42.1138 786.09 44.102 786.09Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 786.09C54.1883 786.09 55.8001 784.349 55.8001 782.203C55.8001 780.056 54.1883 778.316 52.2 778.316C50.2118 778.316 48.6 780.056 48.6 782.203C48.6 784.349 50.2118 786.09 52.2 786.09Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 794.832C37.9923 794.832 39.6041 793.092 39.6041 790.946C39.6041 788.799 37.9923 787.059 36.0041 787.059C34.0158 787.059 32.404 788.799 32.404 790.946C32.404 793.092 34.0158 794.832 36.0041 794.832Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 794.832C46.0903 794.832 47.7021 793.092 47.7021 790.946C47.7021 788.799 46.0903 787.059 44.102 787.059C42.1138 787.059 40.502 788.799 40.502 790.946C40.502 793.092 42.1138 794.832 44.102 794.832Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 794.832C54.1883 794.832 55.8001 793.092 55.8001 790.946C55.8001 788.799 54.1883 787.059 52.2 787.059C50.2118 787.059 48.6 788.799 48.6 790.946C48.6 793.092 50.2118 794.832 52.2 794.832Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M11.698 803.575C13.6862 803.575 15.298 801.835 15.298 799.688C15.298 797.542 13.6862 795.802 11.698 795.802C9.70971 795.802 8.09791 797.542 8.09791 799.688C8.09791 801.835 9.70971 803.575 11.698 803.575Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M27.8939 803.575C29.8822 803.575 31.494 801.835 31.494 799.688C31.494 797.542 29.8822 795.802 27.8939 795.802C25.9057 795.802 24.2939 797.542 24.2939 799.688C24.2939 801.835 25.9057 803.575 27.8939 803.575Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 803.575C46.0903 803.575 47.7021 801.835 47.7021 799.688C47.7021 797.542 46.0903 795.802 44.102 795.802C42.1138 795.802 40.502 797.542 40.502 799.688C40.502 801.835 42.1138 803.575 44.102 803.575Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 803.575C54.1883 803.575 55.8001 801.835 55.8001 799.688C55.8001 797.542 54.1883 795.802 52.2 795.802C50.2118 795.802 48.6 797.542 48.6 799.688C48.6 801.835 50.2118 803.575 52.2 803.575Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 803.575C62.2862 803.575 63.898 801.835 63.898 799.688C63.898 797.542 62.2862 795.802 60.2979 795.802C58.3097 795.802 56.6979 797.542 56.6979 799.688C56.6979 801.835 58.3097 803.575 60.2979 803.575Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 812.318C37.9923 812.318 39.6041 810.578 39.6041 808.431C39.6041 806.285 37.9923 804.544 36.0041 804.544C34.0158 804.544 32.404 806.285 32.404 808.431C32.404 810.578 34.0158 812.318 36.0041 812.318Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 812.318C46.0903 812.318 47.7021 810.578 47.7021 808.431C47.7021 806.285 46.0903 804.544 44.102 804.544C42.1138 804.544 40.502 806.285 40.502 808.431C40.502 810.578 42.1138 812.318 44.102 812.318Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 812.318C54.1883 812.318 55.8001 810.578 55.8001 808.431C55.8001 806.285 54.1883 804.544 52.2 804.544C50.2118 804.544 48.6 806.285 48.6 808.431C48.6 810.578 50.2118 812.318 52.2 812.318Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 812.318C62.2862 812.318 63.898 810.578 63.898 808.431C63.898 806.285 62.2862 804.544 60.2979 804.544C58.3097 804.544 56.6979 806.285 56.6979 808.431C56.6979 810.578 58.3097 812.318 60.2979 812.318Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 812.318C70.3842 812.318 71.996 810.578 71.996 808.431C71.996 806.285 70.3842 804.544 68.3959 804.544C66.4076 804.544 64.7958 806.285 64.7958 808.431C64.7958 810.578 66.4076 812.318 68.3959 812.318Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 812.318C78.4821 812.318 80.0939 810.578 80.0939 808.431C80.0939 806.285 78.4821 804.544 76.4939 804.544C74.5056 804.544 72.8938 806.285 72.8938 808.431C72.8938 810.578 74.5056 812.318 76.4939 812.318Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M84.6041 812.318C86.5924 812.318 88.2041 810.578 88.2041 808.431C88.2041 806.285 86.5924 804.544 84.6041 804.544C82.6158 804.544 81.004 806.285 81.004 808.431C81.004 810.578 82.6158 812.318 84.6041 812.318Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 812.318C94.6903 812.318 96.3021 810.578 96.3021 808.431C96.3021 806.285 94.6903 804.544 92.7021 804.544C90.7138 804.544 89.102 806.285 89.102 808.431C89.102 810.578 90.7138 812.318 92.7021 812.318Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 812.318C102.788 812.318 104.4 810.578 104.4 808.431C104.4 806.285 102.788 804.544 100.8 804.544C98.8117 804.544 97.1999 806.285 97.1999 808.431C97.1999 810.578 98.8117 812.318 100.8 812.318Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M27.8939 821.06C29.8822 821.06 31.494 819.32 31.494 817.174C31.494 815.027 29.8822 813.287 27.8939 813.287C25.9057 813.287 24.2939 815.027 24.2939 817.174C24.2939 819.32 25.9057 821.06 27.8939 821.06Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 821.06C46.0903 821.06 47.7021 819.32 47.7021 817.174C47.7021 815.027 46.0903 813.287 44.102 813.287C42.1138 813.287 40.502 815.027 40.502 817.174C40.502 819.32 42.1138 821.06 44.102 821.06Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 821.06C54.1883 821.06 55.8001 819.32 55.8001 817.174C55.8001 815.027 54.1883 813.287 52.2 813.287C50.2118 813.287 48.6 815.027 48.6 817.174C48.6 819.32 50.2118 821.06 52.2 821.06Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 821.06C62.2862 821.06 63.898 819.32 63.898 817.174C63.898 815.027 62.2862 813.287 60.2979 813.287C58.3097 813.287 56.6979 815.027 56.6979 817.174C56.6979 819.32 58.3097 821.06 60.2979 821.06Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 821.06C70.3842 821.06 71.996 819.32 71.996 817.174C71.996 815.027 70.3842 813.287 68.3959 813.287C66.4076 813.287 64.7958 815.027 64.7958 817.174C64.7958 819.32 66.4076 821.06 68.3959 821.06Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 821.06C78.4821 821.06 80.0939 819.32 80.0939 817.174C80.0939 815.027 78.4821 813.287 76.4939 813.287C74.5056 813.287 72.8938 815.027 72.8938 817.174C72.8938 819.32 74.5056 821.06 76.4939 821.06Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M84.6041 821.06C86.5924 821.06 88.2041 819.32 88.2041 817.174C88.2041 815.027 86.5924 813.287 84.6041 813.287C82.6158 813.287 81.004 815.027 81.004 817.174C81.004 819.32 82.6158 821.06 84.6041 821.06Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 821.06C94.6903 821.06 96.3021 819.32 96.3021 817.174C96.3021 815.027 94.6903 813.287 92.7021 813.287C90.7138 813.287 89.102 815.027 89.102 817.174C89.102 819.32 90.7138 821.06 92.7021 821.06Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 821.06C102.788 821.06 104.4 819.32 104.4 817.174C104.4 815.027 102.788 813.287 100.8 813.287C98.8117 813.287 97.1999 815.027 97.1999 817.174C97.1999 819.32 98.8117 821.06 100.8 821.06Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 829.803C37.9923 829.803 39.6041 828.063 39.6041 825.917C39.6041 823.77 37.9923 822.03 36.0041 822.03C34.0158 822.03 32.404 823.77 32.404 825.917C32.404 828.063 34.0158 829.803 36.0041 829.803Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 829.803C46.0903 829.803 47.7021 828.063 47.7021 825.917C47.7021 823.77 46.0903 822.03 44.102 822.03C42.1138 822.03 40.502 823.77 40.502 825.917C40.502 828.063 42.1138 829.803 44.102 829.803Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 829.803C54.1883 829.803 55.8001 828.063 55.8001 825.917C55.8001 823.77 54.1883 822.03 52.2 822.03C50.2118 822.03 48.6 823.77 48.6 825.917C48.6 828.063 50.2118 829.803 52.2 829.803Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 829.803C78.4821 829.803 80.0939 828.063 80.0939 825.917C80.0939 823.77 78.4821 822.03 76.4939 822.03C74.5056 822.03 72.8938 823.77 72.8938 825.917C72.8938 828.063 74.5056 829.803 76.4939 829.803Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M84.6041 829.803C86.5924 829.803 88.2041 828.063 88.2041 825.917C88.2041 823.77 86.5924 822.03 84.6041 822.03C82.6158 822.03 81.004 823.77 81.004 825.917C81.004 828.063 82.6158 829.803 84.6041 829.803Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 829.803C94.6903 829.803 96.3021 828.063 96.3021 825.917C96.3021 823.77 94.6903 822.03 92.7021 822.03C90.7138 822.03 89.102 823.77 89.102 825.917C89.102 828.063 90.7138 829.803 92.7021 829.803Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 829.803C110.886 829.803 112.498 828.063 112.498 825.917C112.498 823.77 110.886 822.03 108.898 822.03C106.91 822.03 105.298 823.77 105.298 825.917C105.298 828.063 106.91 829.803 108.898 829.803Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M19.7959 838.559C21.7842 838.559 23.396 836.819 23.396 834.673C23.396 832.526 21.7842 830.786 19.7959 830.786C17.8077 830.786 16.1959 832.526 16.1959 834.673C16.1959 836.819 17.8077 838.559 19.7959 838.559Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 838.559C46.0903 838.559 47.7021 836.819 47.7021 834.673C47.7021 832.526 46.0903 830.786 44.102 830.786C42.1138 830.786 40.502 832.526 40.502 834.673C40.502 836.819 42.1138 838.559 44.102 838.559Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 838.559C54.1883 838.559 55.8001 836.819 55.8001 834.673C55.8001 832.526 54.1883 830.786 52.2 830.786C50.2118 830.786 48.6 832.526 48.6 834.673C48.6 836.819 50.2118 838.559 52.2 838.559Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 838.559C62.2862 838.559 63.898 836.819 63.898 834.673C63.898 832.526 62.2862 830.786 60.2979 830.786C58.3097 830.786 56.6979 832.526 56.6979 834.673C56.6979 836.819 58.3097 838.559 60.2979 838.559Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 838.559C70.3842 838.559 71.996 836.819 71.996 834.673C71.996 832.526 70.3842 830.786 68.3959 830.786C66.4076 830.786 64.7958 832.526 64.7958 834.673C64.7958 836.819 66.4076 838.559 68.3959 838.559Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M84.6041 838.559C86.5924 838.559 88.2041 836.819 88.2041 834.673C88.2041 832.526 86.5924 830.786 84.6041 830.786C82.6158 830.786 81.004 832.526 81.004 834.673C81.004 836.819 82.6158 838.559 84.6041 838.559Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 838.559C102.788 838.559 104.4 836.819 104.4 834.673C104.4 832.526 102.788 830.786 100.8 830.786C98.8117 830.786 97.1999 832.526 97.1999 834.673C97.1999 836.819 98.8117 838.559 100.8 838.559Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 838.559C110.886 838.559 112.498 836.819 112.498 834.673C112.498 832.526 110.886 830.786 108.898 830.786C106.91 830.786 105.298 832.526 105.298 834.673C105.298 836.819 106.91 838.559 108.898 838.559Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M116.996 838.559C118.984 838.559 120.596 836.819 120.596 834.673C120.596 832.526 118.984 830.786 116.996 830.786C115.008 830.786 113.396 832.526 113.396 834.673C113.396 836.819 115.008 838.559 116.996 838.559Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 847.302C37.9923 847.302 39.6041 845.562 39.6041 843.415C39.6041 841.269 37.9923 839.529 36.0041 839.529C34.0158 839.529 32.404 841.269 32.404 843.415C32.404 845.562 34.0158 847.302 36.0041 847.302Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 847.302C46.0903 847.302 47.7021 845.562 47.7021 843.415C47.7021 841.269 46.0903 839.529 44.102 839.529C42.1138 839.529 40.502 841.269 40.502 843.415C40.502 845.562 42.1138 847.302 44.102 847.302Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 847.302C54.1883 847.302 55.8001 845.562 55.8001 843.415C55.8001 841.269 54.1883 839.529 52.2 839.529C50.2118 839.529 48.6 841.269 48.6 843.415C48.6 845.562 50.2118 847.302 52.2 847.302Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 847.302C62.2862 847.302 63.898 845.562 63.898 843.415C63.898 841.269 62.2862 839.529 60.2979 839.529C58.3097 839.529 56.6979 841.269 56.6979 843.415C56.6979 845.562 58.3097 847.302 60.2979 847.302Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 847.302C78.4821 847.302 80.0939 845.562 80.0939 843.415C80.0939 841.269 78.4821 839.529 76.4939 839.529C74.5056 839.529 72.8938 841.269 72.8938 843.415C72.8938 845.562 74.5056 847.302 76.4939 847.302Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 847.302C94.6903 847.302 96.3021 845.562 96.3021 843.415C96.3021 841.269 94.6903 839.529 92.7021 839.529C90.7138 839.529 89.102 841.269 89.102 843.415C89.102 845.562 90.7138 847.302 92.7021 847.302Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 847.302C102.788 847.302 104.4 845.562 104.4 843.415C104.4 841.269 102.788 839.529 100.8 839.529C98.8117 839.529 97.1999 841.269 97.1999 843.415C97.1999 845.562 98.8117 847.302 100.8 847.302Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 847.302C110.886 847.302 112.498 845.562 112.498 843.415C112.498 841.269 110.886 839.529 108.898 839.529C106.91 839.529 105.298 841.269 105.298 843.415C105.298 845.562 106.91 847.302 108.898 847.302Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M116.996 847.302C118.984 847.302 120.596 845.562 120.596 843.415C120.596 841.269 118.984 839.529 116.996 839.529C115.008 839.529 113.396 841.269 113.396 843.415C113.396 845.562 115.008 847.302 116.996 847.302Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M36.0041 856.045C37.9923 856.045 39.6041 854.305 39.6041 852.158C39.6041 850.011 37.9923 848.271 36.0041 848.271C34.0158 848.271 32.404 850.011 32.404 852.158C32.404 854.305 34.0158 856.045 36.0041 856.045Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 856.045C62.2862 856.045 63.898 854.305 63.898 852.158C63.898 850.011 62.2862 848.271 60.2979 848.271C58.3097 848.271 56.6979 850.011 56.6979 852.158C56.6979 854.305 58.3097 856.045 60.2979 856.045Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 856.045C70.3842 856.045 71.996 854.305 71.996 852.158C71.996 850.011 70.3842 848.271 68.3959 848.271C66.4076 848.271 64.7958 850.011 64.7958 852.158C64.7958 854.305 66.4076 856.045 68.3959 856.045Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 856.045C78.4821 856.045 80.0939 854.305 80.0939 852.158C80.0939 850.011 78.4821 848.271 76.4939 848.271C74.5056 848.271 72.8938 850.011 72.8938 852.158C72.8938 854.305 74.5056 856.045 76.4939 856.045Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M116.996 856.045C118.984 856.045 120.596 854.305 120.596 852.158C120.596 850.011 118.984 848.271 116.996 848.271C115.008 848.271 113.396 850.011 113.396 852.158C113.396 854.305 115.008 856.045 116.996 856.045Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M44.102 864.787C46.0903 864.787 47.7021 863.047 47.7021 860.901C47.7021 858.754 46.0903 857.014 44.102 857.014C42.1138 857.014 40.502 858.754 40.502 860.901C40.502 863.047 42.1138 864.787 44.102 864.787Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 864.787C54.1883 864.787 55.8001 863.047 55.8001 860.901C55.8001 858.754 54.1883 857.014 52.2 857.014C50.2118 857.014 48.6 858.754 48.6 860.901C48.6 863.047 50.2118 864.787 52.2 864.787Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 864.787C62.2862 864.787 63.898 863.047 63.898 860.901C63.898 858.754 62.2862 857.014 60.2979 857.014C58.3097 857.014 56.6979 858.754 56.6979 860.901C56.6979 863.047 58.3097 864.787 60.2979 864.787Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 864.787C70.3842 864.787 71.996 863.047 71.996 860.901C71.996 858.754 70.3842 857.014 68.3959 857.014C66.4076 857.014 64.7958 858.754 64.7958 860.901C64.7958 863.047 66.4076 864.787 68.3959 864.787Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 864.787C78.4821 864.787 80.0939 863.047 80.0939 860.901C80.0939 858.754 78.4821 857.014 76.4939 857.014C74.5056 857.014 72.8938 858.754 72.8938 860.901C72.8938 863.047 74.5056 864.787 76.4939 864.787Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 864.787C102.788 864.787 104.4 863.047 104.4 860.901C104.4 858.754 102.788 857.014 100.8 857.014C98.8117 857.014 97.1999 858.754 97.1999 860.901C97.1999 863.047 98.8117 864.787 100.8 864.787Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 864.787C110.886 864.787 112.498 863.047 112.498 860.901C112.498 858.754 110.886 857.014 108.898 857.014C106.91 857.014 105.298 858.754 105.298 860.901C105.298 863.047 106.91 864.787 108.898 864.787Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M116.996 864.787C118.984 864.787 120.596 863.047 120.596 860.901C120.596 858.754 118.984 857.014 116.996 857.014C115.008 857.014 113.396 858.754 113.396 860.901C113.396 863.047 115.008 864.787 116.996 864.787Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M52.2 873.53C54.1883 873.53 55.8001 871.79 55.8001 869.644C55.8001 867.497 54.1883 865.757 52.2 865.757C50.2118 865.757 48.6 867.497 48.6 869.644C48.6 871.79 50.2118 873.53 52.2 873.53Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M60.2979 873.53C62.2862 873.53 63.898 871.79 63.898 869.644C63.898 867.497 62.2862 865.757 60.2979 865.757C58.3097 865.757 56.6979 867.497 56.6979 869.644C56.6979 871.79 58.3097 873.53 60.2979 873.53Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 873.53C70.3842 873.53 71.996 871.79 71.996 869.644C71.996 867.497 70.3842 865.757 68.3959 865.757C66.4076 865.757 64.7958 867.497 64.7958 869.644C64.7958 871.79 66.4076 873.53 68.3959 873.53Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 873.53C78.4821 873.53 80.0939 871.79 80.0939 869.644C80.0939 867.497 78.4821 865.757 76.4939 865.757C74.5056 865.757 72.8938 867.497 72.8938 869.644C72.8938 871.79 74.5056 873.53 76.4939 873.53Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M84.6041 873.53C86.5924 873.53 88.2041 871.79 88.2041 869.644C88.2041 867.497 86.5924 865.757 84.6041 865.757C82.6158 865.757 81.004 867.497 81.004 869.644C81.004 871.79 82.6158 873.53 84.6041 873.53Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 873.53C94.6903 873.53 96.3021 871.79 96.3021 869.644C96.3021 867.497 94.6903 865.757 92.7021 865.757C90.7138 865.757 89.102 867.497 89.102 869.644C89.102 871.79 90.7138 873.53 92.7021 873.53Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 873.53C102.788 873.53 104.4 871.79 104.4 869.644C104.4 867.497 102.788 865.757 100.8 865.757C98.8117 865.757 97.1999 867.497 97.1999 869.644C97.1999 871.79 98.8117 873.53 100.8 873.53Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 873.53C110.886 873.53 112.498 871.79 112.498 869.644C112.498 867.497 110.886 865.757 108.898 865.757C106.91 865.757 105.298 867.497 105.298 869.644C105.298 871.79 106.91 873.53 108.898 873.53Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M116.996 873.53C118.984 873.53 120.596 871.79 120.596 869.644C120.596 867.497 118.984 865.757 116.996 865.757C115.008 865.757 113.396 867.497 113.396 869.644C113.396 871.79 115.008 873.53 116.996 873.53Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 882.273C78.4821 882.273 80.0939 880.533 80.0939 878.386C80.0939 876.24 78.4821 874.5 76.4939 874.5C74.5056 874.5 72.8938 876.24 72.8938 878.386C72.8938 880.533 74.5056 882.273 76.4939 882.273Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 882.273C94.6903 882.273 96.3021 880.533 96.3021 878.386C96.3021 876.24 94.6903 874.5 92.7021 874.5C90.7138 874.5 89.102 876.24 89.102 878.386C89.102 880.533 90.7138 882.273 92.7021 882.273Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M116.996 882.273C118.984 882.273 120.596 880.533 120.596 878.386C120.596 876.24 118.984 874.5 116.996 874.5C115.008 874.5 113.396 876.24 113.396 878.386C113.396 880.533 115.008 882.273 116.996 882.273Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M68.3959 891.029C70.3842 891.029 71.996 889.289 71.996 887.142C71.996 884.996 70.3842 883.256 68.3959 883.256C66.4076 883.256 64.7958 884.996 64.7958 887.142C64.7958 889.289 66.4076 891.029 68.3959 891.029Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M84.6041 891.029C86.5924 891.029 88.2041 889.289 88.2041 887.142C88.2041 884.996 86.5924 883.256 84.6041 883.256C82.6158 883.256 81.004 884.996 81.004 887.142C81.004 889.289 82.6158 891.029 84.6041 891.029Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 891.029C94.6903 891.029 96.3021 889.289 96.3021 887.142C96.3021 884.996 94.6903 883.256 92.7021 883.256C90.7138 883.256 89.102 884.996 89.102 887.142C89.102 889.289 90.7138 891.029 92.7021 891.029Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 891.029C102.788 891.029 104.4 889.289 104.4 887.142C104.4 884.996 102.788 883.256 100.8 883.256C98.8117 883.256 97.1999 884.996 97.1999 887.142C97.1999 889.289 98.8117 891.029 100.8 891.029Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 891.029C110.886 891.029 112.498 889.289 112.498 887.142C112.498 884.996 110.886 883.256 108.898 883.256C106.91 883.256 105.298 884.996 105.298 887.142C105.298 889.289 106.91 891.029 108.898 891.029Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M116.996 891.029C118.984 891.029 120.596 889.289 120.596 887.142C120.596 884.996 118.984 883.256 116.996 883.256C115.008 883.256 113.396 884.996 113.396 887.142C113.396 889.289 115.008 891.029 116.996 891.029Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M76.4939 899.772C78.4821 899.772 80.0939 898.032 80.0939 895.885C80.0939 893.738 78.4821 891.998 76.4939 891.998C74.5056 891.998 72.8938 893.738 72.8938 895.885C72.8938 898.032 74.5056 899.772 76.4939 899.772Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 899.772C110.886 899.772 112.498 898.032 112.498 895.885C112.498 893.738 110.886 891.998 108.898 891.998C106.91 891.998 105.298 893.738 105.298 895.885C105.298 898.032 106.91 899.772 108.898 899.772Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M116.996 899.772C118.984 899.772 120.596 898.032 120.596 895.885C120.596 893.738 118.984 891.998 116.996 891.998C115.008 891.998 113.396 893.738 113.396 895.885C113.396 898.032 115.008 899.772 116.996 899.772Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M92.7021 908.514C94.6903 908.514 96.3021 906.774 96.3021 904.628C96.3021 902.481 94.6903 900.741 92.7021 900.741C90.7138 900.741 89.102 902.481 89.102 904.628C89.102 906.774 90.7138 908.514 92.7021 908.514Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M100.8 908.514C102.788 908.514 104.4 906.774 104.4 904.628C104.4 902.481 102.788 900.741 100.8 900.741C98.8117 900.741 97.1999 902.481 97.1999 904.628C97.1999 906.774 98.8117 908.514 100.8 908.514Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 908.514C110.886 908.514 112.498 906.774 112.498 904.628C112.498 902.481 110.886 900.741 108.898 900.741C106.91 900.741 105.298 902.481 105.298 904.628C105.298 906.774 106.91 908.514 108.898 908.514Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M116.996 908.514C118.984 908.514 120.596 906.774 120.596 904.628C120.596 902.481 118.984 900.741 116.996 900.741C115.008 900.741 113.396 902.481 113.396 904.628C113.396 906.774 115.008 908.514 116.996 908.514Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M125.106 908.514C127.094 908.514 128.706 906.774 128.706 904.628C128.706 902.481 127.094 900.741 125.106 900.741C123.118 900.741 121.506 902.481 121.506 904.628C121.506 906.774 123.118 908.514 125.106 908.514Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M133.204 908.514C135.192 908.514 136.804 906.774 136.804 904.628C136.804 902.481 135.192 900.741 133.204 900.741C131.216 900.741 129.604 902.481 129.604 904.628C129.604 906.774 131.216 908.514 133.204 908.514Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M141.302 908.514C143.29 908.514 144.902 906.774 144.902 904.628C144.902 902.481 143.29 900.741 141.302 900.741C139.314 900.741 137.702 902.481 137.702 904.628C137.702 906.774 139.314 908.514 141.302 908.514Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M149.4 908.514C151.388 908.514 153 906.774 153 904.628C153 902.481 151.388 900.741 149.4 900.741C147.412 900.741 145.8 902.481 145.8 904.628C145.8 906.774 147.412 908.514 149.4 908.514Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 917.257C110.886 917.257 112.498 915.517 112.498 913.371C112.498 911.224 110.886 909.484 108.898 909.484C106.91 909.484 105.298 911.224 105.298 913.371C105.298 915.517 106.91 917.257 108.898 917.257Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M125.106 917.257C127.094 917.257 128.706 915.517 128.706 913.371C128.706 911.224 127.094 909.484 125.106 909.484C123.118 909.484 121.506 911.224 121.506 913.371C121.506 915.517 123.118 917.257 125.106 917.257Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M133.204 926C135.192 926 136.804 924.26 136.804 922.113C136.804 919.967 135.192 918.227 133.204 918.227C131.216 918.227 129.604 919.967 129.604 922.113C129.604 924.26 131.216 926 133.204 926Z"
        fillOpacity="0.49"
      />
      <path
        className="path pulse-color"
        d="M108.898 7.77342C110.886 7.77342 112.498 6.03328 112.498 3.88671C112.498 1.74014 110.886 0 108.898 0C106.91 0 105.298 1.74014 105.298 3.88671C105.298 6.03328 106.91 7.77342 108.898 7.77342Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 16.5162C94.6903 16.5162 96.3021 14.776 96.3021 12.6295C96.3021 10.4829 94.6903 8.74275 92.7021 8.74275C90.7138 8.74275 89.102 10.4829 89.102 12.6295C89.102 14.776 90.7138 16.5162 92.7021 16.5162Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 16.5162C102.788 16.5162 104.4 14.776 104.4 12.6295C104.4 10.4829 102.788 8.74275 100.8 8.74275C98.8117 8.74275 97.1999 10.4829 97.1999 12.6295C97.1999 14.776 98.8117 16.5162 100.8 16.5162Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M108.898 16.5162C110.886 16.5162 112.498 14.776 112.498 12.6295C112.498 10.4829 110.886 8.74275 108.898 8.74275C106.91 8.74275 105.298 10.4829 105.298 12.6295C105.298 14.776 106.91 16.5162 108.898 16.5162Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 25.2589C102.788 25.2589 104.4 23.5188 104.4 21.3722C104.4 19.2256 102.788 17.4855 100.8 17.4855C98.8117 17.4855 97.1999 19.2256 97.1999 21.3722C97.1999 23.5188 98.8117 25.2589 100.8 25.2589Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M108.898 25.2589C110.886 25.2589 112.498 23.5188 112.498 21.3722C112.498 19.2256 110.886 17.4855 108.898 17.4855C106.91 17.4855 105.298 19.2256 105.298 21.3722C105.298 23.5188 106.91 25.2589 108.898 25.2589Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M116.996 25.2589C118.984 25.2589 120.596 23.5188 120.596 21.3722C120.596 19.2256 118.984 17.4855 116.996 17.4855C115.008 17.4855 113.396 19.2256 113.396 21.3722C113.396 23.5188 115.008 25.2589 116.996 25.2589Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 34.0017C102.788 34.0017 104.4 32.2615 104.4 30.1149C104.4 27.9684 102.788 26.2282 100.8 26.2282C98.8117 26.2282 97.1999 27.9684 97.1999 30.1149C97.1999 32.2615 98.8117 34.0017 100.8 34.0017Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M108.898 34.0017C110.886 34.0017 112.498 32.2615 112.498 30.1149C112.498 27.9684 110.886 26.2282 108.898 26.2282C106.91 26.2282 105.298 27.9684 105.298 30.1149C105.298 32.2615 106.91 34.0017 108.898 34.0017Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M116.996 34.0017C118.984 34.0017 120.596 32.2615 120.596 30.1149C120.596 27.9684 118.984 26.2282 116.996 26.2282C115.008 26.2282 113.396 27.9684 113.396 30.1149C113.396 32.2615 115.008 34.0017 116.996 34.0017Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 42.7444C102.788 42.7444 104.4 41.0043 104.4 38.8577C104.4 36.7111 102.788 34.971 100.8 34.971C98.8117 34.971 97.1999 36.7111 97.1999 38.8577C97.1999 41.0043 98.8117 42.7444 100.8 42.7444Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M108.898 42.7444C110.886 42.7444 112.498 41.0043 112.498 38.8577C112.498 36.7111 110.886 34.971 108.898 34.971C106.91 34.971 105.298 36.7111 105.298 38.8577C105.298 41.0043 106.91 42.7444 108.898 42.7444Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M116.996 42.7444C118.984 42.7444 120.596 41.0043 120.596 38.8577C120.596 36.7111 118.984 34.971 116.996 34.971C115.008 34.971 113.396 36.7111 113.396 38.8577C113.396 41.0043 115.008 42.7444 116.996 42.7444Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 51.5004C102.788 51.5004 104.4 49.7602 104.4 47.6137C104.4 45.4671 102.788 43.727 100.8 43.727C98.8117 43.727 97.1999 45.4671 97.1999 47.6137C97.1999 49.7602 98.8117 51.5004 100.8 51.5004Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M108.898 51.5004C110.886 51.5004 112.498 49.7602 112.498 47.6137C112.498 45.4671 110.886 43.727 108.898 43.727C106.91 43.727 105.298 45.4671 105.298 47.6137C105.298 49.7602 106.91 51.5004 108.898 51.5004Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M116.996 51.5004C118.984 51.5004 120.596 49.7602 120.596 47.6137C120.596 45.4671 118.984 43.727 116.996 43.727C115.008 43.727 113.396 45.4671 113.396 47.6137C113.396 49.7602 115.008 51.5004 116.996 51.5004Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 60.2431C102.788 60.2431 104.4 58.503 104.4 56.3564C104.4 54.2098 102.788 52.4697 100.8 52.4697C98.8117 52.4697 97.1999 54.2098 97.1999 56.3564C97.1999 58.503 98.8117 60.2431 100.8 60.2431Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M108.898 60.2431C110.886 60.2431 112.498 58.503 112.498 56.3564C112.498 54.2098 110.886 52.4697 108.898 52.4697C106.91 52.4697 105.298 54.2098 105.298 56.3564C105.298 58.503 106.91 60.2431 108.898 60.2431Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M116.996 60.2431C118.984 60.2431 120.596 58.503 120.596 56.3564C120.596 54.2098 118.984 52.4697 116.996 52.4697C115.008 52.4697 113.396 54.2098 113.396 56.3564C113.396 58.503 115.008 60.2431 116.996 60.2431Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M125.106 60.2431C127.094 60.2431 128.706 58.503 128.706 56.3564C128.706 54.2098 127.094 52.4697 125.106 52.4697C123.118 52.4697 121.506 54.2098 121.506 56.3564C121.506 58.503 123.118 60.2431 125.106 60.2431Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 68.9859C102.788 68.9859 104.4 67.2457 104.4 65.0992C104.4 62.9526 102.788 61.2125 100.8 61.2125C98.8117 61.2125 97.1999 62.9526 97.1999 65.0992C97.1999 67.2457 98.8117 68.9859 100.8 68.9859Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M108.898 68.9859C110.886 68.9859 112.498 67.2457 112.498 65.0992C112.498 62.9526 110.886 61.2125 108.898 61.2125C106.91 61.2125 105.298 62.9526 105.298 65.0992C105.298 67.2457 106.91 68.9859 108.898 68.9859Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M116.996 68.9859C118.984 68.9859 120.596 67.2457 120.596 65.0992C120.596 62.9526 118.984 61.2125 116.996 61.2125C115.008 61.2125 113.396 62.9526 113.396 65.0992C113.396 67.2457 115.008 68.9859 116.996 68.9859Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M125.106 68.9859C127.094 68.9859 128.706 67.2457 128.706 65.0992C128.706 62.9526 127.094 61.2125 125.106 61.2125C123.118 61.2125 121.506 62.9526 121.506 65.0992C121.506 67.2457 123.118 68.9859 125.106 68.9859Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 77.7286C102.788 77.7286 104.4 75.9885 104.4 73.8419C104.4 71.6954 102.788 69.9552 100.8 69.9552C98.8117 69.9552 97.1999 71.6954 97.1999 73.8419C97.1999 75.9885 98.8117 77.7286 100.8 77.7286Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M108.898 77.7286C110.886 77.7286 112.498 75.9885 112.498 73.8419C112.498 71.6954 110.886 69.9552 108.898 69.9552C106.91 69.9552 105.298 71.6954 105.298 73.8419C105.298 75.9885 106.91 77.7286 108.898 77.7286Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M116.996 77.7286C118.984 77.7286 120.596 75.9885 120.596 73.8419C120.596 71.6954 118.984 69.9552 116.996 69.9552C115.008 69.9552 113.396 71.6954 113.396 73.8419C113.396 75.9885 115.008 77.7286 116.996 77.7286Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M125.106 77.7286C127.094 77.7286 128.706 75.9885 128.706 73.8419C128.706 71.6954 127.094 69.9552 125.106 69.9552C123.118 69.9552 121.506 71.6954 121.506 73.8419C121.506 75.9885 123.118 77.7286 125.106 77.7286Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 86.4714C102.788 86.4714 104.4 84.7312 104.4 82.5847C104.4 80.4381 102.788 78.6979 100.8 78.6979C98.8117 78.6979 97.1999 80.4381 97.1999 82.5847C97.1999 84.7312 98.8117 86.4714 100.8 86.4714Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M108.898 86.4714C110.886 86.4714 112.498 84.7312 112.498 82.5847C112.498 80.4381 110.886 78.6979 108.898 78.6979C106.91 78.6979 105.298 80.4381 105.298 82.5847C105.298 84.7312 106.91 86.4714 108.898 86.4714Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M116.996 86.4714C118.984 86.4714 120.596 84.7312 120.596 82.5847C120.596 80.4381 118.984 78.6979 116.996 78.6979C115.008 78.6979 113.396 80.4381 113.396 82.5847C113.396 84.7312 115.008 86.4714 116.996 86.4714Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M125.106 86.4714C127.094 86.4714 128.706 84.7312 128.706 82.5847C128.706 80.4381 127.094 78.6979 125.106 78.6979C123.118 78.6979 121.506 80.4381 121.506 82.5847C121.506 84.7312 123.118 86.4714 125.106 86.4714Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 95.2273C102.788 95.2273 104.4 93.4872 104.4 91.3406C104.4 89.1941 102.788 87.4539 100.8 87.4539C98.8117 87.4539 97.1999 89.1941 97.1999 91.3406C97.1999 93.4872 98.8117 95.2273 100.8 95.2273Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M108.898 95.2273C110.886 95.2273 112.498 93.4872 112.498 91.3406C112.498 89.1941 110.886 87.4539 108.898 87.4539C106.91 87.4539 105.298 89.1941 105.298 91.3406C105.298 93.4872 106.91 95.2273 108.898 95.2273Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M116.996 95.2273C118.984 95.2273 120.596 93.4872 120.596 91.3406C120.596 89.1941 118.984 87.4539 116.996 87.4539C115.008 87.4539 113.396 89.1941 113.396 91.3406C113.396 93.4872 115.008 95.2273 116.996 95.2273Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M125.106 95.2273C127.094 95.2273 128.706 93.4872 128.706 91.3406C128.706 89.1941 127.094 87.4539 125.106 87.4539C123.118 87.4539 121.506 89.1941 121.506 91.3406C121.506 93.4872 123.118 95.2273 125.106 95.2273Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M133.204 95.2273C135.192 95.2273 136.804 93.4872 136.804 91.3406C136.804 89.1941 135.192 87.4539 133.204 87.4539C131.216 87.4539 129.604 89.1941 129.604 91.3406C129.604 93.4872 131.216 95.2273 133.204 95.2273Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 103.97C102.788 103.97 104.4 102.23 104.4 100.083C104.4 97.9368 102.788 96.1967 100.8 96.1967C98.8117 96.1967 97.1999 97.9368 97.1999 100.083C97.1999 102.23 98.8117 103.97 100.8 103.97Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M108.898 103.97C110.886 103.97 112.498 102.23 112.498 100.083C112.498 97.9368 110.886 96.1967 108.898 96.1967C106.91 96.1967 105.298 97.9368 105.298 100.083C105.298 102.23 106.91 103.97 108.898 103.97Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M116.996 103.97C118.984 103.97 120.596 102.23 120.596 100.083C120.596 97.9368 118.984 96.1967 116.996 96.1967C115.008 96.1967 113.396 97.9368 113.396 100.083C113.396 102.23 115.008 103.97 116.996 103.97Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M125.106 103.97C127.094 103.97 128.706 102.23 128.706 100.083C128.706 97.9368 127.094 96.1967 125.106 96.1967C123.118 96.1967 121.506 97.9368 121.506 100.083C121.506 102.23 123.118 103.97 125.106 103.97Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M133.204 103.97C135.192 103.97 136.804 102.23 136.804 100.083C136.804 97.9368 135.192 96.1967 133.204 96.1967C131.216 96.1967 129.604 97.9368 129.604 100.083C129.604 102.23 131.216 103.97 133.204 103.97Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 112.713C94.6903 112.713 96.3021 110.973 96.3021 108.826C96.3021 106.68 94.6903 104.939 92.7021 104.939C90.7138 104.939 89.102 106.68 89.102 108.826C89.102 110.973 90.7138 112.713 92.7021 112.713Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 112.713C102.788 112.713 104.4 110.973 104.4 108.826C104.4 106.68 102.788 104.939 100.8 104.939C98.8117 104.939 97.1999 106.68 97.1999 108.826C97.1999 110.973 98.8117 112.713 100.8 112.713Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M108.898 112.713C110.886 112.713 112.498 110.973 112.498 108.826C112.498 106.68 110.886 104.939 108.898 104.939C106.91 104.939 105.298 106.68 105.298 108.826C105.298 110.973 106.91 112.713 108.898 112.713Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M116.996 112.713C118.984 112.713 120.596 110.973 120.596 108.826C120.596 106.68 118.984 104.939 116.996 104.939C115.008 104.939 113.396 106.68 113.396 108.826C113.396 110.973 115.008 112.713 116.996 112.713Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M125.106 112.713C127.094 112.713 128.706 110.973 128.706 108.826C128.706 106.68 127.094 104.939 125.106 104.939C123.118 104.939 121.506 106.68 121.506 108.826C121.506 110.973 123.118 112.713 125.106 112.713Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M133.204 112.713C135.192 112.713 136.804 110.973 136.804 108.826C136.804 106.68 135.192 104.939 133.204 104.939C131.216 104.939 129.604 106.68 129.604 108.826C129.604 110.973 131.216 112.713 133.204 112.713Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M141.302 112.713C143.29 112.713 144.902 110.973 144.902 108.826C144.902 106.68 143.29 104.939 141.302 104.939C139.314 104.939 137.702 106.68 137.702 108.826C137.702 110.973 139.314 112.713 141.302 112.713Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M149.4 112.713C151.388 112.713 153 110.973 153 108.826C153 106.68 151.388 104.939 149.4 104.939C147.412 104.939 145.8 106.68 145.8 108.826C145.8 110.973 147.412 112.713 149.4 112.713Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 121.456C94.6903 121.456 96.3021 119.715 96.3021 117.569C96.3021 115.422 94.6903 113.682 92.7021 113.682C90.7138 113.682 89.102 115.422 89.102 117.569C89.102 119.715 90.7138 121.456 92.7021 121.456Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 121.456C102.788 121.456 104.4 119.715 104.4 117.569C104.4 115.422 102.788 113.682 100.8 113.682C98.8117 113.682 97.1999 115.422 97.1999 117.569C97.1999 119.715 98.8117 121.456 100.8 121.456Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M108.898 121.456C110.886 121.456 112.498 119.715 112.498 117.569C112.498 115.422 110.886 113.682 108.898 113.682C106.91 113.682 105.298 115.422 105.298 117.569C105.298 119.715 106.91 121.456 108.898 121.456Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M116.996 121.456C118.984 121.456 120.596 119.715 120.596 117.569C120.596 115.422 118.984 113.682 116.996 113.682C115.008 113.682 113.396 115.422 113.396 117.569C113.396 119.715 115.008 121.456 116.996 121.456Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M125.106 121.456C127.094 121.456 128.706 119.715 128.706 117.569C128.706 115.422 127.094 113.682 125.106 113.682C123.118 113.682 121.506 115.422 121.506 117.569C121.506 119.715 123.118 121.456 125.106 121.456Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M133.204 121.456C135.192 121.456 136.804 119.715 136.804 117.569C136.804 115.422 135.192 113.682 133.204 113.682C131.216 113.682 129.604 115.422 129.604 117.569C129.604 119.715 131.216 121.456 133.204 121.456Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M141.302 121.456C143.29 121.456 144.902 119.715 144.902 117.569C144.902 115.422 143.29 113.682 141.302 113.682C139.314 113.682 137.702 115.422 137.702 117.569C137.702 119.715 139.314 121.456 141.302 121.456Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 130.198C94.6903 130.198 96.3021 128.458 96.3021 126.312C96.3021 124.165 94.6903 122.425 92.7021 122.425C90.7138 122.425 89.102 124.165 89.102 126.312C89.102 128.458 90.7138 130.198 92.7021 130.198Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 130.198C102.788 130.198 104.4 128.458 104.4 126.312C104.4 124.165 102.788 122.425 100.8 122.425C98.8117 122.425 97.1999 124.165 97.1999 126.312C97.1999 128.458 98.8117 130.198 100.8 130.198Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M108.898 130.198C110.886 130.198 112.498 128.458 112.498 126.312C112.498 124.165 110.886 122.425 108.898 122.425C106.91 122.425 105.298 124.165 105.298 126.312C105.298 128.458 106.91 130.198 108.898 130.198Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M116.996 130.198C118.984 130.198 120.596 128.458 120.596 126.312C120.596 124.165 118.984 122.425 116.996 122.425C115.008 122.425 113.396 124.165 113.396 126.312C113.396 128.458 115.008 130.198 116.996 130.198Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M125.106 130.198C127.094 130.198 128.706 128.458 128.706 126.312C128.706 124.165 127.094 122.425 125.106 122.425C123.118 122.425 121.506 124.165 121.506 126.312C121.506 128.458 123.118 130.198 125.106 130.198Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M133.204 130.198C135.192 130.198 136.804 128.458 136.804 126.312C136.804 124.165 135.192 122.425 133.204 122.425C131.216 122.425 129.604 124.165 129.604 126.312C129.604 128.458 131.216 130.198 133.204 130.198Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M141.302 130.198C143.29 130.198 144.902 128.458 144.902 126.312C144.902 124.165 143.29 122.425 141.302 122.425C139.314 122.425 137.702 124.165 137.702 126.312C137.702 128.458 139.314 130.198 141.302 130.198Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 138.941C94.6903 138.941 96.3021 137.201 96.3021 135.054C96.3021 132.908 94.6903 131.168 92.7021 131.168C90.7138 131.168 89.102 132.908 89.102 135.054C89.102 137.201 90.7138 138.941 92.7021 138.941Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 138.941C102.788 138.941 104.4 137.201 104.4 135.054C104.4 132.908 102.788 131.168 100.8 131.168C98.8117 131.168 97.1999 132.908 97.1999 135.054C97.1999 137.201 98.8117 138.941 100.8 138.941Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M108.898 138.941C110.886 138.941 112.498 137.201 112.498 135.054C112.498 132.908 110.886 131.168 108.898 131.168C106.91 131.168 105.298 132.908 105.298 135.054C105.298 137.201 106.91 138.941 108.898 138.941Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M116.996 138.941C118.984 138.941 120.596 137.201 120.596 135.054C120.596 132.908 118.984 131.168 116.996 131.168C115.008 131.168 113.396 132.908 113.396 135.054C113.396 137.201 115.008 138.941 116.996 138.941Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M125.106 138.941C127.094 138.941 128.706 137.201 128.706 135.054C128.706 132.908 127.094 131.168 125.106 131.168C123.118 131.168 121.506 132.908 121.506 135.054C121.506 137.201 123.118 138.941 125.106 138.941Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 147.697C94.6903 147.697 96.3021 145.957 96.3021 143.81C96.3021 141.664 94.6903 139.924 92.7021 139.924C90.7138 139.924 89.102 141.664 89.102 143.81C89.102 145.957 90.7138 147.697 92.7021 147.697Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 147.697C102.788 147.697 104.4 145.957 104.4 143.81C104.4 141.664 102.788 139.924 100.8 139.924C98.8117 139.924 97.1999 141.664 97.1999 143.81C97.1999 145.957 98.8117 147.697 100.8 147.697Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M108.898 147.697C110.886 147.697 112.498 145.957 112.498 143.81C112.498 141.664 110.886 139.924 108.898 139.924C106.91 139.924 105.298 141.664 105.298 143.81C105.298 145.957 106.91 147.697 108.898 147.697Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M116.996 147.697C118.984 147.697 120.596 145.957 120.596 143.81C120.596 141.664 118.984 139.924 116.996 139.924C115.008 139.924 113.396 141.664 113.396 143.81C113.396 145.957 115.008 147.697 116.996 147.697Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M125.106 147.697C127.094 147.697 128.706 145.957 128.706 143.81C128.706 141.664 127.094 139.924 125.106 139.924C123.118 139.924 121.506 141.664 121.506 143.81C121.506 145.957 123.118 147.697 125.106 147.697Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 156.44C94.6903 156.44 96.3021 154.7 96.3021 152.553C96.3021 150.407 94.6903 148.666 92.7021 148.666C90.7138 148.666 89.102 150.407 89.102 152.553C89.102 154.7 90.7138 156.44 92.7021 156.44Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 156.44C102.788 156.44 104.4 154.7 104.4 152.553C104.4 150.407 102.788 148.666 100.8 148.666C98.8117 148.666 97.1999 150.407 97.1999 152.553C97.1999 154.7 98.8117 156.44 100.8 156.44Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M108.898 156.44C110.886 156.44 112.498 154.7 112.498 152.553C112.498 150.407 110.886 148.666 108.898 148.666C106.91 148.666 105.298 150.407 105.298 152.553C105.298 154.7 106.91 156.44 108.898 156.44Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M116.996 156.44C118.984 156.44 120.596 154.7 120.596 152.553C120.596 150.407 118.984 148.666 116.996 148.666C115.008 148.666 113.396 150.407 113.396 152.553C113.396 154.7 115.008 156.44 116.996 156.44Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 165.183C94.6903 165.183 96.3021 163.442 96.3021 161.296C96.3021 159.149 94.6903 157.409 92.7021 157.409C90.7138 157.409 89.102 159.149 89.102 161.296C89.102 163.442 90.7138 165.183 92.7021 165.183Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 165.183C102.788 165.183 104.4 163.442 104.4 161.296C104.4 159.149 102.788 157.409 100.8 157.409C98.8117 157.409 97.1999 159.149 97.1999 161.296C97.1999 163.442 98.8117 165.183 100.8 165.183Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M108.898 165.183C110.886 165.183 112.498 163.442 112.498 161.296C112.498 159.149 110.886 157.409 108.898 157.409C106.91 157.409 105.298 159.149 105.298 161.296C105.298 163.442 106.91 165.183 108.898 165.183Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M116.996 165.183C118.984 165.183 120.596 163.442 120.596 161.296C120.596 159.149 118.984 157.409 116.996 157.409C115.008 157.409 113.396 159.149 113.396 161.296C113.396 163.442 115.008 165.183 116.996 165.183Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M125.106 165.183C127.094 165.183 128.706 163.442 128.706 161.296C128.706 159.149 127.094 157.409 125.106 157.409C123.118 157.409 121.506 159.149 121.506 161.296C121.506 163.442 123.118 165.183 125.106 165.183Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 173.925C94.6903 173.925 96.3021 172.185 96.3021 170.039C96.3021 167.892 94.6903 166.152 92.7021 166.152C90.7138 166.152 89.102 167.892 89.102 170.039C89.102 172.185 90.7138 173.925 92.7021 173.925Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 173.925C102.788 173.925 104.4 172.185 104.4 170.039C104.4 167.892 102.788 166.152 100.8 166.152C98.8117 166.152 97.1999 167.892 97.1999 170.039C97.1999 172.185 98.8117 173.925 100.8 173.925Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M108.898 173.925C110.886 173.925 112.498 172.185 112.498 170.039C112.498 167.892 110.886 166.152 108.898 166.152C106.91 166.152 105.298 167.892 105.298 170.039C105.298 172.185 106.91 173.925 108.898 173.925Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M116.996 173.925C118.984 173.925 120.596 172.185 120.596 170.039C120.596 167.892 118.984 166.152 116.996 166.152C115.008 166.152 113.396 167.892 113.396 170.039C113.396 172.185 115.008 173.925 116.996 173.925Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M125.106 173.925C127.094 173.925 128.706 172.185 128.706 170.039C128.706 167.892 127.094 166.152 125.106 166.152C123.118 166.152 121.506 167.892 121.506 170.039C121.506 172.185 123.118 173.925 125.106 173.925Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 182.668C94.6903 182.668 96.3021 180.928 96.3021 178.781C96.3021 176.635 94.6903 174.895 92.7021 174.895C90.7138 174.895 89.102 176.635 89.102 178.781C89.102 180.928 90.7138 182.668 92.7021 182.668Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 182.668C102.788 182.668 104.4 180.928 104.4 178.781C104.4 176.635 102.788 174.895 100.8 174.895C98.8117 174.895 97.1999 176.635 97.1999 178.781C97.1999 180.928 98.8117 182.668 100.8 182.668Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M108.898 182.668C110.886 182.668 112.498 180.928 112.498 178.781C112.498 176.635 110.886 174.895 108.898 174.895C106.91 174.895 105.298 176.635 105.298 178.781C105.298 180.928 106.91 182.668 108.898 182.668Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M116.996 182.668C118.984 182.668 120.596 180.928 120.596 178.781C120.596 176.635 118.984 174.895 116.996 174.895C115.008 174.895 113.396 176.635 113.396 178.781C113.396 180.928 115.008 182.668 116.996 182.668Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M125.106 182.668C127.094 182.668 128.706 180.928 128.706 178.781C128.706 176.635 127.094 174.895 125.106 174.895C123.118 174.895 121.506 176.635 121.506 178.781C121.506 180.928 123.118 182.668 125.106 182.668Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 191.411C94.6903 191.411 96.3021 189.671 96.3021 187.524C96.3021 185.378 94.6903 183.637 92.7021 183.637C90.7138 183.637 89.102 185.378 89.102 187.524C89.102 189.671 90.7138 191.411 92.7021 191.411Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 191.411C102.788 191.411 104.4 189.671 104.4 187.524C104.4 185.378 102.788 183.637 100.8 183.637C98.8117 183.637 97.1999 185.378 97.1999 187.524C97.1999 189.671 98.8117 191.411 100.8 191.411Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M108.898 191.411C110.886 191.411 112.498 189.671 112.498 187.524C112.498 185.378 110.886 183.637 108.898 183.637C106.91 183.637 105.298 185.378 105.298 187.524C105.298 189.671 106.91 191.411 108.898 191.411Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M116.996 191.411C118.984 191.411 120.596 189.671 120.596 187.524C120.596 185.378 118.984 183.637 116.996 183.637C115.008 183.637 113.396 185.378 113.396 187.524C113.396 189.671 115.008 191.411 116.996 191.411Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M125.106 191.411C127.094 191.411 128.706 189.671 128.706 187.524C128.706 185.378 127.094 183.637 125.106 183.637C123.118 183.637 121.506 185.378 121.506 187.524C121.506 189.671 123.118 191.411 125.106 191.411Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M84.6041 200.167C86.5924 200.167 88.2041 198.427 88.2041 196.28C88.2041 194.133 86.5924 192.393 84.6041 192.393C82.6158 192.393 81.004 194.133 81.004 196.28C81.004 198.427 82.6158 200.167 84.6041 200.167Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 200.167C94.6903 200.167 96.3021 198.427 96.3021 196.28C96.3021 194.133 94.6903 192.393 92.7021 192.393C90.7138 192.393 89.102 194.133 89.102 196.28C89.102 198.427 90.7138 200.167 92.7021 200.167Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 200.167C102.788 200.167 104.4 198.427 104.4 196.28C104.4 194.133 102.788 192.393 100.8 192.393C98.8117 192.393 97.1999 194.133 97.1999 196.28C97.1999 198.427 98.8117 200.167 100.8 200.167Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M108.898 200.167C110.886 200.167 112.498 198.427 112.498 196.28C112.498 194.133 110.886 192.393 108.898 192.393C106.91 192.393 105.298 194.133 105.298 196.28C105.298 198.427 106.91 200.167 108.898 200.167Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M116.996 200.167C118.984 200.167 120.596 198.427 120.596 196.28C120.596 194.133 118.984 192.393 116.996 192.393C115.008 192.393 113.396 194.133 113.396 196.28C113.396 198.427 115.008 200.167 116.996 200.167Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M84.6041 208.91C86.5924 208.91 88.2041 207.169 88.2041 205.023C88.2041 202.876 86.5924 201.136 84.6041 201.136C82.6158 201.136 81.004 202.876 81.004 205.023C81.004 207.169 82.6158 208.91 84.6041 208.91Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 208.91C94.6903 208.91 96.3021 207.169 96.3021 205.023C96.3021 202.876 94.6903 201.136 92.7021 201.136C90.7138 201.136 89.102 202.876 89.102 205.023C89.102 207.169 90.7138 208.91 92.7021 208.91Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 208.91C102.788 208.91 104.4 207.169 104.4 205.023C104.4 202.876 102.788 201.136 100.8 201.136C98.8117 201.136 97.1999 202.876 97.1999 205.023C97.1999 207.169 98.8117 208.91 100.8 208.91Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M108.898 208.91C110.886 208.91 112.498 207.169 112.498 205.023C112.498 202.876 110.886 201.136 108.898 201.136C106.91 201.136 105.298 202.876 105.298 205.023C105.298 207.169 106.91 208.91 108.898 208.91Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M84.6041 217.652C86.5924 217.652 88.2041 215.912 88.2041 213.766C88.2041 211.619 86.5924 209.879 84.6041 209.879C82.6158 209.879 81.004 211.619 81.004 213.766C81.004 215.912 82.6158 217.652 84.6041 217.652Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 217.652C94.6903 217.652 96.3021 215.912 96.3021 213.766C96.3021 211.619 94.6903 209.879 92.7021 209.879C90.7138 209.879 89.102 211.619 89.102 213.766C89.102 215.912 90.7138 217.652 92.7021 217.652Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 217.652C102.788 217.652 104.4 215.912 104.4 213.766C104.4 211.619 102.788 209.879 100.8 209.879C98.8117 209.879 97.1999 211.619 97.1999 213.766C97.1999 215.912 98.8117 217.652 100.8 217.652Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M108.898 217.652C110.886 217.652 112.498 215.912 112.498 213.766C112.498 211.619 110.886 209.879 108.898 209.879C106.91 209.879 105.298 211.619 105.298 213.766C105.298 215.912 106.91 217.652 108.898 217.652Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 226.395C78.4821 226.395 80.0939 224.655 80.0939 222.508C80.0939 220.362 78.4821 218.622 76.4939 218.622C74.5056 218.622 72.8938 220.362 72.8938 222.508C72.8938 224.655 74.5056 226.395 76.4939 226.395Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M84.6041 226.395C86.5924 226.395 88.2041 224.655 88.2041 222.508C88.2041 220.362 86.5924 218.622 84.6041 218.622C82.6158 218.622 81.004 220.362 81.004 222.508C81.004 224.655 82.6158 226.395 84.6041 226.395Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 226.395C94.6903 226.395 96.3021 224.655 96.3021 222.508C96.3021 220.362 94.6903 218.622 92.7021 218.622C90.7138 218.622 89.102 220.362 89.102 222.508C89.102 224.655 90.7138 226.395 92.7021 226.395Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 226.395C102.788 226.395 104.4 224.655 104.4 222.508C104.4 220.362 102.788 218.622 100.8 218.622C98.8117 218.622 97.1999 220.362 97.1999 222.508C97.1999 224.655 98.8117 226.395 100.8 226.395Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 235.138C78.4821 235.138 80.0939 233.398 80.0939 231.251C80.0939 229.104 78.4821 227.364 76.4939 227.364C74.5056 227.364 72.8938 229.104 72.8938 231.251C72.8938 233.398 74.5056 235.138 76.4939 235.138Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M84.6041 235.138C86.5924 235.138 88.2041 233.398 88.2041 231.251C88.2041 229.104 86.5924 227.364 84.6041 227.364C82.6158 227.364 81.004 229.104 81.004 231.251C81.004 233.398 82.6158 235.138 84.6041 235.138Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 235.138C94.6903 235.138 96.3021 233.398 96.3021 231.251C96.3021 229.104 94.6903 227.364 92.7021 227.364C90.7138 227.364 89.102 229.104 89.102 231.251C89.102 233.398 90.7138 235.138 92.7021 235.138Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 235.138C102.788 235.138 104.4 233.398 104.4 231.251C104.4 229.104 102.788 227.364 100.8 227.364C98.8117 227.364 97.1999 229.104 97.1999 231.251C97.1999 233.398 98.8117 235.138 100.8 235.138Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 243.894C78.4821 243.894 80.0939 242.154 80.0939 240.007C80.0939 237.86 78.4821 236.12 76.4939 236.12C74.5056 236.12 72.8938 237.86 72.8938 240.007C72.8938 242.154 74.5056 243.894 76.4939 243.894Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M84.6041 243.894C86.5924 243.894 88.2041 242.154 88.2041 240.007C88.2041 237.86 86.5924 236.12 84.6041 236.12C82.6158 236.12 81.004 237.86 81.004 240.007C81.004 242.154 82.6158 243.894 84.6041 243.894Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 243.894C94.6903 243.894 96.3021 242.154 96.3021 240.007C96.3021 237.86 94.6903 236.12 92.7021 236.12C90.7138 236.12 89.102 237.86 89.102 240.007C89.102 242.154 90.7138 243.894 92.7021 243.894Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 252.636C78.4821 252.636 80.0939 250.896 80.0939 248.75C80.0939 246.603 78.4821 244.863 76.4939 244.863C74.5056 244.863 72.8938 246.603 72.8938 248.75C72.8938 250.896 74.5056 252.636 76.4939 252.636Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M84.6041 252.636C86.5924 252.636 88.2041 250.896 88.2041 248.75C88.2041 246.603 86.5924 244.863 84.6041 244.863C82.6158 244.863 81.004 246.603 81.004 248.75C81.004 250.896 82.6158 252.636 84.6041 252.636Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 252.636C94.6903 252.636 96.3021 250.896 96.3021 248.75C96.3021 246.603 94.6903 244.863 92.7021 244.863C90.7138 244.863 89.102 246.603 89.102 248.75C89.102 250.896 90.7138 252.636 92.7021 252.636Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 252.636C102.788 252.636 104.4 250.896 104.4 248.75C104.4 246.603 102.788 244.863 100.8 244.863C98.8117 244.863 97.1999 246.603 97.1999 248.75C97.1999 250.896 98.8117 252.636 100.8 252.636Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 261.379C78.4821 261.379 80.0939 259.639 80.0939 257.492C80.0939 255.346 78.4821 253.606 76.4939 253.606C74.5056 253.606 72.8938 255.346 72.8938 257.492C72.8938 259.639 74.5056 261.379 76.4939 261.379Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M84.6041 261.379C86.5924 261.379 88.2041 259.639 88.2041 257.492C88.2041 255.346 86.5924 253.606 84.6041 253.606C82.6158 253.606 81.004 255.346 81.004 257.492C81.004 259.639 82.6158 261.379 84.6041 261.379Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 261.379C94.6903 261.379 96.3021 259.639 96.3021 257.492C96.3021 255.346 94.6903 253.606 92.7021 253.606C90.7138 253.606 89.102 255.346 89.102 257.492C89.102 259.639 90.7138 261.379 92.7021 261.379Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 261.379C102.788 261.379 104.4 259.639 104.4 257.492C104.4 255.346 102.788 253.606 100.8 253.606C98.8117 253.606 97.1999 255.346 97.1999 257.492C97.1999 259.639 98.8117 261.379 100.8 261.379Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 270.122C78.4821 270.122 80.0939 268.382 80.0939 266.235C80.0939 264.089 78.4821 262.349 76.4939 262.349C74.5056 262.349 72.8938 264.089 72.8938 266.235C72.8938 268.382 74.5056 270.122 76.4939 270.122Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M84.6041 270.122C86.5924 270.122 88.2041 268.382 88.2041 266.235C88.2041 264.089 86.5924 262.349 84.6041 262.349C82.6158 262.349 81.004 264.089 81.004 266.235C81.004 268.382 82.6158 270.122 84.6041 270.122Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 270.122C94.6903 270.122 96.3021 268.382 96.3021 266.235C96.3021 264.089 94.6903 262.349 92.7021 262.349C90.7138 262.349 89.102 264.089 89.102 266.235C89.102 268.382 90.7138 270.122 92.7021 270.122Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 278.865C78.4821 278.865 80.0939 277.125 80.0939 274.978C80.0939 272.831 78.4821 271.091 76.4939 271.091C74.5056 271.091 72.8938 272.831 72.8938 274.978C72.8938 277.125 74.5056 278.865 76.4939 278.865Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M84.6041 278.865C86.5924 278.865 88.2041 277.125 88.2041 274.978C88.2041 272.831 86.5924 271.091 84.6041 271.091C82.6158 271.091 81.004 272.831 81.004 274.978C81.004 277.125 82.6158 278.865 84.6041 278.865Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 278.865C94.6903 278.865 96.3021 277.125 96.3021 274.978C96.3021 272.831 94.6903 271.091 92.7021 271.091C90.7138 271.091 89.102 272.831 89.102 274.978C89.102 277.125 90.7138 278.865 92.7021 278.865Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 287.607C78.4821 287.607 80.0939 285.867 80.0939 283.721C80.0939 281.574 78.4821 279.834 76.4939 279.834C74.5056 279.834 72.8938 281.574 72.8938 283.721C72.8938 285.867 74.5056 287.607 76.4939 287.607Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M84.6041 287.607C86.5924 287.607 88.2041 285.867 88.2041 283.721C88.2041 281.574 86.5924 279.834 84.6041 279.834C82.6158 279.834 81.004 281.574 81.004 283.721C81.004 285.867 82.6158 287.607 84.6041 287.607Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 296.363C78.4821 296.363 80.0939 294.623 80.0939 292.477C80.0939 290.33 78.4821 288.59 76.4939 288.59C74.5056 288.59 72.8938 290.33 72.8938 292.477C72.8938 294.623 74.5056 296.363 76.4939 296.363Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M84.6041 296.363C86.5924 296.363 88.2041 294.623 88.2041 292.477C88.2041 290.33 86.5924 288.59 84.6041 288.59C82.6158 288.59 81.004 290.33 81.004 292.477C81.004 294.623 82.6158 296.363 84.6041 296.363Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 296.363C94.6903 296.363 96.3021 294.623 96.3021 292.477C96.3021 290.33 94.6903 288.59 92.7021 288.59C90.7138 288.59 89.102 290.33 89.102 292.477C89.102 294.623 90.7138 296.363 92.7021 296.363Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 305.106C78.4821 305.106 80.0939 303.366 80.0939 301.219C80.0939 299.073 78.4821 297.333 76.4939 297.333C74.5056 297.333 72.8938 299.073 72.8938 301.219C72.8938 303.366 74.5056 305.106 76.4939 305.106Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M84.6041 305.106C86.5924 305.106 88.2041 303.366 88.2041 301.219C88.2041 299.073 86.5924 297.333 84.6041 297.333C82.6158 297.333 81.004 299.073 81.004 301.219C81.004 303.366 82.6158 305.106 84.6041 305.106Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 305.106C94.6903 305.106 96.3021 303.366 96.3021 301.219C96.3021 299.073 94.6903 297.333 92.7021 297.333C90.7138 297.333 89.102 299.073 89.102 301.219C89.102 303.366 90.7138 305.106 92.7021 305.106Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 313.849C78.4821 313.849 80.0939 312.109 80.0939 309.962C80.0939 307.816 78.4821 306.075 76.4939 306.075C74.5056 306.075 72.8938 307.816 72.8938 309.962C72.8938 312.109 74.5056 313.849 76.4939 313.849Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M84.6041 313.849C86.5924 313.849 88.2041 312.109 88.2041 309.962C88.2041 307.816 86.5924 306.075 84.6041 306.075C82.6158 306.075 81.004 307.816 81.004 309.962C81.004 312.109 82.6158 313.849 84.6041 313.849Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 313.849C94.6903 313.849 96.3021 312.109 96.3021 309.962C96.3021 307.816 94.6903 306.075 92.7021 306.075C90.7138 306.075 89.102 307.816 89.102 309.962C89.102 312.109 90.7138 313.849 92.7021 313.849Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 322.592C78.4821 322.592 80.0939 320.852 80.0939 318.705C80.0939 316.558 78.4821 314.818 76.4939 314.818C74.5056 314.818 72.8938 316.558 72.8938 318.705C72.8938 320.852 74.5056 322.592 76.4939 322.592Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M84.6041 322.592C86.5924 322.592 88.2041 320.852 88.2041 318.705C88.2041 316.558 86.5924 314.818 84.6041 314.818C82.6158 314.818 81.004 316.558 81.004 318.705C81.004 320.852 82.6158 322.592 84.6041 322.592Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 322.592C94.6903 322.592 96.3021 320.852 96.3021 318.705C96.3021 316.558 94.6903 314.818 92.7021 314.818C90.7138 314.818 89.102 316.558 89.102 318.705C89.102 320.852 90.7138 322.592 92.7021 322.592Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 331.334C78.4821 331.334 80.0939 329.594 80.0939 327.448C80.0939 325.301 78.4821 323.561 76.4939 323.561C74.5056 323.561 72.8938 325.301 72.8938 327.448C72.8938 329.594 74.5056 331.334 76.4939 331.334Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M84.6041 331.334C86.5924 331.334 88.2041 329.594 88.2041 327.448C88.2041 325.301 86.5924 323.561 84.6041 323.561C82.6158 323.561 81.004 325.301 81.004 327.448C81.004 329.594 82.6158 331.334 84.6041 331.334Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 331.334C94.6903 331.334 96.3021 329.594 96.3021 327.448C96.3021 325.301 94.6903 323.561 92.7021 323.561C90.7138 323.561 89.102 325.301 89.102 327.448C89.102 329.594 90.7138 331.334 92.7021 331.334Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 331.334C102.788 331.334 104.4 329.594 104.4 327.448C104.4 325.301 102.788 323.561 100.8 323.561C98.8117 323.561 97.1999 325.301 97.1999 327.448C97.1999 329.594 98.8117 331.334 100.8 331.334Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 340.077C70.3842 340.077 71.996 338.337 71.996 336.19C71.996 334.044 70.3842 332.304 68.3959 332.304C66.4076 332.304 64.7958 334.044 64.7958 336.19C64.7958 338.337 66.4076 340.077 68.3959 340.077Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 340.077C78.4821 340.077 80.0939 338.337 80.0939 336.19C80.0939 334.044 78.4821 332.304 76.4939 332.304C74.5056 332.304 72.8938 334.044 72.8938 336.19C72.8938 338.337 74.5056 340.077 76.4939 340.077Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M84.6041 340.077C86.5924 340.077 88.2041 338.337 88.2041 336.19C88.2041 334.044 86.5924 332.304 84.6041 332.304C82.6158 332.304 81.004 334.044 81.004 336.19C81.004 338.337 82.6158 340.077 84.6041 340.077Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 340.077C94.6903 340.077 96.3021 338.337 96.3021 336.19C96.3021 334.044 94.6903 332.304 92.7021 332.304C90.7138 332.304 89.102 334.044 89.102 336.19C89.102 338.337 90.7138 340.077 92.7021 340.077Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 340.077C102.788 340.077 104.4 338.337 104.4 336.19C104.4 334.044 102.788 332.304 100.8 332.304C98.8117 332.304 97.1999 334.044 97.1999 336.19C97.1999 338.337 98.8117 340.077 100.8 340.077Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 348.833C70.3842 348.833 71.996 347.093 71.996 344.946C71.996 342.8 70.3842 341.06 68.3959 341.06C66.4076 341.06 64.7958 342.8 64.7958 344.946C64.7958 347.093 66.4076 348.833 68.3959 348.833Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 348.833C78.4821 348.833 80.0939 347.093 80.0939 344.946C80.0939 342.8 78.4821 341.06 76.4939 341.06C74.5056 341.06 72.8938 342.8 72.8938 344.946C72.8938 347.093 74.5056 348.833 76.4939 348.833Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M84.6041 348.833C86.5924 348.833 88.2041 347.093 88.2041 344.946C88.2041 342.8 86.5924 341.06 84.6041 341.06C82.6158 341.06 81.004 342.8 81.004 344.946C81.004 347.093 82.6158 348.833 84.6041 348.833Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 348.833C94.6903 348.833 96.3021 347.093 96.3021 344.946C96.3021 342.8 94.6903 341.06 92.7021 341.06C90.7138 341.06 89.102 342.8 89.102 344.946C89.102 347.093 90.7138 348.833 92.7021 348.833Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 348.833C102.788 348.833 104.4 347.093 104.4 344.946C104.4 342.8 102.788 341.06 100.8 341.06C98.8117 341.06 97.1999 342.8 97.1999 344.946C97.1999 347.093 98.8117 348.833 100.8 348.833Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 357.576C70.3842 357.576 71.996 355.836 71.996 353.689C71.996 351.543 70.3842 349.802 68.3959 349.802C66.4076 349.802 64.7958 351.543 64.7958 353.689C64.7958 355.836 66.4076 357.576 68.3959 357.576Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 357.576C78.4821 357.576 80.0939 355.836 80.0939 353.689C80.0939 351.543 78.4821 349.802 76.4939 349.802C74.5056 349.802 72.8938 351.543 72.8938 353.689C72.8938 355.836 74.5056 357.576 76.4939 357.576Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M84.6041 357.576C86.5924 357.576 88.2041 355.836 88.2041 353.689C88.2041 351.543 86.5924 349.802 84.6041 349.802C82.6158 349.802 81.004 351.543 81.004 353.689C81.004 355.836 82.6158 357.576 84.6041 357.576Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 357.576C94.6903 357.576 96.3021 355.836 96.3021 353.689C96.3021 351.543 94.6903 349.802 92.7021 349.802C90.7138 349.802 89.102 351.543 89.102 353.689C89.102 355.836 90.7138 357.576 92.7021 357.576Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 366.319C70.3842 366.319 71.996 364.578 71.996 362.432C71.996 360.285 70.3842 358.545 68.3959 358.545C66.4076 358.545 64.7958 360.285 64.7958 362.432C64.7958 364.578 66.4076 366.319 68.3959 366.319Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 366.319C78.4821 366.319 80.0939 364.578 80.0939 362.432C80.0939 360.285 78.4821 358.545 76.4939 358.545C74.5056 358.545 72.8938 360.285 72.8938 362.432C72.8938 364.578 74.5056 366.319 76.4939 366.319Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M84.6041 366.319C86.5924 366.319 88.2041 364.578 88.2041 362.432C88.2041 360.285 86.5924 358.545 84.6041 358.545C82.6158 358.545 81.004 360.285 81.004 362.432C81.004 364.578 82.6158 366.319 84.6041 366.319Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 366.319C94.6903 366.319 96.3021 364.578 96.3021 362.432C96.3021 360.285 94.6903 358.545 92.7021 358.545C90.7138 358.545 89.102 360.285 89.102 362.432C89.102 364.578 90.7138 366.319 92.7021 366.319Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 375.061C62.2862 375.061 63.898 373.321 63.898 371.175C63.898 369.028 62.2862 367.288 60.2979 367.288C58.3097 367.288 56.6979 369.028 56.6979 371.175C56.6979 373.321 58.3097 375.061 60.2979 375.061Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 375.061C70.3842 375.061 71.996 373.321 71.996 371.175C71.996 369.028 70.3842 367.288 68.3959 367.288C66.4076 367.288 64.7958 369.028 64.7958 371.175C64.7958 373.321 66.4076 375.061 68.3959 375.061Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 375.061C78.4821 375.061 80.0939 373.321 80.0939 371.175C80.0939 369.028 78.4821 367.288 76.4939 367.288C74.5056 367.288 72.8938 369.028 72.8938 371.175C72.8938 373.321 74.5056 375.061 76.4939 375.061Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M84.6041 375.061C86.5924 375.061 88.2041 373.321 88.2041 371.175C88.2041 369.028 86.5924 367.288 84.6041 367.288C82.6158 367.288 81.004 369.028 81.004 371.175C81.004 373.321 82.6158 375.061 84.6041 375.061Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 383.804C62.2862 383.804 63.898 382.064 63.898 379.917C63.898 377.771 62.2862 376.031 60.2979 376.031C58.3097 376.031 56.6979 377.771 56.6979 379.917C56.6979 382.064 58.3097 383.804 60.2979 383.804Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 383.804C70.3842 383.804 71.996 382.064 71.996 379.917C71.996 377.771 70.3842 376.031 68.3959 376.031C66.4076 376.031 64.7958 377.771 64.7958 379.917C64.7958 382.064 66.4076 383.804 68.3959 383.804Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 383.804C78.4821 383.804 80.0939 382.064 80.0939 379.917C80.0939 377.771 78.4821 376.031 76.4939 376.031C74.5056 376.031 72.8938 377.771 72.8938 379.917C72.8938 382.064 74.5056 383.804 76.4939 383.804Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M84.6041 383.804C86.5924 383.804 88.2041 382.064 88.2041 379.917C88.2041 377.771 86.5924 376.031 84.6041 376.031C82.6158 376.031 81.004 377.771 81.004 379.917C81.004 382.064 82.6158 383.804 84.6041 383.804Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 383.804C94.6903 383.804 96.3021 382.064 96.3021 379.917C96.3021 377.771 94.6903 376.031 92.7021 376.031C90.7138 376.031 89.102 377.771 89.102 379.917C89.102 382.064 90.7138 383.804 92.7021 383.804Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 392.547C54.1883 392.547 55.8001 390.807 55.8001 388.66C55.8001 386.514 54.1883 384.773 52.2 384.773C50.2118 384.773 48.6 386.514 48.6 388.66C48.6 390.807 50.2118 392.547 52.2 392.547Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 392.547C62.2862 392.547 63.898 390.807 63.898 388.66C63.898 386.514 62.2862 384.773 60.2979 384.773C58.3097 384.773 56.6979 386.514 56.6979 388.66C56.6979 390.807 58.3097 392.547 60.2979 392.547Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 392.547C70.3842 392.547 71.996 390.807 71.996 388.66C71.996 386.514 70.3842 384.773 68.3959 384.773C66.4076 384.773 64.7958 386.514 64.7958 388.66C64.7958 390.807 66.4076 392.547 68.3959 392.547Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 392.547C78.4821 392.547 80.0939 390.807 80.0939 388.66C80.0939 386.514 78.4821 384.773 76.4939 384.773C74.5056 384.773 72.8938 386.514 72.8938 388.66C72.8938 390.807 74.5056 392.547 76.4939 392.547Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M84.6041 392.547C86.5924 392.547 88.2041 390.807 88.2041 388.66C88.2041 386.514 86.5924 384.773 84.6041 384.773C82.6158 384.773 81.004 386.514 81.004 388.66C81.004 390.807 82.6158 392.547 84.6041 392.547Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 401.303C54.1883 401.303 55.8001 399.563 55.8001 397.416C55.8001 395.27 54.1883 393.529 52.2 393.529C50.2118 393.529 48.6 395.27 48.6 397.416C48.6 399.563 50.2118 401.303 52.2 401.303Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 401.303C62.2862 401.303 63.898 399.563 63.898 397.416C63.898 395.27 62.2862 393.529 60.2979 393.529C58.3097 393.529 56.6979 395.27 56.6979 397.416C56.6979 399.563 58.3097 401.303 60.2979 401.303Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 401.303C70.3842 401.303 71.996 399.563 71.996 397.416C71.996 395.27 70.3842 393.529 68.3959 393.529C66.4076 393.529 64.7958 395.27 64.7958 397.416C64.7958 399.563 66.4076 401.303 68.3959 401.303Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 401.303C78.4821 401.303 80.0939 399.563 80.0939 397.416C80.0939 395.27 78.4821 393.529 76.4939 393.529C74.5056 393.529 72.8938 395.27 72.8938 397.416C72.8938 399.563 74.5056 401.303 76.4939 401.303Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 410.046C46.0903 410.046 47.7021 408.305 47.7021 406.159C47.7021 404.012 46.0903 402.272 44.102 402.272C42.1138 402.272 40.502 404.012 40.502 406.159C40.502 408.305 42.1138 410.046 44.102 410.046Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 410.046C54.1883 410.046 55.8001 408.305 55.8001 406.159C55.8001 404.012 54.1883 402.272 52.2 402.272C50.2118 402.272 48.6 404.012 48.6 406.159C48.6 408.305 50.2118 410.046 52.2 410.046Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 410.046C62.2862 410.046 63.898 408.305 63.898 406.159C63.898 404.012 62.2862 402.272 60.2979 402.272C58.3097 402.272 56.6979 404.012 56.6979 406.159C56.6979 408.305 58.3097 410.046 60.2979 410.046Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 410.046C70.3842 410.046 71.996 408.305 71.996 406.159C71.996 404.012 70.3842 402.272 68.3959 402.272C66.4076 402.272 64.7958 404.012 64.7958 406.159C64.7958 408.305 66.4076 410.046 68.3959 410.046Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 410.046C78.4821 410.046 80.0939 408.305 80.0939 406.159C80.0939 404.012 78.4821 402.272 76.4939 402.272C74.5056 402.272 72.8938 404.012 72.8938 406.159C72.8938 408.305 74.5056 410.046 76.4939 410.046Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 418.788C37.9923 418.788 39.6041 417.048 39.6041 414.902C39.6041 412.755 37.9923 411.015 36.0041 411.015C34.0158 411.015 32.404 412.755 32.404 414.902C32.404 417.048 34.0158 418.788 36.0041 418.788Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 418.788C46.0903 418.788 47.7021 417.048 47.7021 414.902C47.7021 412.755 46.0903 411.015 44.102 411.015C42.1138 411.015 40.502 412.755 40.502 414.902C40.502 417.048 42.1138 418.788 44.102 418.788Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 418.788C54.1883 418.788 55.8001 417.048 55.8001 414.902C55.8001 412.755 54.1883 411.015 52.2 411.015C50.2118 411.015 48.6 412.755 48.6 414.902C48.6 417.048 50.2118 418.788 52.2 418.788Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 418.788C62.2862 418.788 63.898 417.048 63.898 414.902C63.898 412.755 62.2862 411.015 60.2979 411.015C58.3097 411.015 56.6979 412.755 56.6979 414.902C56.6979 417.048 58.3097 418.788 60.2979 418.788Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 418.788C70.3842 418.788 71.996 417.048 71.996 414.902C71.996 412.755 70.3842 411.015 68.3959 411.015C66.4076 411.015 64.7958 412.755 64.7958 414.902C64.7958 417.048 66.4076 418.788 68.3959 418.788Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 418.788C78.4821 418.788 80.0939 417.048 80.0939 414.902C80.0939 412.755 78.4821 411.015 76.4939 411.015C74.5056 411.015 72.8938 412.755 72.8938 414.902C72.8938 417.048 74.5056 418.788 76.4939 418.788Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 427.531C37.9923 427.531 39.6041 425.791 39.6041 423.644C39.6041 421.498 37.9923 419.758 36.0041 419.758C34.0158 419.758 32.404 421.498 32.404 423.644C32.404 425.791 34.0158 427.531 36.0041 427.531Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 427.531C46.0903 427.531 47.7021 425.791 47.7021 423.644C47.7021 421.498 46.0903 419.758 44.102 419.758C42.1138 419.758 40.502 421.498 40.502 423.644C40.502 425.791 42.1138 427.531 44.102 427.531Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 427.531C54.1883 427.531 55.8001 425.791 55.8001 423.644C55.8001 421.498 54.1883 419.758 52.2 419.758C50.2118 419.758 48.6 421.498 48.6 423.644C48.6 425.791 50.2118 427.531 52.2 427.531Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 427.531C62.2862 427.531 63.898 425.791 63.898 423.644C63.898 421.498 62.2862 419.758 60.2979 419.758C58.3097 419.758 56.6979 421.498 56.6979 423.644C56.6979 425.791 58.3097 427.531 60.2979 427.531Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 427.531C70.3842 427.531 71.996 425.791 71.996 423.644C71.996 421.498 70.3842 419.758 68.3959 419.758C66.4076 419.758 64.7958 421.498 64.7958 423.644C64.7958 425.791 66.4076 427.531 68.3959 427.531Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 427.531C78.4821 427.531 80.0939 425.791 80.0939 423.644C80.0939 421.498 78.4821 419.758 76.4939 419.758C74.5056 419.758 72.8938 421.498 72.8938 423.644C72.8938 425.791 74.5056 427.531 76.4939 427.531Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 436.274C46.0903 436.274 47.7021 434.534 47.7021 432.387C47.7021 430.241 46.0903 428.5 44.102 428.5C42.1138 428.5 40.502 430.241 40.502 432.387C40.502 434.534 42.1138 436.274 44.102 436.274Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 436.274C54.1883 436.274 55.8001 434.534 55.8001 432.387C55.8001 430.241 54.1883 428.5 52.2 428.5C50.2118 428.5 48.6 430.241 48.6 432.387C48.6 434.534 50.2118 436.274 52.2 436.274Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 436.274C62.2862 436.274 63.898 434.534 63.898 432.387C63.898 430.241 62.2862 428.5 60.2979 428.5C58.3097 428.5 56.6979 430.241 56.6979 432.387C56.6979 434.534 58.3097 436.274 60.2979 436.274Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 436.274C70.3842 436.274 71.996 434.534 71.996 432.387C71.996 430.241 70.3842 428.5 68.3959 428.5C66.4076 428.5 64.7958 430.241 64.7958 432.387C64.7958 434.534 66.4076 436.274 68.3959 436.274Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 436.274C78.4821 436.274 80.0939 434.534 80.0939 432.387C80.0939 430.241 78.4821 428.5 76.4939 428.5C74.5056 428.5 72.8938 430.241 72.8938 432.387C72.8938 434.534 74.5056 436.274 76.4939 436.274Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 445.017C46.0903 445.017 47.7021 443.276 47.7021 441.13C47.7021 438.983 46.0903 437.243 44.102 437.243C42.1138 437.243 40.502 438.983 40.502 441.13C40.502 443.276 42.1138 445.017 44.102 445.017Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 445.017C54.1883 445.017 55.8001 443.276 55.8001 441.13C55.8001 438.983 54.1883 437.243 52.2 437.243C50.2118 437.243 48.6 438.983 48.6 441.13C48.6 443.276 50.2118 445.017 52.2 445.017Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 445.017C62.2862 445.017 63.898 443.276 63.898 441.13C63.898 438.983 62.2862 437.243 60.2979 437.243C58.3097 437.243 56.6979 438.983 56.6979 441.13C56.6979 443.276 58.3097 445.017 60.2979 445.017Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 445.017C70.3842 445.017 71.996 443.276 71.996 441.13C71.996 438.983 70.3842 437.243 68.3959 437.243C66.4076 437.243 64.7958 438.983 64.7958 441.13C64.7958 443.276 66.4076 445.017 68.3959 445.017Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 445.017C78.4821 445.017 80.0939 443.276 80.0939 441.13C80.0939 438.983 78.4821 437.243 76.4939 437.243C74.5056 437.243 72.8938 438.983 72.8938 441.13C72.8938 443.276 74.5056 445.017 76.4939 445.017Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 453.773C46.0903 453.773 47.7021 452.032 47.7021 449.886C47.7021 447.739 46.0903 445.999 44.102 445.999C42.1138 445.999 40.502 447.739 40.502 449.886C40.502 452.032 42.1138 453.773 44.102 453.773Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 453.773C54.1883 453.773 55.8001 452.032 55.8001 449.886C55.8001 447.739 54.1883 445.999 52.2 445.999C50.2118 445.999 48.6 447.739 48.6 449.886C48.6 452.032 50.2118 453.773 52.2 453.773Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 453.773C62.2862 453.773 63.898 452.032 63.898 449.886C63.898 447.739 62.2862 445.999 60.2979 445.999C58.3097 445.999 56.6979 447.739 56.6979 449.886C56.6979 452.032 58.3097 453.773 60.2979 453.773Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 453.773C70.3842 453.773 71.996 452.032 71.996 449.886C71.996 447.739 70.3842 445.999 68.3959 445.999C66.4076 445.999 64.7958 447.739 64.7958 449.886C64.7958 452.032 66.4076 453.773 68.3959 453.773Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 453.773C78.4821 453.773 80.0939 452.032 80.0939 449.886C80.0939 447.739 78.4821 445.999 76.4939 445.999C74.5056 445.999 72.8938 447.739 72.8938 449.886C72.8938 452.032 74.5056 453.773 76.4939 453.773Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 462.515C46.0903 462.515 47.7021 460.775 47.7021 458.629C47.7021 456.482 46.0903 454.742 44.102 454.742C42.1138 454.742 40.502 456.482 40.502 458.629C40.502 460.775 42.1138 462.515 44.102 462.515Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 462.515C54.1883 462.515 55.8001 460.775 55.8001 458.629C55.8001 456.482 54.1883 454.742 52.2 454.742C50.2118 454.742 48.6 456.482 48.6 458.629C48.6 460.775 50.2118 462.515 52.2 462.515Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 462.515C62.2862 462.515 63.898 460.775 63.898 458.629C63.898 456.482 62.2862 454.742 60.2979 454.742C58.3097 454.742 56.6979 456.482 56.6979 458.629C56.6979 460.775 58.3097 462.515 60.2979 462.515Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 462.515C70.3842 462.515 71.996 460.775 71.996 458.629C71.996 456.482 70.3842 454.742 68.3959 454.742C66.4076 454.742 64.7958 456.482 64.7958 458.629C64.7958 460.775 66.4076 462.515 68.3959 462.515Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 471.258C46.0903 471.258 47.7021 469.518 47.7021 467.371C47.7021 465.225 46.0903 463.485 44.102 463.485C42.1138 463.485 40.502 465.225 40.502 467.371C40.502 469.518 42.1138 471.258 44.102 471.258Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 471.258C54.1883 471.258 55.8001 469.518 55.8001 467.371C55.8001 465.225 54.1883 463.485 52.2 463.485C50.2118 463.485 48.6 465.225 48.6 467.371C48.6 469.518 50.2118 471.258 52.2 471.258Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 471.258C62.2862 471.258 63.898 469.518 63.898 467.371C63.898 465.225 62.2862 463.485 60.2979 463.485C58.3097 463.485 56.6979 465.225 56.6979 467.371C56.6979 469.518 58.3097 471.258 60.2979 471.258Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 471.258C70.3842 471.258 71.996 469.518 71.996 467.371C71.996 465.225 70.3842 463.485 68.3959 463.485C66.4076 463.485 64.7958 465.225 64.7958 467.371C64.7958 469.518 66.4076 471.258 68.3959 471.258Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 480.001C46.0903 480.001 47.7021 478.261 47.7021 476.114C47.7021 473.967 46.0903 472.227 44.102 472.227C42.1138 472.227 40.502 473.967 40.502 476.114C40.502 478.261 42.1138 480.001 44.102 480.001Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 480.001C54.1883 480.001 55.8001 478.261 55.8001 476.114C55.8001 473.967 54.1883 472.227 52.2 472.227C50.2118 472.227 48.6 473.967 48.6 476.114C48.6 478.261 50.2118 480.001 52.2 480.001Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 480.001C62.2862 480.001 63.898 478.261 63.898 476.114C63.898 473.967 62.2862 472.227 60.2979 472.227C58.3097 472.227 56.6979 473.967 56.6979 476.114C56.6979 478.261 58.3097 480.001 60.2979 480.001Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 480.001C70.3842 480.001 71.996 478.261 71.996 476.114C71.996 473.967 70.3842 472.227 68.3959 472.227C66.4076 472.227 64.7958 473.967 64.7958 476.114C64.7958 478.261 66.4076 480.001 68.3959 480.001Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 488.743C37.9923 488.743 39.6041 487.003 39.6041 484.857C39.6041 482.71 37.9923 480.97 36.0041 480.97C34.0158 480.97 32.404 482.71 32.404 484.857C32.404 487.003 34.0158 488.743 36.0041 488.743Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 488.743C46.0903 488.743 47.7021 487.003 47.7021 484.857C47.7021 482.71 46.0903 480.97 44.102 480.97C42.1138 480.97 40.502 482.71 40.502 484.857C40.502 487.003 42.1138 488.743 44.102 488.743Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 488.743C54.1883 488.743 55.8001 487.003 55.8001 484.857C55.8001 482.71 54.1883 480.97 52.2 480.97C50.2118 480.97 48.6 482.71 48.6 484.857C48.6 487.003 50.2118 488.743 52.2 488.743Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 488.743C62.2862 488.743 63.898 487.003 63.898 484.857C63.898 482.71 62.2862 480.97 60.2979 480.97C58.3097 480.97 56.6979 482.71 56.6979 484.857C56.6979 487.003 58.3097 488.743 60.2979 488.743Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 497.5C37.9923 497.5 39.6041 495.759 39.6041 493.613C39.6041 491.466 37.9923 489.726 36.0041 489.726C34.0158 489.726 32.404 491.466 32.404 493.613C32.404 495.759 34.0158 497.5 36.0041 497.5Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 497.5C46.0903 497.5 47.7021 495.759 47.7021 493.613C47.7021 491.466 46.0903 489.726 44.102 489.726C42.1138 489.726 40.502 491.466 40.502 493.613C40.502 495.759 42.1138 497.5 44.102 497.5Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 497.5C54.1883 497.5 55.8001 495.759 55.8001 493.613C55.8001 491.466 54.1883 489.726 52.2 489.726C50.2118 489.726 48.6 491.466 48.6 493.613C48.6 495.759 50.2118 497.5 52.2 497.5Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 497.5C62.2862 497.5 63.898 495.759 63.898 493.613C63.898 491.466 62.2862 489.726 60.2979 489.726C58.3097 489.726 56.6979 491.466 56.6979 493.613C56.6979 495.759 58.3097 497.5 60.2979 497.5Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 506.242C37.9923 506.242 39.6041 504.502 39.6041 502.356C39.6041 500.209 37.9923 498.469 36.0041 498.469C34.0158 498.469 32.404 500.209 32.404 502.356C32.404 504.502 34.0158 506.242 36.0041 506.242Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 506.242C46.0903 506.242 47.7021 504.502 47.7021 502.356C47.7021 500.209 46.0903 498.469 44.102 498.469C42.1138 498.469 40.502 500.209 40.502 502.356C40.502 504.502 42.1138 506.242 44.102 506.242Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 506.242C54.1883 506.242 55.8001 504.502 55.8001 502.356C55.8001 500.209 54.1883 498.469 52.2 498.469C50.2118 498.469 48.6 500.209 48.6 502.356C48.6 504.502 50.2118 506.242 52.2 506.242Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 506.242C62.2862 506.242 63.898 504.502 63.898 502.356C63.898 500.209 62.2862 498.469 60.2979 498.469C58.3097 498.469 56.6979 500.209 56.6979 502.356C56.6979 504.502 58.3097 506.242 60.2979 506.242Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 514.985C37.9923 514.985 39.6041 513.245 39.6041 511.098C39.6041 508.952 37.9923 507.212 36.0041 507.212C34.0158 507.212 32.404 508.952 32.404 511.098C32.404 513.245 34.0158 514.985 36.0041 514.985Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 514.985C46.0903 514.985 47.7021 513.245 47.7021 511.098C47.7021 508.952 46.0903 507.212 44.102 507.212C42.1138 507.212 40.502 508.952 40.502 511.098C40.502 513.245 42.1138 514.985 44.102 514.985Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 514.985C54.1883 514.985 55.8001 513.245 55.8001 511.098C55.8001 508.952 54.1883 507.212 52.2 507.212C50.2118 507.212 48.6 508.952 48.6 511.098C48.6 513.245 50.2118 514.985 52.2 514.985Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 514.985C62.2862 514.985 63.898 513.245 63.898 511.098C63.898 508.952 62.2862 507.212 60.2979 507.212C58.3097 507.212 56.6979 508.952 56.6979 511.098C56.6979 513.245 58.3097 514.985 60.2979 514.985Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 523.728C37.9923 523.728 39.6041 521.988 39.6041 519.841C39.6041 517.695 37.9923 515.954 36.0041 515.954C34.0158 515.954 32.404 517.695 32.404 519.841C32.404 521.988 34.0158 523.728 36.0041 523.728Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 523.728C46.0903 523.728 47.7021 521.988 47.7021 519.841C47.7021 517.695 46.0903 515.954 44.102 515.954C42.1138 515.954 40.502 517.695 40.502 519.841C40.502 521.988 42.1138 523.728 44.102 523.728Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 523.728C54.1883 523.728 55.8001 521.988 55.8001 519.841C55.8001 517.695 54.1883 515.954 52.2 515.954C50.2118 515.954 48.6 517.695 48.6 519.841C48.6 521.988 50.2118 523.728 52.2 523.728Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 523.728C62.2862 523.728 63.898 521.988 63.898 519.841C63.898 517.695 62.2862 515.954 60.2979 515.954C58.3097 515.954 56.6979 517.695 56.6979 519.841C56.6979 521.988 58.3097 523.728 60.2979 523.728Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 532.471C37.9923 532.471 39.6041 530.73 39.6041 528.584C39.6041 526.437 37.9923 524.697 36.0041 524.697C34.0158 524.697 32.404 526.437 32.404 528.584C32.404 530.73 34.0158 532.471 36.0041 532.471Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 532.471C54.1883 532.471 55.8001 530.73 55.8001 528.584C55.8001 526.437 54.1883 524.697 52.2 524.697C50.2118 524.697 48.6 526.437 48.6 528.584C48.6 530.73 50.2118 532.471 52.2 532.471Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 532.471C62.2862 532.471 63.898 530.73 63.898 528.584C63.898 526.437 62.2862 524.697 60.2979 524.697C58.3097 524.697 56.6979 526.437 56.6979 528.584C56.6979 530.73 58.3097 532.471 60.2979 532.471Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 532.471C70.3842 532.471 71.996 530.73 71.996 528.584C71.996 526.437 70.3842 524.697 68.3959 524.697C66.4076 524.697 64.7958 526.437 64.7958 528.584C64.7958 530.73 66.4076 532.471 68.3959 532.471Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M27.8939 541.213C29.8822 541.213 31.494 539.473 31.494 537.327C31.494 535.18 29.8822 533.44 27.8939 533.44C25.9057 533.44 24.2939 535.18 24.2939 537.327C24.2939 539.473 25.9057 541.213 27.8939 541.213Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 541.213C37.9923 541.213 39.6041 539.473 39.6041 537.327C39.6041 535.18 37.9923 533.44 36.0041 533.44C34.0158 533.44 32.404 535.18 32.404 537.327C32.404 539.473 34.0158 541.213 36.0041 541.213Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 541.213C54.1883 541.213 55.8001 539.473 55.8001 537.327C55.8001 535.18 54.1883 533.44 52.2 533.44C50.2118 533.44 48.6 535.18 48.6 537.327C48.6 539.473 50.2118 541.213 52.2 541.213Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 541.213C62.2862 541.213 63.898 539.473 63.898 537.327C63.898 535.18 62.2862 533.44 60.2979 533.44C58.3097 533.44 56.6979 535.18 56.6979 537.327C56.6979 539.473 58.3097 541.213 60.2979 541.213Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M27.8939 549.969C29.8822 549.969 31.494 548.229 31.494 546.083C31.494 543.936 29.8822 542.196 27.8939 542.196C25.9057 542.196 24.2939 543.936 24.2939 546.083C24.2939 548.229 25.9057 549.969 27.8939 549.969Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 549.969C54.1883 549.969 55.8001 548.229 55.8001 546.083C55.8001 543.936 54.1883 542.196 52.2 542.196C50.2118 542.196 48.6 543.936 48.6 546.083C48.6 548.229 50.2118 549.969 52.2 549.969Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 549.969C62.2862 549.969 63.898 548.229 63.898 546.083C63.898 543.936 62.2862 542.196 60.2979 542.196C58.3097 542.196 56.6979 543.936 56.6979 546.083C56.6979 548.229 58.3097 549.969 60.2979 549.969Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M27.8939 558.712C29.8822 558.712 31.494 556.972 31.494 554.825C31.494 552.679 29.8822 550.939 27.8939 550.939C25.9057 550.939 24.2939 552.679 24.2939 554.825C24.2939 556.972 25.9057 558.712 27.8939 558.712Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 558.712C37.9923 558.712 39.6041 556.972 39.6041 554.825C39.6041 552.679 37.9923 550.939 36.0041 550.939C34.0158 550.939 32.404 552.679 32.404 554.825C32.404 556.972 34.0158 558.712 36.0041 558.712Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 558.712C54.1883 558.712 55.8001 556.972 55.8001 554.825C55.8001 552.679 54.1883 550.939 52.2 550.939C50.2118 550.939 48.6 552.679 48.6 554.825C48.6 556.972 50.2118 558.712 52.2 558.712Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 558.712C62.2862 558.712 63.898 556.972 63.898 554.825C63.898 552.679 62.2862 550.939 60.2979 550.939C58.3097 550.939 56.6979 552.679 56.6979 554.825C56.6979 556.972 58.3097 558.712 60.2979 558.712Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M27.8939 567.455C29.8822 567.455 31.494 565.715 31.494 563.568C31.494 561.422 29.8822 559.681 27.8939 559.681C25.9057 559.681 24.2939 561.422 24.2939 563.568C24.2939 565.715 25.9057 567.455 27.8939 567.455Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 567.455C54.1883 567.455 55.8001 565.715 55.8001 563.568C55.8001 561.422 54.1883 559.681 52.2 559.681C50.2118 559.681 48.6 561.422 48.6 563.568C48.6 565.715 50.2118 567.455 52.2 567.455Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 567.455C62.2862 567.455 63.898 565.715 63.898 563.568C63.898 561.422 62.2862 559.681 60.2979 559.681C58.3097 559.681 56.6979 561.422 56.6979 563.568C56.6979 565.715 58.3097 567.455 60.2979 567.455Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 567.455C70.3842 567.455 71.996 565.715 71.996 563.568C71.996 561.422 70.3842 559.681 68.3959 559.681C66.4076 559.681 64.7958 561.422 64.7958 563.568C64.7958 565.715 66.4076 567.455 68.3959 567.455Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 576.198C54.1883 576.198 55.8001 574.457 55.8001 572.311C55.8001 570.164 54.1883 568.424 52.2 568.424C50.2118 568.424 48.6 570.164 48.6 572.311C48.6 574.457 50.2118 576.198 52.2 576.198Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 576.198C62.2862 576.198 63.898 574.457 63.898 572.311C63.898 570.164 62.2862 568.424 60.2979 568.424C58.3097 568.424 56.6979 570.164 56.6979 572.311C56.6979 574.457 58.3097 576.198 60.2979 576.198Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 576.198C70.3842 576.198 71.996 574.457 71.996 572.311C71.996 570.164 70.3842 568.424 68.3959 568.424C66.4076 568.424 64.7958 570.164 64.7958 572.311C64.7958 574.457 66.4076 576.198 68.3959 576.198Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 584.94C37.9923 584.94 39.6041 583.2 39.6041 581.054C39.6041 578.907 37.9923 577.167 36.0041 577.167C34.0158 577.167 32.404 578.907 32.404 581.054C32.404 583.2 34.0158 584.94 36.0041 584.94Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 584.94C46.0903 584.94 47.7021 583.2 47.7021 581.054C47.7021 578.907 46.0903 577.167 44.102 577.167C42.1138 577.167 40.502 578.907 40.502 581.054C40.502 583.2 42.1138 584.94 44.102 584.94Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 584.94C54.1883 584.94 55.8001 583.2 55.8001 581.054C55.8001 578.907 54.1883 577.167 52.2 577.167C50.2118 577.167 48.6 578.907 48.6 581.054C48.6 583.2 50.2118 584.94 52.2 584.94Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 584.94C62.2862 584.94 63.898 583.2 63.898 581.054C63.898 578.907 62.2862 577.167 60.2979 577.167C58.3097 577.167 56.6979 578.907 56.6979 581.054C56.6979 583.2 58.3097 584.94 60.2979 584.94Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 584.94C70.3842 584.94 71.996 583.2 71.996 581.054C71.996 578.907 70.3842 577.167 68.3959 577.167C66.4076 577.167 64.7958 578.907 64.7958 581.054C64.7958 583.2 66.4076 584.94 68.3959 584.94Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M27.8939 593.696C29.8822 593.696 31.494 591.956 31.494 589.81C31.494 587.663 29.8822 585.923 27.8939 585.923C25.9057 585.923 24.2939 587.663 24.2939 589.81C24.2939 591.956 25.9057 593.696 27.8939 593.696Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 593.696C54.1883 593.696 55.8001 591.956 55.8001 589.81C55.8001 587.663 54.1883 585.923 52.2 585.923C50.2118 585.923 48.6 587.663 48.6 589.81C48.6 591.956 50.2118 593.696 52.2 593.696Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 593.696C62.2862 593.696 63.898 591.956 63.898 589.81C63.898 587.663 62.2862 585.923 60.2979 585.923C58.3097 585.923 56.6979 587.663 56.6979 589.81C56.6979 591.956 58.3097 593.696 60.2979 593.696Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 593.696C70.3842 593.696 71.996 591.956 71.996 589.81C71.996 587.663 70.3842 585.923 68.3959 585.923C66.4076 585.923 64.7958 587.663 64.7958 589.81C64.7958 591.956 66.4076 593.696 68.3959 593.696Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M19.7959 602.439C21.7842 602.439 23.396 600.699 23.396 598.552C23.396 596.406 21.7842 594.666 19.7959 594.666C17.8077 594.666 16.1959 596.406 16.1959 598.552C16.1959 600.699 17.8077 602.439 19.7959 602.439Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M27.8939 602.439C29.8822 602.439 31.494 600.699 31.494 598.552C31.494 596.406 29.8822 594.666 27.8939 594.666C25.9057 594.666 24.2939 596.406 24.2939 598.552C24.2939 600.699 25.9057 602.439 27.8939 602.439Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 602.439C37.9923 602.439 39.6041 600.699 39.6041 598.552C39.6041 596.406 37.9923 594.666 36.0041 594.666C34.0158 594.666 32.404 596.406 32.404 598.552C32.404 600.699 34.0158 602.439 36.0041 602.439Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 602.439C46.0903 602.439 47.7021 600.699 47.7021 598.552C47.7021 596.406 46.0903 594.666 44.102 594.666C42.1138 594.666 40.502 596.406 40.502 598.552C40.502 600.699 42.1138 602.439 44.102 602.439Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 602.439C54.1883 602.439 55.8001 600.699 55.8001 598.552C55.8001 596.406 54.1883 594.666 52.2 594.666C50.2118 594.666 48.6 596.406 48.6 598.552C48.6 600.699 50.2118 602.439 52.2 602.439Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 602.439C62.2862 602.439 63.898 600.699 63.898 598.552C63.898 596.406 62.2862 594.666 60.2979 594.666C58.3097 594.666 56.6979 596.406 56.6979 598.552C56.6979 600.699 58.3097 602.439 60.2979 602.439Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 602.439C70.3842 602.439 71.996 600.699 71.996 598.552C71.996 596.406 70.3842 594.666 68.3959 594.666C66.4076 594.666 64.7958 596.406 64.7958 598.552C64.7958 600.699 66.4076 602.439 68.3959 602.439Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 602.439C78.4821 602.439 80.0939 600.699 80.0939 598.552C80.0939 596.406 78.4821 594.666 76.4939 594.666C74.5056 594.666 72.8938 596.406 72.8938 598.552C72.8938 600.699 74.5056 602.439 76.4939 602.439Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M27.8939 611.182C29.8822 611.182 31.494 609.442 31.494 607.295C31.494 605.148 29.8822 603.408 27.8939 603.408C25.9057 603.408 24.2939 605.148 24.2939 607.295C24.2939 609.442 25.9057 611.182 27.8939 611.182Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 611.182C46.0903 611.182 47.7021 609.442 47.7021 607.295C47.7021 605.148 46.0903 603.408 44.102 603.408C42.1138 603.408 40.502 605.148 40.502 607.295C40.502 609.442 42.1138 611.182 44.102 611.182Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 611.182C54.1883 611.182 55.8001 609.442 55.8001 607.295C55.8001 605.148 54.1883 603.408 52.2 603.408C50.2118 603.408 48.6 605.148 48.6 607.295C48.6 609.442 50.2118 611.182 52.2 611.182Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 611.182C62.2862 611.182 63.898 609.442 63.898 607.295C63.898 605.148 62.2862 603.408 60.2979 603.408C58.3097 603.408 56.6979 605.148 56.6979 607.295C56.6979 609.442 58.3097 611.182 60.2979 611.182Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 611.182C70.3842 611.182 71.996 609.442 71.996 607.295C71.996 605.148 70.3842 603.408 68.3959 603.408C66.4076 603.408 64.7958 605.148 64.7958 607.295C64.7958 609.442 66.4076 611.182 68.3959 611.182Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M27.8939 619.924C29.8822 619.924 31.494 618.184 31.494 616.038C31.494 613.891 29.8822 612.151 27.8939 612.151C25.9057 612.151 24.2939 613.891 24.2939 616.038C24.2939 618.184 25.9057 619.924 27.8939 619.924Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 619.924C46.0903 619.924 47.7021 618.184 47.7021 616.038C47.7021 613.891 46.0903 612.151 44.102 612.151C42.1138 612.151 40.502 613.891 40.502 616.038C40.502 618.184 42.1138 619.924 44.102 619.924Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 619.924C54.1883 619.924 55.8001 618.184 55.8001 616.038C55.8001 613.891 54.1883 612.151 52.2 612.151C50.2118 612.151 48.6 613.891 48.6 616.038C48.6 618.184 50.2118 619.924 52.2 619.924Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 619.924C62.2862 619.924 63.898 618.184 63.898 616.038C63.898 613.891 62.2862 612.151 60.2979 612.151C58.3097 612.151 56.6979 613.891 56.6979 616.038C56.6979 618.184 58.3097 619.924 60.2979 619.924Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 619.924C70.3842 619.924 71.996 618.184 71.996 616.038C71.996 613.891 70.3842 612.151 68.3959 612.151C66.4076 612.151 64.7958 613.891 64.7958 616.038C64.7958 618.184 66.4076 619.924 68.3959 619.924Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 619.924C78.4821 619.924 80.0939 618.184 80.0939 616.038C80.0939 613.891 78.4821 612.151 76.4939 612.151C74.5056 612.151 72.8938 613.891 72.8938 616.038C72.8938 618.184 74.5056 619.924 76.4939 619.924Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M19.7959 628.667C21.7842 628.667 23.396 626.927 23.396 624.78C23.396 622.634 21.7842 620.894 19.7959 620.894C17.8077 620.894 16.1959 622.634 16.1959 624.78C16.1959 626.927 17.8077 628.667 19.7959 628.667Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M27.8939 628.667C29.8822 628.667 31.494 626.927 31.494 624.78C31.494 622.634 29.8822 620.894 27.8939 620.894C25.9057 620.894 24.2939 622.634 24.2939 624.78C24.2939 626.927 25.9057 628.667 27.8939 628.667Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 628.667C37.9923 628.667 39.6041 626.927 39.6041 624.78C39.6041 622.634 37.9923 620.894 36.0041 620.894C34.0158 620.894 32.404 622.634 32.404 624.78C32.404 626.927 34.0158 628.667 36.0041 628.667Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 628.667C46.0903 628.667 47.7021 626.927 47.7021 624.78C47.7021 622.634 46.0903 620.894 44.102 620.894C42.1138 620.894 40.502 622.634 40.502 624.78C40.502 626.927 42.1138 628.667 44.102 628.667Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 628.667C54.1883 628.667 55.8001 626.927 55.8001 624.78C55.8001 622.634 54.1883 620.894 52.2 620.894C50.2118 620.894 48.6 622.634 48.6 624.78C48.6 626.927 50.2118 628.667 52.2 628.667Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 628.667C62.2862 628.667 63.898 626.927 63.898 624.78C63.898 622.634 62.2862 620.894 60.2979 620.894C58.3097 620.894 56.6979 622.634 56.6979 624.78C56.6979 626.927 58.3097 628.667 60.2979 628.667Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 628.667C70.3842 628.667 71.996 626.927 71.996 624.78C71.996 622.634 70.3842 620.894 68.3959 620.894C66.4076 620.894 64.7958 622.634 64.7958 624.78C64.7958 626.927 66.4076 628.667 68.3959 628.667Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M11.698 637.423C13.6862 637.423 15.298 635.683 15.298 633.536C15.298 631.39 13.6862 629.65 11.698 629.65C9.70971 629.65 8.09791 631.39 8.09791 633.536C8.09791 635.683 9.70971 637.423 11.698 637.423Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M19.7959 637.423C21.7842 637.423 23.396 635.683 23.396 633.536C23.396 631.39 21.7842 629.65 19.7959 629.65C17.8077 629.65 16.1959 631.39 16.1959 633.536C16.1959 635.683 17.8077 637.423 19.7959 637.423Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M27.8939 637.423C29.8822 637.423 31.494 635.683 31.494 633.536C31.494 631.39 29.8822 629.65 27.8939 629.65C25.9057 629.65 24.2939 631.39 24.2939 633.536C24.2939 635.683 25.9057 637.423 27.8939 637.423Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 637.423C37.9923 637.423 39.6041 635.683 39.6041 633.536C39.6041 631.39 37.9923 629.65 36.0041 629.65C34.0158 629.65 32.404 631.39 32.404 633.536C32.404 635.683 34.0158 637.423 36.0041 637.423Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 637.423C46.0903 637.423 47.7021 635.683 47.7021 633.536C47.7021 631.39 46.0903 629.65 44.102 629.65C42.1138 629.65 40.502 631.39 40.502 633.536C40.502 635.683 42.1138 637.423 44.102 637.423Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 637.423C54.1883 637.423 55.8001 635.683 55.8001 633.536C55.8001 631.39 54.1883 629.65 52.2 629.65C50.2118 629.65 48.6 631.39 48.6 633.536C48.6 635.683 50.2118 637.423 52.2 637.423Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 637.423C62.2862 637.423 63.898 635.683 63.898 633.536C63.898 631.39 62.2862 629.65 60.2979 629.65C58.3097 629.65 56.6979 631.39 56.6979 633.536C56.6979 635.683 58.3097 637.423 60.2979 637.423Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 637.423C70.3842 637.423 71.996 635.683 71.996 633.536C71.996 631.39 70.3842 629.65 68.3959 629.65C66.4076 629.65 64.7958 631.39 64.7958 633.536C64.7958 635.683 66.4076 637.423 68.3959 637.423Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M11.698 646.166C13.6862 646.166 15.298 644.426 15.298 642.279C15.298 640.133 13.6862 638.392 11.698 638.392C9.70971 638.392 8.09791 640.133 8.09791 642.279C8.09791 644.426 9.70971 646.166 11.698 646.166Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M19.7959 646.166C21.7842 646.166 23.396 644.426 23.396 642.279C23.396 640.133 21.7842 638.392 19.7959 638.392C17.8077 638.392 16.1959 640.133 16.1959 642.279C16.1959 644.426 17.8077 646.166 19.7959 646.166Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M27.8939 646.166C29.8822 646.166 31.494 644.426 31.494 642.279C31.494 640.133 29.8822 638.392 27.8939 638.392C25.9057 638.392 24.2939 640.133 24.2939 642.279C24.2939 644.426 25.9057 646.166 27.8939 646.166Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 646.166C37.9923 646.166 39.6041 644.426 39.6041 642.279C39.6041 640.133 37.9923 638.392 36.0041 638.392C34.0158 638.392 32.404 640.133 32.404 642.279C32.404 644.426 34.0158 646.166 36.0041 646.166Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 646.166C46.0903 646.166 47.7021 644.426 47.7021 642.279C47.7021 640.133 46.0903 638.392 44.102 638.392C42.1138 638.392 40.502 640.133 40.502 642.279C40.502 644.426 42.1138 646.166 44.102 646.166Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 646.166C54.1883 646.166 55.8001 644.426 55.8001 642.279C55.8001 640.133 54.1883 638.392 52.2 638.392C50.2118 638.392 48.6 640.133 48.6 642.279C48.6 644.426 50.2118 646.166 52.2 646.166Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 646.166C62.2862 646.166 63.898 644.426 63.898 642.279C63.898 640.133 62.2862 638.392 60.2979 638.392C58.3097 638.392 56.6979 640.133 56.6979 642.279C56.6979 644.426 58.3097 646.166 60.2979 646.166Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 646.166C70.3842 646.166 71.996 644.426 71.996 642.279C71.996 640.133 70.3842 638.392 68.3959 638.392C66.4076 638.392 64.7958 640.133 64.7958 642.279C64.7958 644.426 66.4076 646.166 68.3959 646.166Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M3.60006 654.909C5.58831 654.909 7.20012 653.169 7.20012 651.022C7.20012 648.875 5.58831 647.135 3.60006 647.135C1.6118 647.135 0 648.875 0 651.022C0 653.169 1.6118 654.909 3.60006 654.909Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M11.698 654.909C13.6862 654.909 15.298 653.169 15.298 651.022C15.298 648.875 13.6862 647.135 11.698 647.135C9.70971 647.135 8.09791 648.875 8.09791 651.022C8.09791 653.169 9.70971 654.909 11.698 654.909Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M19.7959 654.909C21.7842 654.909 23.396 653.169 23.396 651.022C23.396 648.875 21.7842 647.135 19.7959 647.135C17.8077 647.135 16.1959 648.875 16.1959 651.022C16.1959 653.169 17.8077 654.909 19.7959 654.909Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M27.8939 654.909C29.8822 654.909 31.494 653.169 31.494 651.022C31.494 648.875 29.8822 647.135 27.8939 647.135C25.9057 647.135 24.2939 648.875 24.2939 651.022C24.2939 653.169 25.9057 654.909 27.8939 654.909Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 654.909C37.9923 654.909 39.6041 653.169 39.6041 651.022C39.6041 648.875 37.9923 647.135 36.0041 647.135C34.0158 647.135 32.404 648.875 32.404 651.022C32.404 653.169 34.0158 654.909 36.0041 654.909Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 654.909C46.0903 654.909 47.7021 653.169 47.7021 651.022C47.7021 648.875 46.0903 647.135 44.102 647.135C42.1138 647.135 40.502 648.875 40.502 651.022C40.502 653.169 42.1138 654.909 44.102 654.909Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 654.909C54.1883 654.909 55.8001 653.169 55.8001 651.022C55.8001 648.875 54.1883 647.135 52.2 647.135C50.2118 647.135 48.6 648.875 48.6 651.022C48.6 653.169 50.2118 654.909 52.2 654.909Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 654.909C62.2862 654.909 63.898 653.169 63.898 651.022C63.898 648.875 62.2862 647.135 60.2979 647.135C58.3097 647.135 56.6979 648.875 56.6979 651.022C56.6979 653.169 58.3097 654.909 60.2979 654.909Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 654.909C70.3842 654.909 71.996 653.169 71.996 651.022C71.996 648.875 70.3842 647.135 68.3959 647.135C66.4076 647.135 64.7958 648.875 64.7958 651.022C64.7958 653.169 66.4076 654.909 68.3959 654.909Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M3.60006 663.651C5.58831 663.651 7.20012 661.911 7.20012 659.765C7.20012 657.618 5.58831 655.878 3.60006 655.878C1.6118 655.878 0 657.618 0 659.765C0 661.911 1.6118 663.651 3.60006 663.651Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M27.8939 663.651C29.8822 663.651 31.494 661.911 31.494 659.765C31.494 657.618 29.8822 655.878 27.8939 655.878C25.9057 655.878 24.2939 657.618 24.2939 659.765C24.2939 661.911 25.9057 663.651 27.8939 663.651Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 663.651C37.9923 663.651 39.6041 661.911 39.6041 659.765C39.6041 657.618 37.9923 655.878 36.0041 655.878C34.0158 655.878 32.404 657.618 32.404 659.765C32.404 661.911 34.0158 663.651 36.0041 663.651Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 663.651C46.0903 663.651 47.7021 661.911 47.7021 659.765C47.7021 657.618 46.0903 655.878 44.102 655.878C42.1138 655.878 40.502 657.618 40.502 659.765C40.502 661.911 42.1138 663.651 44.102 663.651Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 663.651C54.1883 663.651 55.8001 661.911 55.8001 659.765C55.8001 657.618 54.1883 655.878 52.2 655.878C50.2118 655.878 48.6 657.618 48.6 659.765C48.6 661.911 50.2118 663.651 52.2 663.651Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 663.651C62.2862 663.651 63.898 661.911 63.898 659.765C63.898 657.618 62.2862 655.878 60.2979 655.878C58.3097 655.878 56.6979 657.618 56.6979 659.765C56.6979 661.911 58.3097 663.651 60.2979 663.651Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M27.8939 672.394C29.8822 672.394 31.494 670.654 31.494 668.507C31.494 666.361 29.8822 664.621 27.8939 664.621C25.9057 664.621 24.2939 666.361 24.2939 668.507C24.2939 670.654 25.9057 672.394 27.8939 672.394Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 672.394C37.9923 672.394 39.6041 670.654 39.6041 668.507C39.6041 666.361 37.9923 664.621 36.0041 664.621C34.0158 664.621 32.404 666.361 32.404 668.507C32.404 670.654 34.0158 672.394 36.0041 672.394Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 672.394C46.0903 672.394 47.7021 670.654 47.7021 668.507C47.7021 666.361 46.0903 664.621 44.102 664.621C42.1138 664.621 40.502 666.361 40.502 668.507C40.502 670.654 42.1138 672.394 44.102 672.394Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 672.394C54.1883 672.394 55.8001 670.654 55.8001 668.507C55.8001 666.361 54.1883 664.621 52.2 664.621C50.2118 664.621 48.6 666.361 48.6 668.507C48.6 670.654 50.2118 672.394 52.2 672.394Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 672.394C62.2862 672.394 63.898 670.654 63.898 668.507C63.898 666.361 62.2862 664.621 60.2979 664.621C58.3097 664.621 56.6979 666.361 56.6979 668.507C56.6979 670.654 58.3097 672.394 60.2979 672.394Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M19.7959 681.137C21.7842 681.137 23.396 679.397 23.396 677.25C23.396 675.104 21.7842 673.363 19.7959 673.363C17.8077 673.363 16.1959 675.104 16.1959 677.25C16.1959 679.397 17.8077 681.137 19.7959 681.137Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M27.8939 681.137C29.8822 681.137 31.494 679.397 31.494 677.25C31.494 675.104 29.8822 673.363 27.8939 673.363C25.9057 673.363 24.2939 675.104 24.2939 677.25C24.2939 679.397 25.9057 681.137 27.8939 681.137Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 681.137C37.9923 681.137 39.6041 679.397 39.6041 677.25C39.6041 675.104 37.9923 673.363 36.0041 673.363C34.0158 673.363 32.404 675.104 32.404 677.25C32.404 679.397 34.0158 681.137 36.0041 681.137Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 681.137C46.0903 681.137 47.7021 679.397 47.7021 677.25C47.7021 675.104 46.0903 673.363 44.102 673.363C42.1138 673.363 40.502 675.104 40.502 677.25C40.502 679.397 42.1138 681.137 44.102 681.137Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 681.137C54.1883 681.137 55.8001 679.397 55.8001 677.25C55.8001 675.104 54.1883 673.363 52.2 673.363C50.2118 673.363 48.6 675.104 48.6 677.25C48.6 679.397 50.2118 681.137 52.2 681.137Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M27.8939 689.893C29.8822 689.893 31.494 688.153 31.494 686.006C31.494 683.86 29.8822 682.119 27.8939 682.119C25.9057 682.119 24.2939 683.86 24.2939 686.006C24.2939 688.153 25.9057 689.893 27.8939 689.893Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 689.893C46.0903 689.893 47.7021 688.153 47.7021 686.006C47.7021 683.86 46.0903 682.119 44.102 682.119C42.1138 682.119 40.502 683.86 40.502 686.006C40.502 688.153 42.1138 689.893 44.102 689.893Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 689.893C54.1883 689.893 55.8001 688.153 55.8001 686.006C55.8001 683.86 54.1883 682.119 52.2 682.119C50.2118 682.119 48.6 683.86 48.6 686.006C48.6 688.153 50.2118 689.893 52.2 689.893Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M3.60006 698.636C5.58831 698.636 7.20012 696.896 7.20012 694.749C7.20012 692.602 5.58831 690.862 3.60006 690.862C1.6118 690.862 0 692.602 0 694.749C0 696.896 1.6118 698.636 3.60006 698.636Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M11.698 698.636C13.6862 698.636 15.298 696.896 15.298 694.749C15.298 692.602 13.6862 690.862 11.698 690.862C9.70971 690.862 8.09791 692.602 8.09791 694.749C8.09791 696.896 9.70971 698.636 11.698 698.636Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M19.7959 698.636C21.7842 698.636 23.396 696.896 23.396 694.749C23.396 692.602 21.7842 690.862 19.7959 690.862C17.8077 690.862 16.1959 692.602 16.1959 694.749C16.1959 696.896 17.8077 698.636 19.7959 698.636Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M27.8939 698.636C29.8822 698.636 31.494 696.896 31.494 694.749C31.494 692.602 29.8822 690.862 27.8939 690.862C25.9057 690.862 24.2939 692.602 24.2939 694.749C24.2939 696.896 25.9057 698.636 27.8939 698.636Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 698.636C37.9923 698.636 39.6041 696.896 39.6041 694.749C39.6041 692.602 37.9923 690.862 36.0041 690.862C34.0158 690.862 32.404 692.602 32.404 694.749C32.404 696.896 34.0158 698.636 36.0041 698.636Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 698.636C46.0903 698.636 47.7021 696.896 47.7021 694.749C47.7021 692.602 46.0903 690.862 44.102 690.862C42.1138 690.862 40.502 692.602 40.502 694.749C40.502 696.896 42.1138 698.636 44.102 698.636Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 698.636C54.1883 698.636 55.8001 696.896 55.8001 694.749C55.8001 692.602 54.1883 690.862 52.2 690.862C50.2118 690.862 48.6 692.602 48.6 694.749C48.6 696.896 50.2118 698.636 52.2 698.636Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M3.60006 707.378C5.58831 707.378 7.20012 705.638 7.20012 703.492C7.20012 701.345 5.58831 699.605 3.60006 699.605C1.6118 699.605 0 701.345 0 703.492C0 705.638 1.6118 707.378 3.60006 707.378Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M11.698 707.378C13.6862 707.378 15.298 705.638 15.298 703.492C15.298 701.345 13.6862 699.605 11.698 699.605C9.70971 699.605 8.09791 701.345 8.09791 703.492C8.09791 705.638 9.70971 707.378 11.698 707.378Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M27.8939 707.378C29.8822 707.378 31.494 705.638 31.494 703.492C31.494 701.345 29.8822 699.605 27.8939 699.605C25.9057 699.605 24.2939 701.345 24.2939 703.492C24.2939 705.638 25.9057 707.378 27.8939 707.378Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 707.378C37.9923 707.378 39.6041 705.638 39.6041 703.492C39.6041 701.345 37.9923 699.605 36.0041 699.605C34.0158 699.605 32.404 701.345 32.404 703.492C32.404 705.638 34.0158 707.378 36.0041 707.378Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 707.378C46.0903 707.378 47.7021 705.638 47.7021 703.492C47.7021 701.345 46.0903 699.605 44.102 699.605C42.1138 699.605 40.502 701.345 40.502 703.492C40.502 705.638 42.1138 707.378 44.102 707.378Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 707.378C54.1883 707.378 55.8001 705.638 55.8001 703.492C55.8001 701.345 54.1883 699.605 52.2 699.605C50.2118 699.605 48.6 701.345 48.6 703.492C48.6 705.638 50.2118 707.378 52.2 707.378Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M11.698 716.121C13.6862 716.121 15.298 714.381 15.298 712.234C15.298 710.088 13.6862 708.348 11.698 708.348C9.70971 708.348 8.09791 710.088 8.09791 712.234C8.09791 714.381 9.70971 716.121 11.698 716.121Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M19.7959 716.121C21.7842 716.121 23.396 714.381 23.396 712.234C23.396 710.088 21.7842 708.348 19.7959 708.348C17.8077 708.348 16.1959 710.088 16.1959 712.234C16.1959 714.381 17.8077 716.121 19.7959 716.121Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M27.8939 716.121C29.8822 716.121 31.494 714.381 31.494 712.234C31.494 710.088 29.8822 708.348 27.8939 708.348C25.9057 708.348 24.2939 710.088 24.2939 712.234C24.2939 714.381 25.9057 716.121 27.8939 716.121Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 716.121C37.9923 716.121 39.6041 714.381 39.6041 712.234C39.6041 710.088 37.9923 708.348 36.0041 708.348C34.0158 708.348 32.404 710.088 32.404 712.234C32.404 714.381 34.0158 716.121 36.0041 716.121Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 716.121C46.0903 716.121 47.7021 714.381 47.7021 712.234C47.7021 710.088 46.0903 708.348 44.102 708.348C42.1138 708.348 40.502 710.088 40.502 712.234C40.502 714.381 42.1138 716.121 44.102 716.121Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 716.121C54.1883 716.121 55.8001 714.381 55.8001 712.234C55.8001 710.088 54.1883 708.348 52.2 708.348C50.2118 708.348 48.6 710.088 48.6 712.234C48.6 714.381 50.2118 716.121 52.2 716.121Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M3.60006 724.864C5.58831 724.864 7.20012 723.124 7.20012 720.977C7.20012 718.831 5.58831 717.091 3.60006 717.091C1.6118 717.091 0 718.831 0 720.977C0 723.124 1.6118 724.864 3.60006 724.864Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M11.698 724.864C13.6862 724.864 15.298 723.124 15.298 720.977C15.298 718.831 13.6862 717.091 11.698 717.091C9.70971 717.091 8.09791 718.831 8.09791 720.977C8.09791 723.124 9.70971 724.864 11.698 724.864Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M19.7959 724.864C21.7842 724.864 23.396 723.124 23.396 720.977C23.396 718.831 21.7842 717.091 19.7959 717.091C17.8077 717.091 16.1959 718.831 16.1959 720.977C16.1959 723.124 17.8077 724.864 19.7959 724.864Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M27.8939 724.864C29.8822 724.864 31.494 723.124 31.494 720.977C31.494 718.831 29.8822 717.091 27.8939 717.091C25.9057 717.091 24.2939 718.831 24.2939 720.977C24.2939 723.124 25.9057 724.864 27.8939 724.864Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 724.864C37.9923 724.864 39.6041 723.124 39.6041 720.977C39.6041 718.831 37.9923 717.091 36.0041 717.091C34.0158 717.091 32.404 718.831 32.404 720.977C32.404 723.124 34.0158 724.864 36.0041 724.864Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 724.864C46.0903 724.864 47.7021 723.124 47.7021 720.977C47.7021 718.831 46.0903 717.091 44.102 717.091C42.1138 717.091 40.502 718.831 40.502 720.977C40.502 723.124 42.1138 724.864 44.102 724.864Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M11.698 733.607C13.6862 733.607 15.298 731.866 15.298 729.72C15.298 727.573 13.6862 725.833 11.698 725.833C9.70971 725.833 8.09791 727.573 8.09791 729.72C8.09791 731.866 9.70971 733.607 11.698 733.607Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M19.7959 733.607C21.7842 733.607 23.396 731.866 23.396 729.72C23.396 727.573 21.7842 725.833 19.7959 725.833C17.8077 725.833 16.1959 727.573 16.1959 729.72C16.1959 731.866 17.8077 733.607 19.7959 733.607Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M27.8939 733.607C29.8822 733.607 31.494 731.866 31.494 729.72C31.494 727.573 29.8822 725.833 27.8939 725.833C25.9057 725.833 24.2939 727.573 24.2939 729.72C24.2939 731.866 25.9057 733.607 27.8939 733.607Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 733.607C37.9923 733.607 39.6041 731.866 39.6041 729.72C39.6041 727.573 37.9923 725.833 36.0041 725.833C34.0158 725.833 32.404 727.573 32.404 729.72C32.404 731.866 34.0158 733.607 36.0041 733.607Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M3.60006 742.363C5.58831 742.363 7.20012 740.622 7.20012 738.476C7.20012 736.329 5.58831 734.589 3.60006 734.589C1.6118 734.589 0 736.329 0 738.476C0 740.622 1.6118 742.363 3.60006 742.363Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M11.698 742.363C13.6862 742.363 15.298 740.622 15.298 738.476C15.298 736.329 13.6862 734.589 11.698 734.589C9.70971 734.589 8.09791 736.329 8.09791 738.476C8.09791 740.622 9.70971 742.363 11.698 742.363Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M19.7959 742.363C21.7842 742.363 23.396 740.622 23.396 738.476C23.396 736.329 21.7842 734.589 19.7959 734.589C17.8077 734.589 16.1959 736.329 16.1959 738.476C16.1959 740.622 17.8077 742.363 19.7959 742.363Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M27.8939 742.363C29.8822 742.363 31.494 740.622 31.494 738.476C31.494 736.329 29.8822 734.589 27.8939 734.589C25.9057 734.589 24.2939 736.329 24.2939 738.476C24.2939 740.622 25.9057 742.363 27.8939 742.363Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 742.363C37.9923 742.363 39.6041 740.622 39.6041 738.476C39.6041 736.329 37.9923 734.589 36.0041 734.589C34.0158 734.589 32.404 736.329 32.404 738.476C32.404 740.622 34.0158 742.363 36.0041 742.363Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M19.7959 751.105C21.7842 751.105 23.396 749.365 23.396 747.219C23.396 745.072 21.7842 743.332 19.7959 743.332C17.8077 743.332 16.1959 745.072 16.1959 747.219C16.1959 749.365 17.8077 751.105 19.7959 751.105Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M27.8939 751.105C29.8822 751.105 31.494 749.365 31.494 747.219C31.494 745.072 29.8822 743.332 27.8939 743.332C25.9057 743.332 24.2939 745.072 24.2939 747.219C24.2939 749.365 25.9057 751.105 27.8939 751.105Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 751.105C37.9923 751.105 39.6041 749.365 39.6041 747.219C39.6041 745.072 37.9923 743.332 36.0041 743.332C34.0158 743.332 32.404 745.072 32.404 747.219C32.404 749.365 34.0158 751.105 36.0041 751.105Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M27.8939 759.848C29.8822 759.848 31.494 758.108 31.494 755.961C31.494 753.815 29.8822 752.075 27.8939 752.075C25.9057 752.075 24.2939 753.815 24.2939 755.961C24.2939 758.108 25.9057 759.848 27.8939 759.848Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 759.848C37.9923 759.848 39.6041 758.108 39.6041 755.961C39.6041 753.815 37.9923 752.075 36.0041 752.075C34.0158 752.075 32.404 753.815 32.404 755.961C32.404 758.108 34.0158 759.848 36.0041 759.848Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M19.7959 768.591C21.7842 768.591 23.396 766.851 23.396 764.704C23.396 762.557 21.7842 760.817 19.7959 760.817C17.8077 760.817 16.1959 762.557 16.1959 764.704C16.1959 766.851 17.8077 768.591 19.7959 768.591Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M27.8939 768.591C29.8822 768.591 31.494 766.851 31.494 764.704C31.494 762.557 29.8822 760.817 27.8939 760.817C25.9057 760.817 24.2939 762.557 24.2939 764.704C24.2939 766.851 25.9057 768.591 27.8939 768.591Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 768.591C37.9923 768.591 39.6041 766.851 39.6041 764.704C39.6041 762.557 37.9923 760.817 36.0041 760.817C34.0158 760.817 32.404 762.557 32.404 764.704C32.404 766.851 34.0158 768.591 36.0041 768.591Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M19.7959 777.334C21.7842 777.334 23.396 775.593 23.396 773.447C23.396 771.3 21.7842 769.56 19.7959 769.56C17.8077 769.56 16.1959 771.3 16.1959 773.447C16.1959 775.593 17.8077 777.334 19.7959 777.334Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M27.8939 777.334C29.8822 777.334 31.494 775.593 31.494 773.447C31.494 771.3 29.8822 769.56 27.8939 769.56C25.9057 769.56 24.2939 771.3 24.2939 773.447C24.2939 775.593 25.9057 777.334 27.8939 777.334Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 777.334C37.9923 777.334 39.6041 775.593 39.6041 773.447C39.6041 771.3 37.9923 769.56 36.0041 769.56C34.0158 769.56 32.404 771.3 32.404 773.447C32.404 775.593 34.0158 777.334 36.0041 777.334Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 777.334C46.0903 777.334 47.7021 775.593 47.7021 773.447C47.7021 771.3 46.0903 769.56 44.102 769.56C42.1138 769.56 40.502 771.3 40.502 773.447C40.502 775.593 42.1138 777.334 44.102 777.334Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 777.334C54.1883 777.334 55.8001 775.593 55.8001 773.447C55.8001 771.3 54.1883 769.56 52.2 769.56C50.2118 769.56 48.6 771.3 48.6 773.447C48.6 775.593 50.2118 777.334 52.2 777.334Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 777.334C62.2862 777.334 63.898 775.593 63.898 773.447C63.898 771.3 62.2862 769.56 60.2979 769.56C58.3097 769.56 56.6979 771.3 56.6979 773.447C56.6979 775.593 58.3097 777.334 60.2979 777.334Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M27.8939 786.09C29.8822 786.09 31.494 784.349 31.494 782.203C31.494 780.056 29.8822 778.316 27.8939 778.316C25.9057 778.316 24.2939 780.056 24.2939 782.203C24.2939 784.349 25.9057 786.09 27.8939 786.09Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 786.09C37.9923 786.09 39.6041 784.349 39.6041 782.203C39.6041 780.056 37.9923 778.316 36.0041 778.316C34.0158 778.316 32.404 780.056 32.404 782.203C32.404 784.349 34.0158 786.09 36.0041 786.09Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 786.09C46.0903 786.09 47.7021 784.349 47.7021 782.203C47.7021 780.056 46.0903 778.316 44.102 778.316C42.1138 778.316 40.502 780.056 40.502 782.203C40.502 784.349 42.1138 786.09 44.102 786.09Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 786.09C54.1883 786.09 55.8001 784.349 55.8001 782.203C55.8001 780.056 54.1883 778.316 52.2 778.316C50.2118 778.316 48.6 780.056 48.6 782.203C48.6 784.349 50.2118 786.09 52.2 786.09Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 794.832C37.9923 794.832 39.6041 793.092 39.6041 790.946C39.6041 788.799 37.9923 787.059 36.0041 787.059C34.0158 787.059 32.404 788.799 32.404 790.946C32.404 793.092 34.0158 794.832 36.0041 794.832Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 794.832C46.0903 794.832 47.7021 793.092 47.7021 790.946C47.7021 788.799 46.0903 787.059 44.102 787.059C42.1138 787.059 40.502 788.799 40.502 790.946C40.502 793.092 42.1138 794.832 44.102 794.832Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 794.832C54.1883 794.832 55.8001 793.092 55.8001 790.946C55.8001 788.799 54.1883 787.059 52.2 787.059C50.2118 787.059 48.6 788.799 48.6 790.946C48.6 793.092 50.2118 794.832 52.2 794.832Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M11.698 803.575C13.6862 803.575 15.298 801.835 15.298 799.688C15.298 797.542 13.6862 795.802 11.698 795.802C9.70971 795.802 8.09791 797.542 8.09791 799.688C8.09791 801.835 9.70971 803.575 11.698 803.575Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M27.8939 803.575C29.8822 803.575 31.494 801.835 31.494 799.688C31.494 797.542 29.8822 795.802 27.8939 795.802C25.9057 795.802 24.2939 797.542 24.2939 799.688C24.2939 801.835 25.9057 803.575 27.8939 803.575Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 803.575C46.0903 803.575 47.7021 801.835 47.7021 799.688C47.7021 797.542 46.0903 795.802 44.102 795.802C42.1138 795.802 40.502 797.542 40.502 799.688C40.502 801.835 42.1138 803.575 44.102 803.575Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 803.575C54.1883 803.575 55.8001 801.835 55.8001 799.688C55.8001 797.542 54.1883 795.802 52.2 795.802C50.2118 795.802 48.6 797.542 48.6 799.688C48.6 801.835 50.2118 803.575 52.2 803.575Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 803.575C62.2862 803.575 63.898 801.835 63.898 799.688C63.898 797.542 62.2862 795.802 60.2979 795.802C58.3097 795.802 56.6979 797.542 56.6979 799.688C56.6979 801.835 58.3097 803.575 60.2979 803.575Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 812.318C37.9923 812.318 39.6041 810.578 39.6041 808.431C39.6041 806.285 37.9923 804.544 36.0041 804.544C34.0158 804.544 32.404 806.285 32.404 808.431C32.404 810.578 34.0158 812.318 36.0041 812.318Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 812.318C46.0903 812.318 47.7021 810.578 47.7021 808.431C47.7021 806.285 46.0903 804.544 44.102 804.544C42.1138 804.544 40.502 806.285 40.502 808.431C40.502 810.578 42.1138 812.318 44.102 812.318Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 812.318C54.1883 812.318 55.8001 810.578 55.8001 808.431C55.8001 806.285 54.1883 804.544 52.2 804.544C50.2118 804.544 48.6 806.285 48.6 808.431C48.6 810.578 50.2118 812.318 52.2 812.318Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 812.318C62.2862 812.318 63.898 810.578 63.898 808.431C63.898 806.285 62.2862 804.544 60.2979 804.544C58.3097 804.544 56.6979 806.285 56.6979 808.431C56.6979 810.578 58.3097 812.318 60.2979 812.318Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 812.318C70.3842 812.318 71.996 810.578 71.996 808.431C71.996 806.285 70.3842 804.544 68.3959 804.544C66.4076 804.544 64.7958 806.285 64.7958 808.431C64.7958 810.578 66.4076 812.318 68.3959 812.318Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 812.318C78.4821 812.318 80.0939 810.578 80.0939 808.431C80.0939 806.285 78.4821 804.544 76.4939 804.544C74.5056 804.544 72.8938 806.285 72.8938 808.431C72.8938 810.578 74.5056 812.318 76.4939 812.318Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M84.6041 812.318C86.5924 812.318 88.2041 810.578 88.2041 808.431C88.2041 806.285 86.5924 804.544 84.6041 804.544C82.6158 804.544 81.004 806.285 81.004 808.431C81.004 810.578 82.6158 812.318 84.6041 812.318Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 812.318C94.6903 812.318 96.3021 810.578 96.3021 808.431C96.3021 806.285 94.6903 804.544 92.7021 804.544C90.7138 804.544 89.102 806.285 89.102 808.431C89.102 810.578 90.7138 812.318 92.7021 812.318Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 812.318C102.788 812.318 104.4 810.578 104.4 808.431C104.4 806.285 102.788 804.544 100.8 804.544C98.8117 804.544 97.1999 806.285 97.1999 808.431C97.1999 810.578 98.8117 812.318 100.8 812.318Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M27.8939 821.06C29.8822 821.06 31.494 819.32 31.494 817.174C31.494 815.027 29.8822 813.287 27.8939 813.287C25.9057 813.287 24.2939 815.027 24.2939 817.174C24.2939 819.32 25.9057 821.06 27.8939 821.06Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 821.06C46.0903 821.06 47.7021 819.32 47.7021 817.174C47.7021 815.027 46.0903 813.287 44.102 813.287C42.1138 813.287 40.502 815.027 40.502 817.174C40.502 819.32 42.1138 821.06 44.102 821.06Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 821.06C54.1883 821.06 55.8001 819.32 55.8001 817.174C55.8001 815.027 54.1883 813.287 52.2 813.287C50.2118 813.287 48.6 815.027 48.6 817.174C48.6 819.32 50.2118 821.06 52.2 821.06Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 821.06C62.2862 821.06 63.898 819.32 63.898 817.174C63.898 815.027 62.2862 813.287 60.2979 813.287C58.3097 813.287 56.6979 815.027 56.6979 817.174C56.6979 819.32 58.3097 821.06 60.2979 821.06Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 821.06C70.3842 821.06 71.996 819.32 71.996 817.174C71.996 815.027 70.3842 813.287 68.3959 813.287C66.4076 813.287 64.7958 815.027 64.7958 817.174C64.7958 819.32 66.4076 821.06 68.3959 821.06Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 821.06C78.4821 821.06 80.0939 819.32 80.0939 817.174C80.0939 815.027 78.4821 813.287 76.4939 813.287C74.5056 813.287 72.8938 815.027 72.8938 817.174C72.8938 819.32 74.5056 821.06 76.4939 821.06Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M84.6041 821.06C86.5924 821.06 88.2041 819.32 88.2041 817.174C88.2041 815.027 86.5924 813.287 84.6041 813.287C82.6158 813.287 81.004 815.027 81.004 817.174C81.004 819.32 82.6158 821.06 84.6041 821.06Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 821.06C94.6903 821.06 96.3021 819.32 96.3021 817.174C96.3021 815.027 94.6903 813.287 92.7021 813.287C90.7138 813.287 89.102 815.027 89.102 817.174C89.102 819.32 90.7138 821.06 92.7021 821.06Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 821.06C102.788 821.06 104.4 819.32 104.4 817.174C104.4 815.027 102.788 813.287 100.8 813.287C98.8117 813.287 97.1999 815.027 97.1999 817.174C97.1999 819.32 98.8117 821.06 100.8 821.06Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 829.803C37.9923 829.803 39.6041 828.063 39.6041 825.917C39.6041 823.77 37.9923 822.03 36.0041 822.03C34.0158 822.03 32.404 823.77 32.404 825.917C32.404 828.063 34.0158 829.803 36.0041 829.803Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 829.803C46.0903 829.803 47.7021 828.063 47.7021 825.917C47.7021 823.77 46.0903 822.03 44.102 822.03C42.1138 822.03 40.502 823.77 40.502 825.917C40.502 828.063 42.1138 829.803 44.102 829.803Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 829.803C54.1883 829.803 55.8001 828.063 55.8001 825.917C55.8001 823.77 54.1883 822.03 52.2 822.03C50.2118 822.03 48.6 823.77 48.6 825.917C48.6 828.063 50.2118 829.803 52.2 829.803Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 829.803C78.4821 829.803 80.0939 828.063 80.0939 825.917C80.0939 823.77 78.4821 822.03 76.4939 822.03C74.5056 822.03 72.8938 823.77 72.8938 825.917C72.8938 828.063 74.5056 829.803 76.4939 829.803Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M84.6041 829.803C86.5924 829.803 88.2041 828.063 88.2041 825.917C88.2041 823.77 86.5924 822.03 84.6041 822.03C82.6158 822.03 81.004 823.77 81.004 825.917C81.004 828.063 82.6158 829.803 84.6041 829.803Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 829.803C94.6903 829.803 96.3021 828.063 96.3021 825.917C96.3021 823.77 94.6903 822.03 92.7021 822.03C90.7138 822.03 89.102 823.77 89.102 825.917C89.102 828.063 90.7138 829.803 92.7021 829.803Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M108.898 829.803C110.886 829.803 112.498 828.063 112.498 825.917C112.498 823.77 110.886 822.03 108.898 822.03C106.91 822.03 105.298 823.77 105.298 825.917C105.298 828.063 106.91 829.803 108.898 829.803Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M19.7959 838.559C21.7842 838.559 23.396 836.819 23.396 834.673C23.396 832.526 21.7842 830.786 19.7959 830.786C17.8077 830.786 16.1959 832.526 16.1959 834.673C16.1959 836.819 17.8077 838.559 19.7959 838.559Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 838.559C46.0903 838.559 47.7021 836.819 47.7021 834.673C47.7021 832.526 46.0903 830.786 44.102 830.786C42.1138 830.786 40.502 832.526 40.502 834.673C40.502 836.819 42.1138 838.559 44.102 838.559Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 838.559C54.1883 838.559 55.8001 836.819 55.8001 834.673C55.8001 832.526 54.1883 830.786 52.2 830.786C50.2118 830.786 48.6 832.526 48.6 834.673C48.6 836.819 50.2118 838.559 52.2 838.559Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 838.559C62.2862 838.559 63.898 836.819 63.898 834.673C63.898 832.526 62.2862 830.786 60.2979 830.786C58.3097 830.786 56.6979 832.526 56.6979 834.673C56.6979 836.819 58.3097 838.559 60.2979 838.559Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 838.559C70.3842 838.559 71.996 836.819 71.996 834.673C71.996 832.526 70.3842 830.786 68.3959 830.786C66.4076 830.786 64.7958 832.526 64.7958 834.673C64.7958 836.819 66.4076 838.559 68.3959 838.559Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M84.6041 838.559C86.5924 838.559 88.2041 836.819 88.2041 834.673C88.2041 832.526 86.5924 830.786 84.6041 830.786C82.6158 830.786 81.004 832.526 81.004 834.673C81.004 836.819 82.6158 838.559 84.6041 838.559Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 838.559C102.788 838.559 104.4 836.819 104.4 834.673C104.4 832.526 102.788 830.786 100.8 830.786C98.8117 830.786 97.1999 832.526 97.1999 834.673C97.1999 836.819 98.8117 838.559 100.8 838.559Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M108.898 838.559C110.886 838.559 112.498 836.819 112.498 834.673C112.498 832.526 110.886 830.786 108.898 830.786C106.91 830.786 105.298 832.526 105.298 834.673C105.298 836.819 106.91 838.559 108.898 838.559Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M116.996 838.559C118.984 838.559 120.596 836.819 120.596 834.673C120.596 832.526 118.984 830.786 116.996 830.786C115.008 830.786 113.396 832.526 113.396 834.673C113.396 836.819 115.008 838.559 116.996 838.559Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 847.302C37.9923 847.302 39.6041 845.562 39.6041 843.415C39.6041 841.269 37.9923 839.529 36.0041 839.529C34.0158 839.529 32.404 841.269 32.404 843.415C32.404 845.562 34.0158 847.302 36.0041 847.302Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 847.302C46.0903 847.302 47.7021 845.562 47.7021 843.415C47.7021 841.269 46.0903 839.529 44.102 839.529C42.1138 839.529 40.502 841.269 40.502 843.415C40.502 845.562 42.1138 847.302 44.102 847.302Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 847.302C54.1883 847.302 55.8001 845.562 55.8001 843.415C55.8001 841.269 54.1883 839.529 52.2 839.529C50.2118 839.529 48.6 841.269 48.6 843.415C48.6 845.562 50.2118 847.302 52.2 847.302Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 847.302C62.2862 847.302 63.898 845.562 63.898 843.415C63.898 841.269 62.2862 839.529 60.2979 839.529C58.3097 839.529 56.6979 841.269 56.6979 843.415C56.6979 845.562 58.3097 847.302 60.2979 847.302Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 847.302C78.4821 847.302 80.0939 845.562 80.0939 843.415C80.0939 841.269 78.4821 839.529 76.4939 839.529C74.5056 839.529 72.8938 841.269 72.8938 843.415C72.8938 845.562 74.5056 847.302 76.4939 847.302Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 847.302C94.6903 847.302 96.3021 845.562 96.3021 843.415C96.3021 841.269 94.6903 839.529 92.7021 839.529C90.7138 839.529 89.102 841.269 89.102 843.415C89.102 845.562 90.7138 847.302 92.7021 847.302Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 847.302C102.788 847.302 104.4 845.562 104.4 843.415C104.4 841.269 102.788 839.529 100.8 839.529C98.8117 839.529 97.1999 841.269 97.1999 843.415C97.1999 845.562 98.8117 847.302 100.8 847.302Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M108.898 847.302C110.886 847.302 112.498 845.562 112.498 843.415C112.498 841.269 110.886 839.529 108.898 839.529C106.91 839.529 105.298 841.269 105.298 843.415C105.298 845.562 106.91 847.302 108.898 847.302Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M116.996 847.302C118.984 847.302 120.596 845.562 120.596 843.415C120.596 841.269 118.984 839.529 116.996 839.529C115.008 839.529 113.396 841.269 113.396 843.415C113.396 845.562 115.008 847.302 116.996 847.302Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M36.0041 856.045C37.9923 856.045 39.6041 854.305 39.6041 852.158C39.6041 850.011 37.9923 848.271 36.0041 848.271C34.0158 848.271 32.404 850.011 32.404 852.158C32.404 854.305 34.0158 856.045 36.0041 856.045Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 856.045C62.2862 856.045 63.898 854.305 63.898 852.158C63.898 850.011 62.2862 848.271 60.2979 848.271C58.3097 848.271 56.6979 850.011 56.6979 852.158C56.6979 854.305 58.3097 856.045 60.2979 856.045Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 856.045C70.3842 856.045 71.996 854.305 71.996 852.158C71.996 850.011 70.3842 848.271 68.3959 848.271C66.4076 848.271 64.7958 850.011 64.7958 852.158C64.7958 854.305 66.4076 856.045 68.3959 856.045Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 856.045C78.4821 856.045 80.0939 854.305 80.0939 852.158C80.0939 850.011 78.4821 848.271 76.4939 848.271C74.5056 848.271 72.8938 850.011 72.8938 852.158C72.8938 854.305 74.5056 856.045 76.4939 856.045Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M116.996 856.045C118.984 856.045 120.596 854.305 120.596 852.158C120.596 850.011 118.984 848.271 116.996 848.271C115.008 848.271 113.396 850.011 113.396 852.158C113.396 854.305 115.008 856.045 116.996 856.045Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M44.102 864.787C46.0903 864.787 47.7021 863.047 47.7021 860.901C47.7021 858.754 46.0903 857.014 44.102 857.014C42.1138 857.014 40.502 858.754 40.502 860.901C40.502 863.047 42.1138 864.787 44.102 864.787Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 864.787C54.1883 864.787 55.8001 863.047 55.8001 860.901C55.8001 858.754 54.1883 857.014 52.2 857.014C50.2118 857.014 48.6 858.754 48.6 860.901C48.6 863.047 50.2118 864.787 52.2 864.787Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 864.787C62.2862 864.787 63.898 863.047 63.898 860.901C63.898 858.754 62.2862 857.014 60.2979 857.014C58.3097 857.014 56.6979 858.754 56.6979 860.901C56.6979 863.047 58.3097 864.787 60.2979 864.787Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 864.787C70.3842 864.787 71.996 863.047 71.996 860.901C71.996 858.754 70.3842 857.014 68.3959 857.014C66.4076 857.014 64.7958 858.754 64.7958 860.901C64.7958 863.047 66.4076 864.787 68.3959 864.787Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 864.787C78.4821 864.787 80.0939 863.047 80.0939 860.901C80.0939 858.754 78.4821 857.014 76.4939 857.014C74.5056 857.014 72.8938 858.754 72.8938 860.901C72.8938 863.047 74.5056 864.787 76.4939 864.787Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 864.787C102.788 864.787 104.4 863.047 104.4 860.901C104.4 858.754 102.788 857.014 100.8 857.014C98.8117 857.014 97.1999 858.754 97.1999 860.901C97.1999 863.047 98.8117 864.787 100.8 864.787Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M108.898 864.787C110.886 864.787 112.498 863.047 112.498 860.901C112.498 858.754 110.886 857.014 108.898 857.014C106.91 857.014 105.298 858.754 105.298 860.901C105.298 863.047 106.91 864.787 108.898 864.787Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M116.996 864.787C118.984 864.787 120.596 863.047 120.596 860.901C120.596 858.754 118.984 857.014 116.996 857.014C115.008 857.014 113.396 858.754 113.396 860.901C113.396 863.047 115.008 864.787 116.996 864.787Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M52.2 873.53C54.1883 873.53 55.8001 871.79 55.8001 869.644C55.8001 867.497 54.1883 865.757 52.2 865.757C50.2118 865.757 48.6 867.497 48.6 869.644C48.6 871.79 50.2118 873.53 52.2 873.53Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M60.2979 873.53C62.2862 873.53 63.898 871.79 63.898 869.644C63.898 867.497 62.2862 865.757 60.2979 865.757C58.3097 865.757 56.6979 867.497 56.6979 869.644C56.6979 871.79 58.3097 873.53 60.2979 873.53Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 873.53C70.3842 873.53 71.996 871.79 71.996 869.644C71.996 867.497 70.3842 865.757 68.3959 865.757C66.4076 865.757 64.7958 867.497 64.7958 869.644C64.7958 871.79 66.4076 873.53 68.3959 873.53Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 873.53C78.4821 873.53 80.0939 871.79 80.0939 869.644C80.0939 867.497 78.4821 865.757 76.4939 865.757C74.5056 865.757 72.8938 867.497 72.8938 869.644C72.8938 871.79 74.5056 873.53 76.4939 873.53Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M84.6041 873.53C86.5924 873.53 88.2041 871.79 88.2041 869.644C88.2041 867.497 86.5924 865.757 84.6041 865.757C82.6158 865.757 81.004 867.497 81.004 869.644C81.004 871.79 82.6158 873.53 84.6041 873.53Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 873.53C94.6903 873.53 96.3021 871.79 96.3021 869.644C96.3021 867.497 94.6903 865.757 92.7021 865.757C90.7138 865.757 89.102 867.497 89.102 869.644C89.102 871.79 90.7138 873.53 92.7021 873.53Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 873.53C102.788 873.53 104.4 871.79 104.4 869.644C104.4 867.497 102.788 865.757 100.8 865.757C98.8117 865.757 97.1999 867.497 97.1999 869.644C97.1999 871.79 98.8117 873.53 100.8 873.53Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M108.898 873.53C110.886 873.53 112.498 871.79 112.498 869.644C112.498 867.497 110.886 865.757 108.898 865.757C106.91 865.757 105.298 867.497 105.298 869.644C105.298 871.79 106.91 873.53 108.898 873.53Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M116.996 873.53C118.984 873.53 120.596 871.79 120.596 869.644C120.596 867.497 118.984 865.757 116.996 865.757C115.008 865.757 113.396 867.497 113.396 869.644C113.396 871.79 115.008 873.53 116.996 873.53Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 882.273C78.4821 882.273 80.0939 880.533 80.0939 878.386C80.0939 876.24 78.4821 874.5 76.4939 874.5C74.5056 874.5 72.8938 876.24 72.8938 878.386C72.8938 880.533 74.5056 882.273 76.4939 882.273Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 882.273C94.6903 882.273 96.3021 880.533 96.3021 878.386C96.3021 876.24 94.6903 874.5 92.7021 874.5C90.7138 874.5 89.102 876.24 89.102 878.386C89.102 880.533 90.7138 882.273 92.7021 882.273Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M116.996 882.273C118.984 882.273 120.596 880.533 120.596 878.386C120.596 876.24 118.984 874.5 116.996 874.5C115.008 874.5 113.396 876.24 113.396 878.386C113.396 880.533 115.008 882.273 116.996 882.273Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M68.3959 891.029C70.3842 891.029 71.996 889.289 71.996 887.142C71.996 884.996 70.3842 883.256 68.3959 883.256C66.4076 883.256 64.7958 884.996 64.7958 887.142C64.7958 889.289 66.4076 891.029 68.3959 891.029Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M84.6041 891.029C86.5924 891.029 88.2041 889.289 88.2041 887.142C88.2041 884.996 86.5924 883.256 84.6041 883.256C82.6158 883.256 81.004 884.996 81.004 887.142C81.004 889.289 82.6158 891.029 84.6041 891.029Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 891.029C94.6903 891.029 96.3021 889.289 96.3021 887.142C96.3021 884.996 94.6903 883.256 92.7021 883.256C90.7138 883.256 89.102 884.996 89.102 887.142C89.102 889.289 90.7138 891.029 92.7021 891.029Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 891.029C102.788 891.029 104.4 889.289 104.4 887.142C104.4 884.996 102.788 883.256 100.8 883.256C98.8117 883.256 97.1999 884.996 97.1999 887.142C97.1999 889.289 98.8117 891.029 100.8 891.029Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M108.898 891.029C110.886 891.029 112.498 889.289 112.498 887.142C112.498 884.996 110.886 883.256 108.898 883.256C106.91 883.256 105.298 884.996 105.298 887.142C105.298 889.289 106.91 891.029 108.898 891.029Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M116.996 891.029C118.984 891.029 120.596 889.289 120.596 887.142C120.596 884.996 118.984 883.256 116.996 883.256C115.008 883.256 113.396 884.996 113.396 887.142C113.396 889.289 115.008 891.029 116.996 891.029Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M76.4939 899.772C78.4821 899.772 80.0939 898.032 80.0939 895.885C80.0939 893.738 78.4821 891.998 76.4939 891.998C74.5056 891.998 72.8938 893.738 72.8938 895.885C72.8938 898.032 74.5056 899.772 76.4939 899.772Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M108.898 899.772C110.886 899.772 112.498 898.032 112.498 895.885C112.498 893.738 110.886 891.998 108.898 891.998C106.91 891.998 105.298 893.738 105.298 895.885C105.298 898.032 106.91 899.772 108.898 899.772Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M116.996 899.772C118.984 899.772 120.596 898.032 120.596 895.885C120.596 893.738 118.984 891.998 116.996 891.998C115.008 891.998 113.396 893.738 113.396 895.885C113.396 898.032 115.008 899.772 116.996 899.772Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M92.7021 908.514C94.6903 908.514 96.3021 906.774 96.3021 904.628C96.3021 902.481 94.6903 900.741 92.7021 900.741C90.7138 900.741 89.102 902.481 89.102 904.628C89.102 906.774 90.7138 908.514 92.7021 908.514Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M100.8 908.514C102.788 908.514 104.4 906.774 104.4 904.628C104.4 902.481 102.788 900.741 100.8 900.741C98.8117 900.741 97.1999 902.481 97.1999 904.628C97.1999 906.774 98.8117 908.514 100.8 908.514Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M108.898 908.514C110.886 908.514 112.498 906.774 112.498 904.628C112.498 902.481 110.886 900.741 108.898 900.741C106.91 900.741 105.298 902.481 105.298 904.628C105.298 906.774 106.91 908.514 108.898 908.514Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M116.996 908.514C118.984 908.514 120.596 906.774 120.596 904.628C120.596 902.481 118.984 900.741 116.996 900.741C115.008 900.741 113.396 902.481 113.396 904.628C113.396 906.774 115.008 908.514 116.996 908.514Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M125.106 908.514C127.094 908.514 128.706 906.774 128.706 904.628C128.706 902.481 127.094 900.741 125.106 900.741C123.118 900.741 121.506 902.481 121.506 904.628C121.506 906.774 123.118 908.514 125.106 908.514Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M133.204 908.514C135.192 908.514 136.804 906.774 136.804 904.628C136.804 902.481 135.192 900.741 133.204 900.741C131.216 900.741 129.604 902.481 129.604 904.628C129.604 906.774 131.216 908.514 133.204 908.514Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M141.302 908.514C143.29 908.514 144.902 906.774 144.902 904.628C144.902 902.481 143.29 900.741 141.302 900.741C139.314 900.741 137.702 902.481 137.702 904.628C137.702 906.774 139.314 908.514 141.302 908.514Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M149.4 908.514C151.388 908.514 153 906.774 153 904.628C153 902.481 151.388 900.741 149.4 900.741C147.412 900.741 145.8 902.481 145.8 904.628C145.8 906.774 147.412 908.514 149.4 908.514Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M108.898 917.257C110.886 917.257 112.498 915.517 112.498 913.371C112.498 911.224 110.886 909.484 108.898 909.484C106.91 909.484 105.298 911.224 105.298 913.371C105.298 915.517 106.91 917.257 108.898 917.257Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M125.106 917.257C127.094 917.257 128.706 915.517 128.706 913.371C128.706 911.224 127.094 909.484 125.106 909.484C123.118 909.484 121.506 911.224 121.506 913.371C121.506 915.517 123.118 917.257 125.106 917.257Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
      <path
        className="path pulse-color"
        d="M133.204 926C135.192 926 136.804 924.26 136.804 922.113C136.804 919.967 135.192 918.227 133.204 918.227C131.216 918.227 129.604 919.967 129.604 922.113C129.604 924.26 131.216 926 133.204 926Z"
        stroke="#000012"
        strokeWidth="0.01"
      />
    </svg>
  );
};
